<script lang="ts" setup>
import { computed, nextTick, onMounted, provide, ref, watch } from 'vue';
import type { SortableEvent } from 'sortablejs';
import Sortable from 'sortablejs';
import { useToast } from 'vue-toastification';
import VTableRow from '@/components/Tables/VTableRow.vue';
import { currentHoverIdType } from '@/provide/keys';
import { cva } from 'class-variance-authority';

export type SortEmit = {
  selectedItem: string;
  newOrder: string[];
};

type Props = {
  modelValue?: unknown[] | null;
  rowSize?: 'medium' | 'large' | 'small' | 'minimal';
  roundedPillRows?: boolean;
  noSpacing?: boolean;
  splitRowsVertically?: boolean;
  allMainCells?: boolean;
  unStriped?: boolean;
  edgeToEdge?: boolean;
  headerHover?: boolean;
  stickyHeader?: boolean;
  canDrag?: boolean;
  setDragCursor?: boolean | null;
  dragHandle?: string;
  itemKey?: string | null;
  isStore?: boolean;
  rowClasses?: string;
  setRowStyle?: (item: any, index: number) => string;
  setRowClasses?: (item: any, index: number) => string;
  mainRowFunction?: (item: any, index: number) => boolean;
  isRowDraggable?: (item: any, index: number) => boolean;
  stickyFirstColumn?: boolean;
  borderedTable?: boolean;
  verticallyBorderedTable?: boolean;
  stickyLastColumn?: boolean;
  snapRows?: boolean;
  invertedRows?: boolean;
  handleOutside?: boolean;
  dontUseScrollSnap?: boolean;
  tableClasses?: string | null;
  softerBackgroundHeader?: boolean;
  tableBorder?: TableBorder;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  rowSize: 'medium',
  roundedPillRows: false,
  noSpacing: false,
  splitRowsVertically: false,
  allMainCells: false,
  unStriped: false,
  invertedRows: false,
  edgeToEdge: false,
  headerHover: false,
  stickyHeader: false,
  stickyFirstColumn: false,
  stickyLastColumn: false,
  canDrag: false,
  setDragCursor: true,
  snapRows: false,
  handleOutside: false,
  dragHandle: undefined,
  itemKey: 'id',
  isStore: false,
  rowClasses: '',
  setRowStyle: () => '',
  setRowClasses: () => '',
  mainRowFunction: () => false,
  isRowDraggable: () => true,
  dontUseScrollSnap: false,
  tableClasses: null,
  softerBackgroundHeader: false,
  tableBorder: 'horizontal',
});

const emit = defineEmits<{
  (e: 'newOrder', arg: { item: unknown; order: number }): void;
  (e: 'update:modelValue', event: unknown[]): void;
  (e: 'reorder-all', event: SortableEvent): void;
  (e: 'rowClick', event: MouseEvent): void;
  (e: 'sorted', arg: SortEmit): void;
}>();

const headClasses = cva(' ', {
  variants: {
    type: {
      soft: `[&>tr>th]:bg-content-secondary`,
      default: `[&>tr>th]:bg-[--color-background-table-header]`,
    },
  },
  defaultVariants: {
    type: 'default',
  },
});

const getSize = () => {
  switch (props.rowSize) {
    case 'medium':
      return 'md';
    case 'small':
      return 'sm';
    case 'large':
      return 'lg';
    case 'minimal':
      return 'xs';
  }
};

const size = getSize();

// const rowClasses = computed(() => {
//   let string = '[&>tr>th]:h-[18px] ';
//   // switch (props.rowSize) {
//   //   case 'large': {
//   //     string += ' ';
//   //     break;
//   //   }
//   //   case 'minimal': {
//   //     string += ' ';
//   //     break;
//   //   }
//   //   case 'small': {
//   //     string += ' ';
//   //     break;
//   //   }
//   //   case 'medium':
//   //   default: {
//   //     string += ' ';
//   //     break;
//   //   }
//   // }
//
//   if (props.roundedPillRows) {
//     string += ' [&>tr>*]:rounded-none [&>tr>:first-child]:rounded-l [&>tr>:last-child]:rounded-r';
//   }
//
//   if (!props.unStriped) {
//     string += props.invertedRows
//       ? ' [&>:nth-child(odd)]:bg-row [&>*]:border-b [&>:nth-child(even)]:bg-row-alternate [&>:nth-child(odd)_td]:bg-row [&>:nth-child(even)_td]:bg-row-alternate'
//       : ' [&>:nth-child(even)]:bg-row [&>*]:border-b [&>:nth-child(odd)]:bg-row-alternate [&>:nth-child(even)_td]:bg-row [&>:nth-child(odd)_td]:bg-row-alternate';
//   }
//
//   return string;
// });

// const headerClasses = computed(() => {
//   let string = ' [&>tr>*]:font-semibold  border-t';
//
//   if (props.softerBackgroundHeader) {
//     string += ' [&>tr>*]:bg-content-secondary ';
//   } else {
//     string += ' [&>tr>*]:bg-[--color-background-table-header] ';
//   }
//
//   if (!props.unStriped) {
//     string += ' [&>tr>*]:border-b ';
//   }
//
//   switch (props.rowSize) {
//     case 'large': {
//       string += ' [&>tr>*]:px-edge [&>tr>th]:py-edge-1/4';
//       break;
//     }
//     case 'small': {
//       string += ' [&>tr>*]:px-edge-1/4  [&>tr>th]:py-edge-1/4';
//       break;
//     }
//     case 'medium':
//     default: {
//       string += ' [&>tr>*]:px-edge-1/2 [&>tr>th]:py-edge-1/4';
//       break;
//     }
//   }
//   return string;
// });

type TableBorder = 'all' | 'vertical' | 'horizontal' | 'off';

const tableClass = cva('border-separate [&_tr>th]:px-edge-1/2 [&_tr>th]:py-edge-1/4', {
  variants: {
    size: {
      xs: `[&_tr>td]:h-[17px] [&_tr>td]:px-edge-1/2 [&_tr>td]:py-edge-1/4`,
      sm: `[&_tr>td]:h-[27px] [&_tr>td]:px-edge-1/2 [&_tr>td]:py-edge-1/4`,
      md: `[&_tr>td]:h-[37px] [&_tr>td]:px-edge-1/2 [&_tr>td]:py-edge-1/4`,
      lg: `[&_tr>td]:h-[47px] [&_tr>td]:px-edge-1/2 [&_tr>td]:py-edge-1/4`,
    },
    width: {
      edge: `[&_tr>td:first-child]:px-edge [&_tr>td:last-child]:px-edge [&_tr>th:first-child]:px-edge [&_tr>th:last-child]:px-edge`,
      default: ``,
    },
    border: {
      all: `[&_tr>*]:border ${props.edgeToEdge ? '[&_tr>*:first-child]:border-l-0 [&_tr>*:last-child]:border-r-0' : ''} `,
      vertical: `[&_tr>*]:border-x ${props.edgeToEdge ? '[&_tr>*:first-child]:border-l-0 [&_tr>*:last-child]:border-r-0' : ''} `,
      horizontal: `[&_tr>*]:border-t [&_tr:last-child>*]:border-b ${props.edgeToEdge ? '[&_tr>*:first-child]:border-l-0 [&_tr>*:last-child]:border-r-0' : ''} `,
      off: ``,
    },
    form: {
      rounded: `border-spacing-y-[2px] border-spacing-x-[0]  [&_tr>td:first-child]:!rounded-l [&_tr>td:last-child]:rounded-r`,
      square: `border-spacing-y-[0px] bg`,
    },
  },
});

const header = ref<HTMLElement | null>(null);
const body = ref<HTMLElement | null>(null);

const currentHoverId = ref(null);

if (props.headerHover) {
  provide(currentHoverIdType, {
    currentHoverId,
    update: (newNum) => {
      currentHoverId.value = newNum;
    },
  });
}

const setHoverIds = () => {
  if (!props.headerHover) return;

  nextTick(() => {
    const headerCells = header.value?.children?.item(0)?.children;
    const bodyRows = body.value?.children;

    for (let j = 0; j < bodyRows.length; j++) {
      for (let k = 0; k < bodyRows[j]?.children.length; k++) {
        if (headerCells?.item(k)?.id) {
          bodyRows[j]?.children[k].setAttribute('data-column-hover-id', headerCells.item(k).id);
        }
      }
    }
  });
};

let sortableInstance: Sortable | null = null;

onMounted(() => {
  setHoverIds();
  watch(
    () => props.canDrag,
    (newVal) => {
      if (newVal && body.value) {
        sortableInstance = new Sortable(body.value, {
          animation: 150,
          handle: props.dragHandle,
          draggable: '.draggable',
          ghostClass: 'ghostClass2',
          disabled: !props.canDrag,
          chosenClass: 'chosenClass',
          onEnd(event: SortableEvent) {
            if (props.handleOutside) {
              const rowId = event.item.dataset.id;

              if (!rowId) {
                useToast().error('Something went wrong, please try again.');
                return;
              }

              const newOrder = sortableInstance?.toArray() || [];
              emit('sorted', { selectedItem: rowId, newOrder: newOrder });
              return;
            }
            const { oldIndex, newIndex } = event;
            if (oldIndex === newIndex) return;
            const itemId = event.item.getAttribute('data-id');
            if (!itemId) {
              useToast().error('Error while reordering items 1');
              return;
            }
            const selectedItem = props.modelValue.find((item) => item[props.itemKey] == itemId);
            if (!selectedItem) {
              useToast().error('Error selected item not found');
              return;
            }
            const itemIdBefore = event.to.children.item(newIndex - 1)?.getAttribute('data-id');
            const itemIdAfter = event.to.children.item(newIndex + 1)?.getAttribute('data-id');
            if (itemIdBefore && itemIdAfter) {
              const i1 = props.modelValue?.find((item) => item[props.itemKey] == itemIdBefore);
              const i2 = props.modelValue?.find((item) => item[props.itemKey] == itemIdAfter);
              if (!i1 || !i1) {
                useToast().error('Error while reordering items 2');
                return;
              }
              const newOrder = Math.round((i1.order + i2.order) / 2);
              if (props.modelValue.some((item) => item.order === newOrder)) {
                useToast().error('Same order already exists');
                emit('reorder-all', () => {
                  sortableInstance?.sort(
                    props.modelValue?.map((i) => i[props.itemKey]),
                    true
                  );
                });
                return;
              }
              selectedItem.order = newOrder;
              const sortedItems = [...props.modelValue].sort((a, b) => a.order - b.order);
              emit('newOrder', { item: selectedItem, order: newOrder });
              emit('update:modelValue', sortedItems);
            } else if (itemIdBefore) {
              // MOVED TO THE LAST POSITION
              const i1 = props.modelValue?.find((item) => item[props.itemKey] == itemIdBefore);
              if (!i1) {
                useToast().error('Error while reordering items 3');
                return;
              }
              const newOrder = i1.order + 10000;
              if (props.modelValue.some((item) => item.order === newOrder)) {
                useToast().error('Same order already exists');
                emit('reorder-all', () => {
                  sortableInstance?.sort(
                    props.modelValue?.map((i) => i[props.itemKey]),
                    true
                  );
                });
                return;
              }
              selectedItem.order = newOrder;
              const sortedItems = [...props.modelValue].sort((a, b) => a.order - b.order);
              const sortedIds = sortedItems.map((i) => String(i[props.itemKey]));
              emit('newOrder', { item: selectedItem, order: newOrder });
              emit('update:modelValue', sortedItems);
            } else if (itemIdAfter) {
              // MOVED TO THE FIRST POSITION
              const i2 = props.modelValue?.find((item) => item[props.itemKey] == itemIdAfter);
              if (!i2) {
                useToast().error('Error while reordering items 4');
                return;
              }
              const newOrder = Math.round(i2.order / 2);
              if (props.modelValue.some((item) => item.order === newOrder) || newOrder < 0) {
                useToast().error('Same order already exists');
                emit('reorder-all', () => {
                  sortableInstance?.sort(
                    props.modelValue?.map((i) => i[props.itemKey]),
                    true
                  );
                });
                return;
              }
              selectedItem.order = newOrder;
              const sortedItems = [...props.modelValue].sort((a, b) => a.order - b.order);
              emit('newOrder', { item: selectedItem, order: newOrder });
              emit('update:modelValue', sortedItems);
            } else {
              useToast().error('Something went wrong DRAG AND DROP');
            }
          },
        });
      } else {
        sortableInstance?.destroy();
      }
    },
    { immediate: true }
  );
});

if (!props.isStore) {
  watch(
    () => props.modelValue,
    async (array) => {
      //TODO: fix this @erik
      // if (array.length === 0) {
      //   if (sortableInstance) {
      //     sortableInstance.option('disabled', true);
      //   }
      //   return;
      // }
      // if (sortableInstance) {
      //   sortableInstance.option('disabled', false);
      // }
      // await nextTick();
      // try {
      //   if (!sortableInstance) return;
      //
      //   // if (JSON.stringify(array?.map((e) => String(e.id))) !== JSON.stringify(sortableInstance?.toArray())) {
      //   //   sortableInstance?.sort(
      //   //     array?.map((i) => i[props.itemKey]),
      //   //     false
      //   //   );
      //   // }
      //
      //   setTimeout(() => {
      //     if (JSON.stringify(array?.map((e) => String(e.id))) !== JSON.stringify(sortableInstance?.toArray())) {
      //       sortableInstance?.sort(
      //         array?.map((i) => i[props.itemKey]),
      //         false
      //       );
      //     }
      //   }, 500);
      // } catch (e) {
      //   console.error(e);
      // }
    },
    { deep: true }
  );
}

const setRowStyle = (item: any, index: number) => {
  if (props.setRowStyle) {
    return props.setRowStyle(item, index);
  }
  return '';
};
const tableElement = ref<HTMLElement | null>(null);
const container = ref<HTMLElement | null>(null);

const scrollPaddingTop = ref(0);
const calculateScrollPaddingTop = () => {
  if (props.snapRows && props.stickyHeader && tableElement.value) {
    const headerHeight = (tableElement.value.querySelector('thead') as HTMLElement)?.offsetHeight || 0;
    if (headerHeight) {
      scrollPaddingTop.value = headerHeight;
    }
  }
};
const snapObjectAfterTableHeight = ref(0);
const calculateSnapObjectAfterTableHeight = () => {
  if (props.snapRows && props.stickyHeader && tableElement.value && container.value) {
    if (props.dontUseScrollSnap) return;

    if (container.value.parentElement.offsetHeight > container.value.offsetHeight) {
      snapObjectAfterTableHeight.value = 0;
      return;
    }

    const containerHeight = container.value.offsetHeight;
    const contentHeight = containerHeight - scrollPaddingTop.value;
    const rowHeight = (body.value.querySelector('tr') as HTMLElement)?.getBoundingClientRect().height || 0;
    snapObjectAfterTableHeight.value = Math.round(contentHeight % rowHeight);
    return;
  }
  snapObjectAfterTableHeight.value = 0;
};
if (props.snapRows) {
  // calculateSnapObjectAfterTableHeight();
  [0, 50, 100, 200, 500, 1000].forEach((t) => {
    setTimeout(() => {
      calculateSnapObjectAfterTableHeight();
      calculateScrollPaddingTop();
    }, t);
  });
  setInterval(() => {
    calculateSnapObjectAfterTableHeight();
    calculateScrollPaddingTop();
  }, 5000);
}

const setHoverIcon = computed(() => {
  if (props.canDrag && props.setDragCursor) {
    return 'cursor-grab';
  }
  return '';
});
</script>

<template>
  <div
    ref="container"
    :style="snapRows && stickyHeader ? ' scroll-padding-top: ' + scrollPaddingTop + 'px;' : ''"
    :class="
      (snapRows ? '[&_thead_tr]:snap-unset snap-x snap-y snap-mandatory [&_tr]:snap-start ' : '') +
      (snapRows && stickyHeader ? ' [&_thead_tr]:top-[4px]' : '')
    ">
    <table
      ref="tableElement"
      class="w-full table-auto"
      :class="[
        tableClass({
          size: size,
          width: edgeToEdge ? 'edge' : 'default',
          border: tableBorder,
          form: roundedPillRows ? 'rounded' : 'square',
        }),
        {
          '[&>thead]:sticky [&>thead]:top-0 [&>thead]:z-[100] [&>thead]:bg-[--color-background-table-header] [&>thead]:shadow-border [&>thead_th]:bg-[--color-background-table-header]':
            stickyHeader,
        },
        {
          '[&>*_td:first-child]:sticky [&>*_td:first-child]:left-0 [&>*_td:first-child]:z-[10] [&>*_td:first-child]:border-r [&>*_th:first-child]:sticky [&>*_th:first-child]:left-0 [&>*_th:first-child]:z-[11] [&>*_th:first-child]:border-r':
            stickyFirstColumn,
        },
        {
          '[&>*_td:last-child>*]:w-full [&>*_td:last-child]:sticky [&>*_td:last-child]:right-0 [&>*_td:last-child]:z-[1] [&>*_td:last-child_.left-side-border]:block [&>*_th:last-child>*]:w-full [&>*_th:last-child]:sticky [&>*_th:last-child]:right-0 [&>*_th:last-child]:z-[11] [&>*_th:last-child_.left-side-border]:block':
            stickyLastColumn,
        },
      ]"
      @mouseenter="setHoverIds()"
      @mouseleave="currentHoverId = null">
      <thead
        ref="header"
        :class="headClasses({ type: softerBackgroundHeader ? 'soft' : 'default' })">
        <slot name="head" />
      </thead>
      <tbody ref="body">
        <slot v-if="!modelValue" />
        <template v-else>
          <VTableRow
            v-for="(item, index) in modelValue"
            :key="item[itemKey]"
            :data-id="`${item[itemKey]}`"
            :style="setRowStyle(item, index)"
            :class="[
              { 'draggable': isRowDraggable(item, index) },
              'relative hover:z-10',
              rowClasses,
              setRowClasses(item, index),
              setHoverIcon,
            ]"
            @click="$emit('rowClick', item, index, $event)">
            <slot
              name="row"
              :index="index"
              :item="item" />
          </VTableRow>
          <slot name="new-row" />
        </template>
        <tr
          v-if="snapObjectAfterTableHeight > 0"
          :style="'height: ' + snapObjectAfterTableHeight + 'px;'"
          class="w-full" />
      </tbody>

      <slot name="footer" />
    </table>
  </div>
</template>

<style>
.ghostClass2 {
  opacity: 0;
  background-color: #f5f5f5;
}
</style>
