<script setup lang="ts">
import { useToast } from 'vue-toastification';
import { downloadFile } from '@/helpers/downloadFileFunctions';
import ReportModal from '@/components/Modals/ReportModal.vue';
import { concatName } from '@/services/api-partners';
import VMultiselect from '@/components/Inputs/VMultiselect.vue';
import { ref } from 'vue';
import { capitalizeString, getKey } from '@/util/globals';
import { useOrderReports } from '@/composables/use-order-reports';
import VSelect from '@/components/Inputs/VSelect.vue';
import { useLocalStorage } from '@vueuse/core';
import { InvoiceBasesResource } from '@/types/invoice-row';

type Props = {
  invoiceBasis: InvoiceBasesResource;
  orderReportGroupId?: number | null;
  downloading: boolean;
  model: string;
  modelId: number;
};

const props = withDefaults(defineProps<Props>(), {
  orderReportGroupId: null,
});

const emit = defineEmits<{
  'update:downloading': [arg: boolean];
  'closed';
}>();

const toast = useToast();

const asCSV = useLocalStorage('invoiceReportAsCSVFor_' + props.orderReportGroupId, true);

const modalOpen = ref(true);
const metaData = ref([]);
const partners = ref(null);
const includedMetaDataIds = ref([]);
const includedCompanyIds = ref([]);
const includedContactIds = ref([]);
const availableColumns = ref(['sku', 'description', 'quantity', 'price']);
const skipColumns = useLocalStorage('invoiceReportAsSkipColumns', []);

const updateSkipColumns = (newIncludedColumns) => {
  skipColumns.value = availableColumns.value.filter((c) => !newIncludedColumns.includes(c));
};
const fetchPartners = async () => {
  if (props.model !== 'Invite') return;
  const { data } = await axios.get(`/api/invites/${props.modelId}/partners`);
  partners.value = data;
  if (partners.value?.contacts?.length) {
    partners.value.contacts = partners.value.contacts.map((contact) => {
      return {
        ...contact,
        name: concatName(contact),
      };
    });
  }
};

const fetchMetaDatas = async () => {
  if (metaData.value.length > 0) return;
  const { data } = await axios.get('/api/meta-data', {
    params: {
      model_type: `App\\${props.model}`,
      model_id: props.modelId,
    },
  });
  metaData.value = data;
};
const orderReportId = useLocalStorage('orderReportIdForInvoices_' + props.orderReportGroupId, null);

const { orderReports, fetchOrderReports } = useOrderReports(
  'Group',
  props.orderReportGroupId,
  false,
  props.orderReportGroupId !== null
);

const getData = async () => {
  await fetchMetaDatas();
  await fetchPartners();
  if (props.orderReportGroupId) {
    await fetchOrderReports();
  }
  if (props.invoiceBasis) {
    if (props.invoiceBasis.partner_company_id) {
      includedCompanyIds.value.push(props.invoiceBasis.partner_company_id);
    }
    if (props.invoiceBasis.partner_contact_id) {
      includedContactIds.value.push(props.invoiceBasis.partner_contact_id);
    }
  }
};

getData();

const downloadReport = async () => {
  if (props.downloading) return;
  emit('update:downloading', true);
  if (orderReportId.value && asCSV.value) {
    const response = await axios
      .post(`/api/reports/order-reports/${orderReportId.value}`, {
        invoice_base_id: props.invoiceBasis.id,
      })
      .catch((error) => {
        console.error(error);
        emit('update:downloading', false);
        toast.warning('Something went wrong, please try again later');
      });

    if (response.status === 204) {
      toast.success('There are no data in the requested invoice basis.');
      emit('update:downloading', false);
      return;
    }
    if (response.status === 205) {
      toast.success('Can only download invoice bases for events.');
      emit('update:downloading', false);
      return;
    }
    await downloadFile(response.data.url, response.data.name);
    modalOpen.value = false;
    emit('update:downloading', false);
    return;
  }

  const response = await axios
    .post(`/api/invoice-bases/${props.invoiceBasis.id}/download`, {
      asCSV: asCSV.value,
      metaDataIds: !asCSV.value ? includedMetaDataIds.value : [],
      contactIds: !asCSV.value ? includedContactIds.value : [],
      companyIds: !asCSV.value ? includedCompanyIds.value : [],
      skip_columns: !asCSV.value ? skipColumns.value : [],
    })
    .catch((error) => {
      console.error(error);
      emit('update:downloading', false);
      toast.warning('Something went wrong, please try again later');
    });

  if (response.status === 204) {
    toast.success('There are no data in the requested invoice basis.');
    emit('update:downloading', false);
    return;
  }
  if (response.status === 205) {
    toast.success('Can only download invoice bases for events.');
    emit('update:downloading', false);
    return;
  }
  await downloadFile(response.data.url, response.data.name);
  modalOpen.value = false;
  emit('update:downloading', false);
  emit('closed', false);
};
</script>

<template>
  <ReportModal
    v-if="modalOpen"
    v-model:as-c-s-v="asCSV"
    :with-button="false"
    :can-download="true"
    title="Download Report"
    :working="downloading"
    @closed="[(modalOpen = false), emit('closed')]"
    @download="downloadReport">
    <transition name="slide-down">
      <div
        v-if="!asCSV"
        class="form-layout">
        <VMultiselect
          v-if="partners && getKey(partners, 'contacts', []).length > 0"
          v-model="includedContactIds"
          :close-on-select="false"
          :options="partners.contacts"
          with-add-all
          label="Contacts To Include" />
        <VMultiselect
          v-if="partners && getKey(partners, 'companies', []).length > 0"
          v-model="includedCompanyIds"
          :close-on-select="false"
          :options="partners.companies"
          with-add-all
          label="Companies To Include" />
        <VMultiselect
          v-if="metaData"
          v-model="includedMetaDataIds"
          :close-on-select="false"
          option-label="title"
          :options="metaData"
          with-add-all
          label="Meta Data To Include" />
        <VMultiselect
          :model-value="availableColumns.filter((c) => !skipColumns.includes(c))"
          :close-on-select="false"
          :options="availableColumns"
          label="Columns To Show"
          @update:model-value="updateSkipColumns">
          <template #pill-container="{ item }"> {{ capitalizeString(item) }}</template>
          <template #default="{ option }"> {{ capitalizeString(option) }}</template>
        </VMultiselect>
      </div>
      <div v-else>
        <VSelect
          v-if="orderReports && orderReports.length > 0"
          label="Report Style"
          title="Which Format"
          nullable
          option-value="title"
          nullable-display-text="System Default"
          :options="orderReports"
          :model-value="orderReportId ? Number(orderReportId) : null"
          @update:model-value="orderReportId = $event" />
      </div>
    </transition>
  </ReportModal>
</template>
