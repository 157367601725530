<script lang="ts" setup>
import BaseSlideout from '@/components/Base/BaseSlideout.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { useSmallScreen } from '@/composables/use-small-screen';
import { ref } from 'vue';
import { slotEmpty } from '@/util/globals';
import ActionButtonGroup, { type ActionButtonProps } from '@/components/Inputs/Components/ActionButtonGroup.vue';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import { safeHtmlStringify } from '@/util/safe-html-stringify';
import { twMerge } from 'tailwind-merge';

type Props = {
  small?: boolean;
  tiny?: boolean;
  medium?: boolean;
  title?: string | null;
  titleHighlight?: string | string[];
  loading?: boolean;
  updateButtonString?: string;
  deleteButtonString?: string;
  disabled?: boolean;
  canDelete?: boolean;
  onlyCloseButton?: boolean;
  update?: boolean;
  createButtonText?: string;
  subHeaderText?: string | null;
  warningBeforeClose?: boolean;
  headerActions?: ActionButtonProps[];
  baseZIndex?: number;
  withFirstInputFocus?: boolean;
  withinSame?: boolean;
  mainContentClasses?: string | null;
  mainHeader?: boolean;
  footerClasses?: string;
};

const props = withDefaults(defineProps<Props>(), {
  small: false,
  medium: false,
  tiny: false,
  withinSame: false,
  title: null,
  loading: false,
  canDelete: false,
  titleHighlight: '',
  updateButtonString: 'Update',
  deleteButtonString: 'Delete',
  disabled: false,
  onlyCloseButton: false,
  update: false,
  createButtonText: 'Create',
  subHeaderText: null,
  mainContentClasses: null,
  warningBeforeClose: false,
  withFirstInputFocus: true,
  headerActions: () => [],
  baseZIndex: 100,
  mainHeader: false,
  footerClasses: '',
});

const emit = defineEmits<{
  (event: 'closed'): void;
  (event: 'disabled-clicked'): void;
  (event: 'create', close: () => void): void;
  (event: 'update', close: () => void): void;
  (event: 'delete', close: () => void): void;
}>();

const { isSmallScreen } = useSmallScreen();

const create = (close: () => void): void => {
  emit('create', close);
};

const updateClick = (close: () => void): void => {
  emit('update', close);
};

const deleteClick = (close: () => void): void => {
  emit('delete', close);
};

const getTitle = () => {
  let title = props.title;

  const replaceWord = (string: string, word: string) => {
    return string.replace(word, `<span class="mx-edge-1/4">"${word}"</span>`);
  };

  if (props.titleHighlight) {
    switch (typeof props.titleHighlight) {
      case 'object': {
        for (let i = 0; i < props.titleHighlight.length; i++) {
          title = replaceWord(title, props.titleHighlight[i]);
        }
        break;
      }
      case 'string': {
        title = replaceWord(title, props.titleHighlight);
        break;
      }
      default: {
        break;
      }
    }
  }
  return title;
};

const contentSlot = ref<HTMLInputElement | null>(null);
const checkIfSomethingShouldBeActive = () => {
  if (!props.withFirstInputFocus) return;
  try {
    const focusElements = ['INPUT', 'TEXTAREA', 'SELECT'];
    let activeElement = document.activeElement;
    if (focusElements.includes(activeElement.tagName)) return;
    const firstInput = contentSlot.value?.querySelector('input');
    if (firstInput) {
      firstInput.focus();
    }
  } catch (e) {}
};
setTimeout(() => {
  checkIfSomethingShouldBeActive();
}, 400);
</script>

<template>
  <BaseSlideout
    :model-value="true"
    :tiny="tiny"
    :small="small"
    :medium="medium"
    :within-same="withinSame"
    :base-z-index="baseZIndex"
    :main-content-classes="mainContentClasses"
    :header-actions="headerActions"
    :warning-before-close="warningBeforeClose"
    :header-text="title"
    @closed="$emit('closed')">
    <template
      v-if="!slotEmpty($slots, 'menuContainer')"
      #menuContainer>
      <slot name="menuContainer"></slot>
    </template>
    <template #main-content="{ close }">
      <div
        v-if="!isSmallScreen"
        class="flex h-full flex-col">
        <slot name="titleSlot">
          <div class="h-[6rem] items-center border-b px-edge">
            <slot name="title">
              <div class="flex h-full items-center justify-between">
                <div class="flex h-full flex-col justify-center">
                  <div class="flex items-center gap-edge-1/2">
                    <h1
                      :class="{ 'text-4xl': mainHeader }"
                      v-html="safeHtmlStringify(getTitle())" />
                    <IconWithLoading
                      v-if="loading"
                      loading />
                    <slot name="afterTitle" />
                  </div>
                  <slot name="subHeader">
                    <h6 v-if="subHeaderText">
                      {{ subHeaderText }}
                    </h6>
                  </slot>
                </div>
                <ActionButtonGroup :actions="headerActions" />
              </div>
            </slot>
          </div>
        </slot>
        <div
          ref="contentSlot"
          class="flex-1 overflow-auto">
          <slot :close="close" />
        </div>
        <div
          v-if="!onlyCloseButton"
          :class="twMerge('h-[64px] border-t bg-content', footerClasses)">
          <slot
            name="footer"
            :close="close">
            <div class="flex h-full items-center justify-between gap-edge px-edge">
              <div>
                <slot name="area-in-footer-for-buttons"></slot>
              </div>
              <div
                v-if="update"
                class="flex items-center gap-edge">
                <VButton
                  type="warning"
                  :title="deleteButtonString"
                  :loading="loading"
                  :emphasized="false"
                  size="lg"
                  disabled-tool-tip-text="test"
                  @click="deleteClick(close)" />
                <VButton
                  type="primary"
                  :title="updateButtonString"
                  :disabled="disabled"
                  :emphasized="true"
                  :loading="loading"
                  size="lg"
                  @click="updateClick(close)" />
              </div>
              <div v-else>
                <VButton
                  type="primary"
                  size="lg"
                  :disabled="disabled"
                  :emphasized="true"
                  disabled-tool-tip-text="test"
                  :title="createButtonText"
                  :loading="loading"
                  @click="create(close)" />
              </div>
            </div>
          </slot>
        </div>
      </div>
      <div
        v-else
        class="flex h-full flex-col gap-1">
        <div class="flex items-center justify-between gap-edge-1/4 border-b p-edge">
          <div class="flex-1">
            <slot name="title">
              <h3>
                {{ title }}
              </h3>
              <h6 v-if="subHeaderText">
                {{ subHeaderText }}
              </h6>
            </slot>
          </div>
          <i
            class="fa fa-fw fa-times fa-2x"
            @click="close()" />
        </div>
        <div class="flex-1 overflow-auto">
          <slot />
        </div>
        <div
          v-if="!onlyCloseButton"
          class="w-full border-t p-edge">
          <slot
            name="footer"
            :close="close">
            <div class="flex h-full items-center justify-between gap-edge px-edge-1/4">
              <div>
                <slot name="area-in-footer-for-buttons"></slot>
              </div>
              <template v-if="update">
                <VButton
                  type="warning"
                  :title="deleteButtonString"
                  :disabled="disabled"
                  :loading="loading"
                  @click="deleteClick(close)" />
                <VButton
                  type="primary"
                  :title="updateButtonString"
                  :disabled="disabled"
                  :emphasized="true"
                  :loading="loading"
                  @click="updateClick(close)" />
              </template>
              <VButton
                v-else
                type="primary"
                :disabled="disabled"
                :emphasized="true"
                :title="createButtonText"
                :loading="loading"
                @click="create(close)" />
            </div>
          </slot>
        </div>
      </div>
    </template>
  </BaseSlideout>
</template>
