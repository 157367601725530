<script setup lang="ts">
import { computed, ref } from 'vue';
import { router, usePage } from '@inertiajs/vue3';
import VDropdown, { type DropDownListItem } from '@/components/Inputs/Dropdown/VDropdown.vue';
import UserProfileModal from '@/components/Modals/UserProfileModal.vue';

const activeUsers = ref([]);
const channelId = ref('');
const selectedUser = ref(null);

const validUser = (user) => !user.email.includes('@crescat') && user.id !== usePage().props.auth.user.id;

const listenForBroadcast = () => {
  Echo.join(`On.${channelId.value}`)
    .here((users) => {
      activeUsers.value = users.filter((user) => validUser(user));
    })
    .joining((user) => {
      if (validUser(user) && !activeUsers.value.some((u) => u.id === user.id)) {
        activeUsers.value.push(user);
      }
    })
    .leaving((user) => {
      if (validUser(user)) {
        const index = activeUsers.value.findIndex((u) => u.id === user.id);
        if (index > -1) {
          activeUsers.value.splice(index, 1);
        }
      }
    })
    .error(() => {
      console.error('active users error');
    });
};

router.on('navigate', (event) => {
  if (channelId.value) {
    window.Echo.leave(`On.${channelId.value}`);
    channelId.value = '';
  }
  switch (event.detail.page.component) {
    case 'Group/GroupShow': {
      channelId.value = `Group.${event.detail.page.props.group.id}`;
      break;
    }
    case 'Group/GroupAdministrator': {
      channelId.value = `Group.${event.detail.page.props.group.id}.Administrator`;
      break;
    }
    case 'Festival/FestivalShow': {
      channelId.value = `Festival.${event.detail.page.props.festival.id}`;
      break;
    }
    case 'Festival/FestivalAdministrator': {
      channelId.value = `Festival.${event.detail.page.props.festival.id}.Administrator`;
      break;
    }
    case 'Festival/FestivalSectionShow': {
      channelId.value = `FestivalSection.${event.detail.page.props.festivalSection.id}`;
      break;
    }
    case 'PublicForm/PublicFormShow': {
      channelId.value = `PublicForm.${event.detail.page.props.publicForm.id}`;
      break;
    }
    case 'Performance/PerformanceShow': {
      channelId.value = `Performance.${event.detail.page.props.performance.id}`;
      break;
    }
    case 'Event/EventShow': {
      channelId.value = `Event.${event.detail.page.props.event.id}`;
      break;
    }
    default: {
    }
  }
  if (channelId.value) {
    listenForBroadcast();
  }
});

const filteredActiveUsers = computed(() => {
  const uniqueUsers = [];
  activeUsers.value.forEach((user) => {
    if (!uniqueUsers.some((u) => u.id === user.id)) {
      uniqueUsers.push(user);
    }
  });
  return uniqueUsers;
});

const items = computed(() => {
  const items: DropDownListItem = [
    {
      title: 'Active Users',
      type: 'header',
    },
  ];

  if (filteredActiveUsers.value.length === 0) {
    items.push({
      title: 'No Active Users',
      value: 1,
    });
  } else {
    items.push(
      ...filteredActiveUsers.value.map((user) => ({
        title: user.name,
        value: user.id,
        avatar_url: user.avatar_url,
        action: (close: () => void) => {
          selectedUser.value = user;
          close();
        },
      }))
    );
  }
  return items;
});
</script>

<template>
  <div>
    <VDropdown
      v-if="channelId && filteredActiveUsers.length > 0"
      :style="'width: ' + filteredActiveUsers.length * 25 + 'px;'"
      class="max-w-[67px]"
      :items="items">
      <template #click-area>
        <div class="flex items-center -space-x-edge-1/4 overflow-hidden">
          <img
            v-for="(user, index) in filteredActiveUsers.filter((u, i) => i < 2)"
            class="inline-block h-[25px] w-[25px] rounded-full border object-cover"
            :title="user.name"
            :src="user.avatar_url"
            :alt="user.name" />
          <div
            v-if="filteredActiveUsers.length > 2"
            dusk="navbar-active-users"
            class="inline-flex h-[25px] w-[25px] items-center rounded border bg px-edge-1/4 pt-1 text-center text-xs font-semibold">
            +{{ filteredActiveUsers.length - 2 }}
          </div>
        </div>
      </template>
      <template #pre="{ item }">
        <img
          :src="item.avatar_url"
          class="h-[16px] w-[16px] rounded-full object-cover"
          alt="user image" />
      </template>
    </VDropdown>
    <UserProfileModal
      v-if="selectedUser?.id"
      show-image
      :name="selectedUser.name"
      :email="selectedUser.email"
      :phone="selectedUser.phone"
      :avatar-url="selectedUser.avatar_url"
      @closed="selectedUser = null" />
  </div>
</template>
