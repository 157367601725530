<script lang="ts" setup>
import { VueFinalModal } from 'vue-final-modal';
import { ZIndexPopUpModal } from '@/variables/z-indexes';
import VButton, { type ButtonProps } from '@/components/Inputs/VButton.vue';
import { ref } from 'vue';
import { useEventListener } from '@vueuse/core';
import VSelect from '@/components/Inputs/VSelect.vue';

type Props = {
  title: string;
  description?: string;
  buttonText: ButtonProps['title'];
  buttonType: ButtonProps['type'];
  options: {
    value: string | number;
    label: string;
  }[];
  useSelect?: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  submit: [value: string | number];
  closed: [];
}>();

const setIndex = ({ index }: { index: number }) => {
  if (index === 0) return ZIndexPopUpModal;
  return ZIndexPopUpModal + 2 * index;
};

const selected = ref(props.options[0].value);

const onSubmit = () => {
  emit('submit', selected.value);
};

const createButton = ref<HTMLButtonElement | null>(null);

useEventListener('keydown', (e) => {
  if (e.key === 'Enter') {
    const focusElements = ['TEXTAREA', 'SELECT'];
    let activeElement = document.activeElement;
    if (focusElements.includes(activeElement.tagName)) return;

    const richTextField = document.getElementsByClassName('ProseMirror-focused');
    if (richTextField.length > 0) return;

    if (createButton.value?.button) {
      createButton.value?.button?.click();
    }
  }
});
</script>

<template>
  <VueFinalModal
    :content-transition="{
      'enter-active-class': 'transition duration-300 ease-in-out transform',
      'enter-from-class': 'scale-0 opacity-0',
      'enter-to-class': '',
      'leave-active-class': 'transition duration-200 ease-in-out transform',
      'leave-to-class': 'opacity-0',
      'leave-from-class': '',
    }"
    overlay-class="bg-[--color-backdrop-modal]"
    class="flex items-center justify-center"
    :z-index-fn="setIndex"
    :content-class="`bg rounded border w-[400px] text max-h-full`"
    @closed="$emit('closed')">
    <div class="relative">
      <div
        class="flex h-[64px] items-center justify-between gap-edge-1/4 rounded-t border-b bg-[--color-background-modal-header] px-edge">
        <h2 class="flex-1">
          <slot name="heading-text">
            {{ title }}
          </slot>
        </h2>
        <div>
          <VButton
            icon="fa-times"
            size="sm"
            @click="$emit('closed')" />
        </div>
      </div>

      <div class="px-edge">
        <div
          v-if="description"
          class="py-edge">
          {{ description }}
        </div>
        <ul
          v-if="!useSelect"
          class="space-y-edge-1/2 pb-edge">
          <li
            v-for="option in options"
            :key="option.value"
            class="flex cursor-pointer items-center gap-edge"
            @click="selected = option.value">
            <input
              v-model="selected"
              type="radio"
              style="box-shadow: none"
              class="focus:ring-transparent checked:appearance-none checked:bg-highlight checked:text-highlight focus:bg-highlight focus:shadow-none focus:outline-0 focus:ring-0 checked:focus:border-0 checked:focus:ring-0 focus-visible:bg-highlight focus-visible:outline-none focus-visible:ring-0"
              :value="option.value" />

            <div>
              {{ option.label }}
            </div>
          </li>
        </ul>

        <div
          v-if="useSelect"
          class="space-y-edge-1/2 pb-edge">
          <VSelect
            v-model="selected"
            :option-key="'value'"
            :option-value="'label'"
            :options="options" />
        </div>
      </div>

      <div class="flex h-[64px] items-center justify-between gap-edge border-t border-transparent px-edge">
        <VButton
          title="Cancel"
          :size="'lg'"
          :emphasized="true"
          @click="$emit('closed')" />
        <VButton
          ref="createButton"
          :size="'lg'"
          :emphasized="true"
          :title="buttonText"
          :type="buttonType"
          @click="onSubmit" />
      </div>
    </div>
  </VueFinalModal>
</template>
