<script lang="ts" setup>
import { VueFinalModal } from 'vue-final-modal';
import { ZIndexPopUpModal } from '@/variables/z-indexes';
import { getKey } from '@/util/globals';
import { safeHtmlStringify } from '@/util/safe-html-stringify';
import VButton, { type ButtonProps } from '@/components/Inputs/VButton.vue';

type Props = {
  title: string;
  content?: string | null;
  titleHighlight?: string | string[];
  buttons?: ButtonProps &
    {
      emit?: string;
    }[];
  overrideClasses?: string;
};

const props = withDefaults(defineProps<Props>(), {
  title: '',
  content: null,
  titleHighlight: '',
  buttons: () => [
    {
      title: 'Cancel',
      emit: 'cancel',
    },
    {
      type: 'primary',
      title: 'Confirm',
      emit: 'confirm',
    },
  ],
  overrideClasses: '',
});

defineEmits<{
  (event: 'cancel'): void;
  (event: 'close'): void;
  (event: 'keep'): void;
  (event: 'confirm', close: () => void): void;
  (event: 'sure', close: () => void): void;
  (event: 'remove', close: () => void): void;
  (event: 'first', close: () => void): void;
  (event: 'second', close: () => void): void;
  (event: 'third', close: () => void): void;
  (event: 'forth', close: () => void): void;
  (event: 'fifth', close: () => void): void;
  (event: 'pdf', close: () => void): void;
  (event: 'csv', close: () => void): void;
}>();

const getTitle = () => {
  let title = props.title;

  const replaceWord = (string: string, word: string) => {
    return string.replace(word, `<h2 class="text-highlight mx-edge-1/4">${word}</h2>`);
  };

  if (props.titleHighlight) {
    switch (typeof props.titleHighlight) {
      case 'object': {
        for (let i = 0; i < props.titleHighlight.length; i++) {
          title = replaceWord(title, props.titleHighlight[i]);
        }
        break;
      }
      case 'string': {
        title = replaceWord(title, props.titleHighlight);
        break;
      }
      default: {
        break;
      }
    }
  }
  return title;
};

const setIndex = ({ index }: { index: number }) => {
  if (index === 0) return ZIndexPopUpModal;
  return ZIndexPopUpModal + 2 * index;
};
</script>

<template>
  <VueFinalModal
    :content-transition="{
      'enter-active-class': 'transition duration-300 ease-in-out transform',
      'enter-from-class': 'scale-0 opacity-0',
      'enter-to-class': '',
      'leave-active-class': 'transition duration-200 ease-in-out transform',
      'leave-to-class': 'opacity-0',
      'leave-from-class': '',
    }"
    overlay-class="bg-[--color-backdrop-modal]"
    class="flex items-center justify-center"
    :z-index-fn="setIndex"
    :content-class="`bg rounded border shadow-lg w-[400px] text max-h-full ${overrideClasses}`"
    @click-outside="$emit('cancel')">
    <div class="relative rounded">
      <div
        class="flex h-[64px] items-center justify-between gap-edge-1/4 rounded-t border-b bg-[--color-background-modal-header] px-edge">
        <h2
          :title="safeHtmlStringify(getTitle())"
          class="flex-1 truncate">
          <slot name="heading-text">
            <span v-html="safeHtmlStringify(getTitle())" />
          </slot>
        </h2>
        <div>
          <VButton
            icon="fa-times"
            size="sm"
            @click="$emit('cancel')" />
        </div>
      </div>

      <div class="p-edge">
        <div
          v-if="content"
          class="text-base"
          v-html="safeHtmlStringify(content)" />
      </div>

      <div
        :class="`flex h-[64px] items-center gap-edge border-t border-transparent px-edge ${buttons && buttons.length === 1 ? 'justify-end' : 'justify-between'}`">
        <VButton
          v-for="b in buttons"
          :key="getKey(b, 'title')"
          :icon="getKey(b, 'icon')"
          :type="getKey(b, 'type', 'default')"
          :size="getKey(b, 'size', 'lg')"
          :emphasized="getKey(b, 'emphasized', true)"
          :title="getKey(b, 'title')"
          @click="$emit(b.emit)" />
      </div>
    </div>
  </VueFinalModal>
</template>
