<script setup lang="ts">
import moment from 'moment';
import { dateFormat, dateTimeFormat } from '@/variables/date-format';
import VSelect from '@/components/Inputs/VSelect.vue';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import DateHourSelector from '@/components/Inputs/Date/DateHourSelector.vue';
import { ref } from 'vue';

type Props = {
  start?: string | null;
  end?: string | null;
  withRange?: boolean | null;
  setFocus?: boolean | null;
  withTime?: boolean | null;
  vertical?: boolean | null;
  granularity?: string | null;
  monthsForRange?: [] | null;
};
const props = withDefaults(defineProps<Props>(), {
  start: null,
  end: null,
  withRange: true,
  setFocus: false,
  withTime: false,
  vertical: false,
  granularity: 'year',
  monthsForRange: () => [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6],
});

const emit = defineEmits<{
  (e: 'update:start', value: string): void;
  (e: 'update:end', value: string): void;
}>();

const rangeId = ref(0);
const ranges = ref([]);

const updateStartAndEndBasedOnRange = () => {
  const index = _.findIndex(ranges.value, (r) => r.id === rangeId.value);
  if (index > -1) {
    emit('update:start', ranges.value[index].start);
    emit('update:end', ranges.value[index].end);
  }
};

const setupRange = () => {
  ranges.value = [];

  switch (props.granularity) {
    case 'month': {
      const months = props.monthsForRange;
      ranges.value.push({
        id: null,
        name: 'Custom Range',
        start: moment().startOf('month').format(dateTimeFormat),
        end: moment().endOf('month').format(dateTimeFormat),
      });
      for (let i = 0; i < months.length; i++) {
        ranges.value.push({
          id: months[i],
          name: `${moment().add(months[i], 'months').startOf('month').format('MMMM YYYY')}`,
          start: moment().add(months[i], 'months').startOf('month').format(dateTimeFormat),
          end: moment().add(months[i], 'months').endOf('month').format(dateTimeFormat),
        });
      }
      break;
    }
    case 'year':
    default: {
      ranges.value = [
        {
          id: 0,
          name: 'Custom Range',
          start: moment().format(dateFormat),
          end: moment().format(dateFormat),
        },
        {
          id: 1,
          name: `01.01 - 31.12 ${moment().startOf('year').subtract('24', 'months').format('YYYY')}`,
          start: moment().startOf('year').subtract('24', 'months').format(dateFormat),
          end: moment().startOf('year').subtract('12', 'months').subtract('1', 'day').format(dateFormat),
        },
        {
          id: 2,
          name: `01.01 - 31.12 ${moment().startOf('year').subtract('12', 'months').format('YYYY')}`,
          start: moment().startOf('year').subtract('12', 'months').format(dateFormat),
          end: moment().startOf('year').subtract('1', 'day').format(dateFormat),
        },
        {
          id: 3,
          name: `01.01 - 31.12 ${moment().startOf('year').format('YYYY')}`,
          start: moment().startOf('year').format(dateFormat),
          end: moment().startOf('year').add('12', 'months').subtract('1', 'day').format(dateFormat),
        },
        {
          id: 4,
          name: `01.01 - 31.12 ${moment().startOf('year').add('12', 'months').format('YYYY')}`,
          start: moment().startOf('year').add('12', 'months').format(dateFormat),
          end: moment().startOf('year').add('24', 'months').subtract('1', 'day').format(dateFormat),
        },
      ];
    }
  }
};
setupRange();

const newStart = (dateTime) => {
  document.activeElement.blur();
  if (!props.end) {
    emit('update:end', moment(dateTime).add(1, 'day').format(dateFormat));
  }
  if (moment(dateTime, dateFormat).isAfter(props.end)) {
    emit('update:end', moment(dateTime).add(1, 'day').format(dateFormat));
  }
  emit('update:start', moment(dateTime).format(dateFormat));
};
const newEnd = (dateTime) => {
  document.activeElement.blur();
  if (!props.start) {
    emit('update:start', moment(dateTime).subtract(1, 'day').format(dateFormat));
  }
  if (moment(dateTime, dateFormat).isBefore(props.start)) {
    emit('update:start', moment(dateTime).subtract(1, 'day').format(dateFormat));
  }
  emit('update:end', moment(dateTime).format(dateFormat));
};
</script>

<template>
  <div class="form-layout mt-edge-1/4 grid-cols-2">
    <VSelect
      v-if="withRange"
      v-model="rangeId"
      :set-focus="setFocus"
      class="col-span-2"
      label="Select range"
      :options="ranges"
      option-text="name"
      @update:model-value="updateStartAndEndBasedOnRange" />

    <template v-if="Number(rangeId) === 0 || !withRange">
      <VDatepicker
        v-if="!withTime"
        :model-value="start"
        required
        :min-date="null"
        label="Start"
        @update:model-value="newStart" />
      <DateHourSelector
        v-if="withTime"
        :date-time="start"
        required
        :vertical="vertical"
        :min-date="null"
        label="Start"
        @update:date-time="newStart" />
      <VDatepicker
        v-if="!withTime"
        :model-value="end"
        required
        :min-date="null"
        label="End"
        @update:model-value="newEnd" />
      <DateHourSelector
        v-if="withTime"
        :date-time="end"
        required
        :vertical="vertical"
        :min-date="null"
        label="End"
        @update:date-time="newEnd" />
    </template>
  </div>
</template>
