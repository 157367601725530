<script setup lang="ts">
import { useShiftTypes } from '@/composables/use-shift-types';
import { ShiftResource } from '@/types/event';
import { InviteResource } from '@/types/invite';
import moment from 'moment';
import { computed, ref } from 'vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import { dateFormat, timeFormat } from '@/variables/date-format';
import { getColorOfShift, getTitleOfShift, resendShiftNotification, shiftIsFuture } from '@/helpers/shiftFunctions';
import { useSmallScreen } from '@/composables/use-small-screen';
import { useEmitStore } from '@/store/EmitStore';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import DisplayBadge from '@/components/Display/DisplayBadge.vue';

type Props = {
  invite: InviteResource;
  groupId: number;
  canEdit: boolean;
  show: boolean;
};

const props = defineProps<Props>();

defineEmits<{
  (event: 'update:show', arg: boolean): void;
}>();

const working = ref(false);

const shifts = ref(props.invite.owner_shifts);

const { shiftTypes, fetch: fetchShiftTypes } = useShiftTypes('Group', props.groupId, false);
fetchShiftTypes();

const formatted = computed(() => {
  return shifts.value.map((s) => Object.assign(s, { start_date: moment(s.start).format(dateFormat) }));
});

const sortedItems = computed(() => {
  return _.orderBy(formatted.value, 'start');
});

const dates = computed(() => {
  return _.groupBy(sortedItems.value, 'start_date');
});

const formattedDate = (date: string) => moment(date).format('dddd Do [of] MMMM');

const getShiftDuration = (start: string, end: string) => {
  if (end) {
    const val = moment(end).valueOf() - moment(start).valueOf();
    if (val > 24 * 60 * 60 * 1000) {
      const num = Math.floor(val / (60 * 60 * 1000));
      return `${moment(start).format(timeFormat)} - ${moment(end).format(timeFormat)} (${num}h)`;
    }
    return `${moment(start).format(timeFormat)} - ${moment(end).format(timeFormat)}`;
  }
  return moment(start).format(timeFormat);
};

const getShiftTypeTitle = (shift: ShiftResource) => {
  const index = _.findIndex(shiftTypes.value, (r) => r.id === shift.shift_type_id);
  if (index > -1) {
    return shiftTypes.value[index].title + (shift.title ? ` - ${shift.title}` : '');
  }
  return shift.title;
};
const { isSmallScreen } = useSmallScreen();

const loading = ref(false);
const fetchShifts = async () => {
  if (loading.value) return;
  loading.value = true;
  const { data } = await axios.get(`/api/invites/${props.invite.id}/owner-shifts`);
  shifts.value = data;
  loading.value = false;
};

useEmitStore().$subscribe((mutation, state) => {
  switch (state.item?.key) {
    case 'shifts-changed': {
      fetchShifts();
      break;
    }
    default:
      break;
  }
});
</script>

<template>
  <BoxContainer
    title="Shifts"
    :header-size="'h3'"
    :content-padding="false">
    <div class="content">
      <VTable
        v-if="sortedItems.length === 0"
        edge-to-edge>
        <VTableRow no-background>
          <VTableCell>
            <div class="italic text-soft">No Shifts Added</div>
          </VTableCell>
        </VTableRow>
      </VTable>

      <VTable
        v-for="date in dates"
        :key="date"
        edge-to-edge
        :bordered-table="true"
        row-size="small">
        <VTableRow no-background>
          <VTableCell
            main-cell
            colspan="100%">
            <h4>
              {{ formattedDate(date[0].start_date) }}
            </h4>
          </VTableCell>
        </VTableRow>

        <VTableRow
          classes="!bg-content-secondary"
          head>
          <VTableCell style="min-width: 40px; max-width: 40px; width: 40px" />
          <VTableCell style="min-width: 111px; max-width: 111px; width: 111px">When</VTableCell>
          <VTableCell style="width: calc(45% - 25px)"> Who</VTableCell>
          <VTableCell
            v-if="!isSmallScreen"
            style="width: calc(40% - 70px)">
            Title
          </VTableCell>
          <VTableCell v-if="!isSmallScreen" />
        </VTableRow>

        <VTableRow v-for="shift in date">
          <VTableCell
            main-cell
            style="min-width: 40px; max-width: 40px; width: 40px">
            <div
              v-if="
                shift.cancelled_at === null &&
                shift.approved &&
                shift.user_id &&
                !shift.accepted_at &&
                !shift.declined_at &&
                shiftIsFuture(shift)
              "
              class="change-fa-icon [&>i]:text-xs">
              <i
                v-if="working"
                class="fa fa-fw fa-circle-o-notch fa-spin" />
              <i
                v-if="!working"
                class="fa fa-fw fa-circle remove-hover text-pending" />
              <i
                v-if="!working"
                title="Send Reminder"
                class="fa fa-fw fa-envelope show-hover cursor-pointer"
                @click="resendShiftNotification(shift)" />
            </div>

            <i
              v-else
              :class="{ 'fa-exclamation-circle !text-soft': !shift.approved, 'fa-circle': shift.approved }"
              class="fa fa-fw text-xs"
              :title="getTitleOfShift(shift)"
              :style="'color: ' + getColorOfShift(shift) + ';'" />
          </VTableCell>
          <VTableCell style="min-width: 100px; max-width: 100px; width: 100px">
            <div class="">
              <span
                v-if="
                  (shift.check_in && shift.check_in !== shift.start) ||
                  (shift.check_out && shift.check_out !== shift.end)
                "
                style="text-decoration: line-through">
                {{ getShiftDuration(shift.start, shift.end) }}
              </span>
              {{
                getShiftDuration(
                  shift.check_in ? shift.check_in : shift.start,
                  shift.check_out ? shift.check_out : shift.end
                )
              }}
            </div>
          </VTableCell>
          <VTableCell
            :main-cell="shift.cancelled_at === null && shift.approved"
            style="width: calc(45% - 25px)">
            <div
              v-if="shift.cancelled_at !== null"
              class="italic">
              Cancelled
            </div>
            <template v-else-if="shift.user">
              {{ shift.user.name }}
            </template>
            <template v-else-if="shift.for_sale">
              <div class="flex">
                <DisplayBadge
                  size="sm"
                  :text="
                    shift.shift_interest_pivots.filter((p) => !p.declined_at).length === 0
                      ? 'For Sale'
                      : shift.shift_interest_pivots.filter((p) => !p.declined_at).length + ' Requests'
                  "
                  :color="
                    shift.shift_interest_pivots.filter((p) => !p.declined_at).length === 0 ? 'transparent' : 'success'
                  " />
              </div>
            </template>
            <template v-else> Un-assigned</template>
          </VTableCell>

          <VTableCell style="width: 200px">
            {{ getShiftTypeTitle(shift) }}
          </VTableCell>
          <VTableCell
            v-if="!isSmallScreen"
            style="width: 80px">
            <div
              v-if="shift.approved"
              class="grid grid-cols-3 items-center">
              <i
                v-if="shift.check_in"
                :title="'Checked in at ' + shift.check_in"
                class="fa fa-fw fa-sign-in" />
              <i
                v-if="shift.check_out"
                :title="'Checked out at ' + shift.check_out"
                class="fa fa-fw fa-sign-out" />
              <i
                v-if="shift.notes"
                :title="shift.notes"
                class="fa fa-fw fa-comment" />
            </div>
          </VTableCell>
        </VTableRow>
      </VTable>
    </div>
  </BoxContainer>
</template>
