<script lang="ts" setup>
import CalendarHead from '@/components/calendar/CalendarHead.vue';
import HoverBox from '@/components/HoverBox.vue';
import { changeAndFormatStamp } from '@/util/timeFunctions';
import type { CalendarApi, CalendarOptions, EventApi, EventClickArg, EventMountArg } from '@fullcalendar/core';
import { DayCellMountArg } from '@fullcalendar/core';
import type { EventImpl } from '@fullcalendar/core/internal';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import resourceDayGrid from '@fullcalendar/resource-daygrid';
import resourceTimeGrid from '@fullcalendar/resource-timegrid';
import resourceTimeline from '@fullcalendar/resource-timeline';
import scrollgrid from '@fullcalendar/scrollgrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import FullCalendar from '@fullcalendar/vue3';
import { computed, nextTick, ref, watch } from 'vue';
import moment from 'moment';
import VButton from '@/components/Inputs/VButton.vue';
import LoadingScreen from '@/components/LoadingScreen.vue';

type Props = {
  options: CalendarOptions;
  views?: string[];
  view: string;
  hoverOnDates?: boolean;
  hasEventHover?: boolean;
  hoverOnEvents?: boolean;
  hasEventMenu?: boolean;
  withClickDate?: boolean;
  titleIsClickableDate?: boolean;
  withClickEvent?: boolean;
  pastEventsFullColor?: boolean;
  withHeading?: boolean;
  showDefaultActions?: boolean;
  showTitle?: boolean;
  showViewSelector?: boolean;
  firstDayOfItem?: string | null;
  withToday?: boolean;
  allowedDates?: {
    allowedStart: string;
    allowedEnd: string;
  };
  headerActions?: [] | null;
  itemsForViewSelector?: [] | null;
  withRightClickDay?: boolean;
  showDateInCalendar?: boolean;
  sticky?: boolean;
  loading?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  hasEventHover: false,
  hoverOnDates: false,
  hasEventMenu: false,
  withClickDate: false,
  withClickEvent: false,
  withHeading: true,
  showDefaultActions: true,
  titleIsClickableDate: false,
  showTitle: true,
  showViewSelector: true,
  withToday: true,
  firstDayOfItem: null,
  showDateInCalendar: false,
  hoverOnEvents: false,
  pastEventsFullColor: false,
  allowedDates: {
    allowedStart: '',
    allowedEnd: '',
  },
  headerActions: () => [],
  itemsForViewSelector: () => [],
  views: () => [],
  withRightClickDay: false,
  sticky: false,
  loading: false,
});

const emit = defineEmits<{
  (e: 'eventHover', arg: { jsEvent: MouseEvent; event: EventImpl; el: HTMLElement }): void;
  (e: 'eventDidMount', arg: EventMountArg): void;
  (e: 'dateClick', arg: DateClickArg): void;
  (e: 'eventClick', arg: EventImpl, arg2: EventClickArg): void;
  (e: 'update:view', arg: string): void;
}>();

const hoverEvent = ref<EventApi | null>(null);
const hoverTimeout = ref<NodeJS.Timeout>();
const top = ref(0);
const left = ref(0);
const menuY = ref(0);
const menuX = ref(0);
const menuEvent = ref<null | {
  _def: EventImpl['_def'];
  title: EventImpl['title'];
  allDay: EventImpl['allDay'];
  start: EventImpl['start'];
  startStr: EventImpl['startStr'];
  end: EventImpl['end'];
  endStr: EventImpl['endStr'];
  groupId: EventImpl['groupId'];
  id: EventImpl['id'];
  url: EventImpl['url'];
  [key: string]: unknown;
}>(null);

const menuDay = ref<DayCellMountArg | null>(null);
const menuDayY = ref(0);
const menuDayX = ref(0);

const eventMouseLeave = () => {
  clearTimeout(hoverTimeout.value);
  top.value = 0;
  left.value = 0;
  hoverEvent.value = null;
};

const closeEventMenu = () => {
  menuX.value = 0;
  menuY.value = 0;
  menuEvent.value = null;
};

const closeDayMenu = () => {
  menuX.value = 0;
  menuY.value = 0;
  menuDay.value = null;
};

const footerOpen = ref(false);

const hoverFilters = ref<string[]>([]);
const selectedFilters = ref<string[]>([]);

const hoverFilterActions = {
  add: (filter: string) => {
    if (!hoverFilters.value.includes(filter)) {
      hoverFilters.value.push(filter);
    }
  },
  remove: (filter: string) => {
    hoverFilters.value = hoverFilters.value.filter((f) => f !== filter);
  },
};

const selectedFilterActions = (filter: string) => {
  if (!selectedFilters.value.includes(filter)) {
    selectedFilters.value.push(filter);
  } else {
    selectedFilters.value = selectedFilters.value.filter((f) => f !== filter);
  }
};

const calendar = ref<typeof FullCalendar | null>(null);

const fullCalendarApi = computed<CalendarApi | null>(() => {
  if (calendar.value) {
    return calendar.value.getApi();
  }
  return null;
});

defineExpose({
  api: fullCalendarApi,
  calendar: calendar,
});

const title = ref('');

const eventBounds = ref<DOMRect | null>(null);

const calendarIsLoading = ref(false);

const calendarData = computed(() => {
  return {
    schedulerLicenseKey: '0037394323-fcs-1713539151',
    themeSystem: 'standard',
    plugins: [
      dayGridPlugin,
      listPlugin,
      timeGridPlugin,
      timelinePlugin,
      resourceTimeline,
      resourceTimeGrid,
      interactionPlugin,
      resourceDayGrid,
      scrollgrid,
    ],
    viewDidMount: (info) => {
      title.value = info.view.title;
    },
    headerToolbar: false,
    firstDay: 1,
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
    },
    locale: 'en-GB',
    height: 'auto',
    eventDisplay: 'block',
    buttonText: false,
    slotEventOverlap: false,
    displayEventTime: false,
    weekText: '',
    dateClick: (info) => {
      if (props.withClickDate) {
        emit('dateClick', info);
      }
    },
    dayCellDidMount(info) {
      if (props.withRightClickDay) {
        info.el.addEventListener('contextmenu', async (e) => {
          menuEvent.value = null;
          menuDay.value = null;
          e.preventDefault();
          eventMouseLeave();
          await nextTick();
          eventBounds.value = info.el.getBoundingClientRect();
          menuDayY.value = e.clientY;
          menuDayX.value = e.clientX;
          menuDay.value = info;
        });
      }
    },
    eventDidMount: (info) => {
      emit('eventDidMount', info);
      if (props.hasEventMenu && info.el) {
        try {
          if (info.event.display === 'background') {
            return;
          }
        } catch (e) {}
        info.el.addEventListener('contextmenu', async (e) => {
          menuDay.value = null;
          menuEvent.value = null;
          e.preventDefault();
          e.stopPropagation();
          eventMouseLeave();
          await nextTick();
          eventBounds.value = info.el.getBoundingClientRect();

          if (info.view.type === 'month') {
            const start = moment(info.event.start);
            const end = moment(info.event.end);

            if (start && end) {
              const diff = end.diff(start, 'days');
              if (diff > 1) {
                menuY.value = e.clientY + 5;
                menuX.value = e.clientX + 5;
              } else {
                const diffHours = end.diff(start, 'hours');
                const endHour = end.hour();
                const endDay = end.day();
                const startDay = start.day();
                if (diffHours > 12 && endHour >= 6 && endDay > startDay) {
                  menuY.value = e.clientY + 5;
                  menuX.value = e.clientX + 5;
                } else {
                  menuY.value = info.el.getBoundingClientRect().bottom;
                  menuX.value = info.el.getBoundingClientRect().right;
                }
              }
            } else {
              menuY.value = info.el.getBoundingClientRect().bottom;
              menuX.value = info.el.getBoundingClientRect().right;
            }
          } else {
            menuY.value = e.clientY + 5;
            menuX.value = e.clientX + 5;
          }

          menuEvent.value = {
            _def: info.event._def,
            title: info.event.title,
            allDay: info.event.allDay,
            start: info.event.start,
            startStr: info.event.startStr,
            end: info.event.end,
            endStr: info.event.endStr,
            groupId: info.event.groupId,
            id: info.event.id,
            url: info.event.url,
            ...info.event.extendedProps,
          };
        });
      }
    },
    eventClick: (info) => {
      if (props.withClickEvent) {
        emit('eventClick', info.event, info);
      }
    },
    loading(isLoading) {
      if (isLoading) {
        calendarIsLoading.value = true;
        return;
      }

      setTimeout(() => {
        eventMouseLeave();
        closeEventMenu();
        closeDayMenu();
        calendarIsLoading.value = false;
      }, 100);
    },
    eventMouseLeave,
    eventMouseEnter({ jsEvent, event, el, view }) {
      if (props.hasEventHover && el) {
        hoverEvent.value = null;
        hoverTimeout.value = setTimeout(() => {
          closeEventMenu();
          emit('eventHover', { jsEvent, event, el });
          if (view.type === 'month' || view.type === 'week') {
            const start = moment(event.start);
            const end = moment(event.end);

            if (start && end) {
              const diff = end.diff(start, 'days');
              if (diff > 1) {
                top.value = jsEvent.clientY + 5;
                left.value = jsEvent.clientX + 5;
              } else {
                const diffHours = end.diff(start, 'hours');
                const endHour = end.hour();
                const endDay = end.day();
                const startDay = start.day();
                if (diffHours > 12 && endHour >= 6 && endDay > startDay) {
                  top.value = jsEvent.clientY + 5;
                  left.value = jsEvent.clientX + 5;
                } else {
                  top.value = el.getBoundingClientRect().bottom;
                  left.value = el.getBoundingClientRect().right;
                }
              }
            } else {
              top.value = el.getBoundingClientRect().bottom;
              left.value = el.getBoundingClientRect().right;
            }
          } else {
            top.value = jsEvent.clientY + 5;
            left.value = jsEvent.clientX + 5;
          }
          eventBounds.value = el.getBoundingClientRect();
          hoverEvent.value = event;
        }, 250);
      }
    },
    ...props.options,
  } as CalendarOptions;
});

const showFullcalendar = computed(() => {
  if (!calendarData.value || !calendarData.value.views) return false;
  return props.view in calendarData.value.views && !['list', 'table'].includes(props.view);
});

const calendarCreated = computed(() => calendar.value !== null);

watch(showFullcalendar, (show) => {
  if (show && fullCalendarApi.value) {
    setTimeout(() => {
      fullCalendarApi.value?.render();
    }, 100);
  }
});

watch([() => props.view, () => calendarCreated.value], () => {
  if (showFullcalendar.value && calendarCreated.value) {
    calendar.value?.getApi().changeView(props.view);
    title.value = calendar.value?.getApi().view.title;
  }
});

const goToToday = () => {
  if (showFullcalendar.value) {
    calendar.value?.getApi().today();
  }
  if (showFullcalendar.value) {
    title.value = calendar.value?.getApi().view.title;
  }
};

const goToPastDate = () => {
  if (showFullcalendar.value) {
    calendar.value?.getApi().prev();
  }
  if (showFullcalendar.value) {
    title.value = calendar.value?.getApi().view.title;
  }
};

const goToFeatureDate = () => {
  if (showFullcalendar.value) {
    calendar.value?.getApi().next();
  }
  if (showFullcalendar.value) {
    title.value = calendar.value?.getApi().view.title;
  }
};

const goToDate = (date: string) => {
  if (showFullcalendar.value) {
    calendar.value?.getApi().gotoDate(date);
  }
  if (showFullcalendar.value) {
    title.value = calendar.value?.getApi().view.title;
  }
};

const showDateCircle = () => {
  if (!calendar.value) return false;
  switch (calendar.value?.getApi().view.type) {
    case 'month':
      return true;
    default: {
      return false;
    }
  }
};

const setTitle = (newTitle: string) => {
  title.value = newTitle;
};

export type SlotLabelProp = {
  date: Date;
  text: string;
  isPast?: boolean;
  isFuture?: boolean;
  isToday?: boolean;
  el?: HTMLElement;
  level?: number;
  view: any;
};

defineSlots<{
  'day-menu'?: (props: { event: DayCellMountArg; close: () => void }) => any;
  'header-left'?: (props: any) => any;
  'today-button'?: (props: any) => any;
  'title'?: (props: { data: Date | undefined }) => any;
  'heading'?: (props: any) => any;
  'heading-right'?: (props: any) => any;
  'under-heading'?: (props: any) => any;
  'event'?: (props: { event: any; hoverFilters: any; selectedFilters: any }) => any;
  'resourceGroupLabelContent'?: (props: { resource: any }) => any;
  'weekNumberContent'?: (props: { resource: any }) => any;
  'dayCellContent'?: (props: { resource: any }) => any;
  'resourceAreaHeaderContent'?: (props: { resource: any }) => any;
  'resourceLaneContent'?: (props: { resource: any }) => any;
  'resourceLabelContent'?: (props: { resource: any }) => any;
  'list'?: (props: { setTitle: (name: string) => void; selectedFilters: any }) => any;
  'table'?: (props: { setTitle: (name: string) => void; selectedFilters: any }) => any;
  'footer'?: (props: {
    hoverActions: any;
    hoverFilters: any;
    open: any;
    selectActions: any;
    selectedFilters: any;
    toggleFooter: any;
  }) => any;
  'event-hover'?: (props: { event: EventApi | null }) => any;
  'event-menu'?: (props: { event: any; close: () => void }) => any;
  'slotLabelContent'?: (props: { arg: SlotLabelProp }) => any;
}>();
</script>

<template>
  <div
    :class="{
      'hover-on-dates': hoverOnDates,
      'fc-event-hover-on-event [&_.fc-event]:cursor-pointer': hoverOnEvents,
      '[&_.fc-event-past]:!opacity-100 [&_.fc-event-past]:brightness-100': pastEventsFullColor,
    }"
    class="flex flex-col justify-between">
    <div :class="sticky ? 'flex flex-1 flex-col overflow-hidden' : 'flex-1 overflow-auto'">
      <CalendarHead
        v-if="withHeading"
        :current-view="view"
        class="border-b py-edge"
        :show-default-actions="showDefaultActions"
        :show-title="showTitle"
        :show-view-selector="showViewSelector"
        :start="changeAndFormatStamp({ stamp: calendar?.getApi().view.currentStart })"
        :title="title"
        :views="views"
        :with-today="withToday"
        :first-day-of-item="firstDayOfItem"
        :allowed-dates="allowedDates"
        :actions="headerActions"
        :sticky="sticky"
        :items-for-view-selector="itemsForViewSelector"
        :title-is-clickable-date="titleIsClickableDate"
        @future="goToFeatureDate"
        @past="goToPastDate"
        @today="goToToday"
        @date-change="goToDate"
        @update:current-view="$emit('update:view', $event)">
        <template #header-left>
          <slot name="header-left" />
        </template>
        <template #title>
          <span v-if="!showDateInCalendar" />
          <slot
            name="title"
            :data="fullCalendarApi?.getDate()" />
        </template>
        <template #default>
          <slot
            name="heading"
            :hover-actions="hoverFilterActions"
            :hover-filters="hoverFilters"
            :open="footerOpen"
            :select-actions="selectedFilterActions"
            :selected-filters="selectedFilters"
            :toggle-footer="() => (footerOpen = !footerOpen)" />
        </template>
        <template #under-heading>
          <slot
            name="under-heading"
            :hover-actions="hoverFilterActions"
            :hover-filters="hoverFilters"
            :open="footerOpen"
            :select-actions="selectedFilterActions"
            :selected-filters="selectedFilters"
            :toggle-footer="() => (footerOpen = !footerOpen)" />
        </template>
        <template #heading-right>
          <slot name="heading-right" />
        </template>
        <template #today-button>
          <slot name="today-button">
            <VButton
              v-if="firstDayOfItem"
              title="First Day"
              :tool-tip-text="'Go to first day'"
              size="sm"
              @click="goToDate(firstDayOfItem)" />
          </slot>
        </template>
      </CalendarHead>

      <div
        v-if="showFullcalendar"
        :class="{
          'flex-1 overflow-auto': sticky,
        }"
        class="v-calendar relative">
        <FullCalendar
          ref="calendar"
          :options="calendarData">
          <template
            v-if="$slots.event"
            #eventContent="{ event }">
            <slot
              :event="event"
              :hover-filters="hoverFilters"
              :selected-filters="selectedFilters"
              name="event">
              <span>
                {{ event }}
              </span>
            </slot>
          </template>
          <template #resourceGroupLabelContent="arg">
            <slot
              :resource="arg"
              name="resourceGroupLabelContent">
              <div>
                {{ arg.groupValue }}
              </div>
            </slot>
          </template>

          <template #weekNumberContent="arg">
            <slot
              :resource="arg"
              name="weekNumberContent" />
          </template>

          <template #dayCellContent="arg">
            <slot
              v-if="showDateCircle()"
              :resource="arg"
              name="dayCellContent">
              <div>
                <div class="mx-auto">
                  <div
                    :class="{ 'bg-highlight pt-[3px] text-sm': arg.isToday, 'pt-[2px] text-xs': !arg.isToday }"
                    class="h-[25px] w-[25px] rounded-full">
                    {{ arg.dayNumberText }}
                  </div>
                </div>
              </div>
            </slot>
          </template>

          <template #resourceAreaHeaderContent="arg">
            <slot
              :resource="arg"
              name="resourceAreaHeaderContent" />
          </template>

          <template #resourceLaneContent="arg">
            <slot
              :resource="arg"
              name="resourceLaneContent" />
          </template>

          <template #resourceLabelContent="{ resource }">
            <slot
              :resource="resource"
              name="resourceLabelContent">
              <div>
                {{ resource.title }}
              </div>
            </slot>
          </template>

          <template #slotLabelContent="arg">
            <slot
              :arg="arg"
              name="slotLabelContent">
              <div>
                {{ arg.text }}
              </div>
            </slot>
          </template>
        </FullCalendar>
        <div
          v-if="loading"
          class="absolute bottom-0 left-0 right-0 top-0 z-[5000] flex items-center justify-center bg">
          <LoadingScreen />
        </div>
      </div>

      <div
        v-if="!showFullcalendar"
        :class="{
          'flex-1 overflow-auto': sticky,
        }">
        <slot
          v-if="view === 'list'"
          :set-title="setTitle"
          :selected-filters="selectedFilters"
          name="list" />
        <slot
          v-if="view === 'table'"
          :set-title="setTitle"
          :selected-filters="selectedFilters"
          name="table" />
      </div>
    </div>

    <div
      v-if="$slots.footer && showFullcalendar"
      :class="!footerOpen ? 'h-context-sidebar-bottom-height' : 'h-fit-content min-h-[250px]'"
      class="overflow-hidden border-b-8 border-b-highlight">
      <slot
        :hover-actions="hoverFilterActions"
        :hover-filters="hoverFilters"
        :open="footerOpen"
        :select-actions="selectedFilterActions"
        :selected-filters="selectedFilters"
        :toggle-footer="() => (footerOpen = !footerOpen)"
        name="footer" />
    </div>

    <HoverBox
      v-if="hasEventHover && $slots['event-hover'] && hoverEvent && !calendarIsLoading"
      :close-on-scroll="true"
      :event-bounds="eventBounds"
      :always-in-view-port="true"
      :x-pos="left"
      :y-pos="top">
      <slot
        :event="hoverEvent"
        name="event-hover" />
    </HoverBox>

    <HoverBox
      v-if="hasEventMenu && $slots['event-menu'] && menuEvent && !calendarIsLoading"
      :close-on-scroll="true"
      :close-on-click="true"
      :event-bounds="eventBounds"
      :always-in-view-port="true"
      :x-pos="menuX"
      :y-pos="menuY"
      @closed="closeEventMenu">
      <slot
        :close="closeEventMenu"
        :event="menuEvent"
        name="event-menu" />
    </HoverBox>

    <HoverBox
      v-if="withRightClickDay && $slots['day-menu'] && menuDay && !calendarIsLoading"
      :close-on-scroll="true"
      :close-on-click="true"
      :event-bounds="eventBounds"
      :x-pos="menuDayX"
      :y-pos="menuDayY"
      @closed="closeDayMenu">
      <slot
        :close="closeDayMenu"
        :event="menuDay"
        name="day-menu" />
    </HoverBox>
  </div>
</template>
