<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import VMultiselect from '@/components/Inputs/VMultiselect.vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import BaseSlideout from '@/components/Base/BaseSlideout.vue';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import AuditsSidebarItem from '@/components/Audits/AuditsSidebarItem.vue';
import DisplayRichText from '@/components/Display/DisplayRichText.vue';
import InfiniteLoading from 'v3-infinite-loading';
import { safeHtmlStringify } from '@/util/safe-html-stringify';
import VButton, { type ButtonProps } from '@/components/Inputs/VButton.vue';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import { getKey } from '@/util/globals';

type Props = {
  url: string;
  title: string;
  queryableParameters: any;
  allowedFields?: any[];
  canFilterModels?: boolean;
  canFilterAuditEvents?: boolean;
  withButton?: boolean;
  buttonText?: string;
};

const props = defineProps<
  {
    url: string;
    title: string;
    queryableParameters: any;
    allowedFields?: any[];
    canFilterModels?: boolean;
    canFilterAuditEvents?: boolean;
    withButton?: boolean;
    buttonText?: string;
  } & ButtonProps
>();

const emit = defineEmits<{
  (event: 'closed', ...args: any[]): void;
  (event: 'restored', ...args: any[]): void;
}>();

const toast = useToast();

const loading = ref(false);
const isOpen = ref(false);
const modalOpen = ref(false);
const loadingSelectedAudit = ref(false);
const selectedAuditModalOpen = ref(false);
const audits = ref([]);
const activeFields = ref([]);
const page = ref(1);
const totalPages = ref(1);
const totalAudits = ref(null);
const selectedMinimalAudit = ref(null);
const selectedAudit = ref(null);
const auditableTypes = ref(props.queryableParameters);
const target = ref(null);

const modelsWithRestore = [
  'App\\Assignment',
  'App\\Assignable',
  'App\\Notepad',
  'App\\Contact',
  'App\\EventResource',
  'App\\EventGroup',
];

const auditEventTypes = ref([
  { name: 'Created', id: 'created' },
  { name: 'Updated', id: 'updated' },
  { name: 'Deleted', id: 'deleted' },
]);

const allAuditEventTypes = [
  { name: 'Created', id: 'created' },
  { name: 'Updated', id: 'updated' },
  { name: 'Deleted', id: 'deleted' },
];

const timeoutFunction = ref(null);
const fetching = ref(false);

const displayableAudits = computed(() => {
  return audits.value.filter((audit) => audit.title);
});

const loadAudits = async (state) => {
  if (loading.value || page.value > totalPages.value) return;

  fetching.value = true;
  loading.value = true;

  try {
    const { data } = await axios.get(props.url, {
      params: {
        page: page.value,
        auditable_types: auditableTypes.value.flatMap((type) => {
          if (type.hasOwnProperty('param')) {
            if (Array.isArray(type.param)) {
              return type.param.map((param) => param);
            }
            return type.param;
          }
          return type.id;
        }),
        audit_events: props.canFilterAuditEvents ? auditEventTypes.value.map((type) => type.id) : null,
      },
    });

    if (data.meta.hasOwnProperty('total')) {
      totalAudits.value = data.meta.total;
    }
    if (data.meta.hasOwnProperty('last_page')) {
      totalPages.value = data.meta.last_page;
    } else if (data.data.length > 0) {
      totalPages.value = page.value + 1;
    }
    if (data.data.length) {
      audits.value = audits.value.concat(data.data);
    }

    page.value = data.meta.current_page + 1;

    if (state && page.value >= totalPages.value) {
      state.complete();
    } else if (state) {
      state.loaded();
    }

    loading.value = false;
    fetching.value = false;

    // await nextTick();
    // if (displayableAudits.value.length < 5) {
    //   if (page.value < totalPages.value) {
    //     page.value++;
    //     await loadAudits();
    //   }
    // }
  } catch (error) {
    console.error(error);
    if (state) {
      state.error();
    }
    throw error;
  }
};

const resetAndLoad = (delay = 1000) => {
  if (auditableTypes.value.length === 0) {
    audits.value = [];
    loading.value = false;
    fetching.value = false;
    return;
  }
  audits.value = [];
  page.value = 1;
  clearTimeout(timeoutFunction.value);
  timeoutFunction.value = null;
  fetching.value = true;
  timeoutFunction.value = setTimeout(() => {
    clearTimeout(timeoutFunction.value);
    loadAudits();
  }, delay);
};

const showSidebar = () => {
  isOpen.value = false;
  nextTick(() => {
    isOpen.value = true;
  });
  resetAndLoad(0);
};

const fetchAudit = () => {
  loadingSelectedAudit.value = true;
  axios
    .get(`/api/audits/show/${selectedMinimalAudit.value.id}`)
    .then((resp) => {
      selectedAudit.value = resp.data;
      loadingSelectedAudit.value = false;
      const possibleFields = Object.keys(resp.data.old_values).concat(Object.keys(resp.data.new_values));
      const index = _.findIndex(props.allowedFields, (field) => field.model === resp.data.auditable_type);
      if (index > -1) {
        activeFields.value = getKey(props.allowedFields[index], 'fields', []).filter((field) =>
          possibleFields.includes(field.id)
        );
      }
    })
    .catch((error) => {
      closeModal();
      toast.warning('Something went wrong.');
      console.error(error);
    });
};

const doTheActualRestoringOfAudit = () => {
  if (!modelsWithRestore.includes(selectedAudit.value.auditable_type)) {
    return;
  }
  loadingSelectedAudit.value = true;
  axios
    .post(`/api/audits/show/${selectedAudit.value.id}`)
    .then(() => {
      toast.success('Restored.');
      resetAndLoad();
      switch (selectedAudit.value.auditable_type) {
        case 'App\\JobTitleOnEvent':
        case 'App\\EventGroup':
          // this.$root.$emit('groupStructureUpdated');
          break;
        case 'App\\Documentrequest':
          // this.$root.$emit('refetchDocumentsAndRequests');
          break;
        case 'App\\DocumentFolder':
        case 'App\\Document':
          // this.$root.$emit('documentFolderStructureChanged');
          break;
        case 'App\\EventResource':
          // this.$root.$emit('refetchAllEventResources');
          // this.$root.$emit('eventResourcesUpdated');
          break;
        case 'App\\Assignment':
        case 'App\\Assignable':
        default:
          emit('restored');
          break;
      }
      closeModal();
    })
    .catch((error) => {
      closeModal();
      toast.warning('Something went wrong.');
      console.error(error);
    });
};

const restoreSelectedAudit = () => {
  if (!modelsWithRestore.includes(selectedAudit.value.auditable_type)) {
    return;
  }
  if (selectedAudit.value.event === 'created') {
    // this.$modal.show('dialog', {
    //   title: 'Delete item',
    //   class: 'advanced-buttons accept-decline-model',
    //   text: 'Are you sure you want to delete this?',
    //   buttons: [
    //     {
    //       title: "Yes, I'm sure",
    //       handler: () => {
    //         this.doTheActualRestoringOfAudit();
    //         this.$modal.hide('dialog');
    //       },
    //     },
    //     {
    //       title: 'Cancel',
    //     },
    //   ],
    // });
  } else {
    doTheActualRestoringOfAudit();
  }
};
const showModal = () => {
  modalOpen.value = false;
  nextTick(() => {
    modalOpen.value = true;
  });
};
const closeModal = () => {
  modalOpen.value = false;
};
const getFieldFromArray = (values, field) => {
  if (values.hasOwnProperty(field.id) && values[field.id]) {
    if (field.hasOwnProperty('notMissing')) {
      return field.notMissing;
    }
    return values[field.id];
  }
  return field.missing;
};

const openAudit = (audit) => {
  if (!audit.can_open) return;
  selectedMinimalAudit.value = null;
  selectedAudit.value = null;
  activeFields.value = [];
  selectedAuditModalOpen.value = false;
  showModal();
  nextTick(() => {
    selectedMinimalAudit.value = audit;
    fetchAudit();
  });
};

watch(auditableTypes, () => {
  resetAndLoad(0);
});

watch(auditEventTypes, () => {
  resetAndLoad(0);
});

onMounted(() => {
  if (!props.withButton) {
    setTimeout(() => {
      showSidebar();
    }, 100);
  }
});
// useInfiniteScroll(
//   target,
//   async () => {
//     // load more
//     if (page.value < totalPages.value && !loading.value) {
//       page.value += 1;
//       await loadAudits();
//     }
//   },
//   {
//     distance: 10,
//     canLoadMore(el: HTMLDivElement) {
//       return true;
//     },
//   }
// );

defineSlots<{
  default?: (props: { open: () => void }) => unknown;
}>();

defineOptions({
  inheritAttrs: false,
});

const activeProps = computed(() => {
  return {
    ...props,
    title: props.buttonText,
    toolTipText: props.title,
    icon: 'fa-history',
  };
});
</script>

<template>
  <VButton
    v-if="withButton"
    v-bind="activeProps"
    @click="showSidebar" />

  <BaseSlideout
    v-model="isOpen"
    small
    within-same
    :base-z-index="1000"
    @closed="$emit('closed')">
    <template #header>
      <div class="relative flex flex-col border-b p-edge">
        <div class="flex gap-edge-1/2">
          <IconWithLoading
            icon="fa-history"
            classes="text-3xl"
            :loading="loading" />
          <h1>{{ totalAudits }} Records</h1>
        </div>
        <div class="sub-title text-soft">{{ title }}</div>
      </div>
    </template>
    <template #default>
      <div
        ref="target"
        class="h-full space-y-edge overflow-auto bg-content-main p-edge">
        <BoxContainer
          v-if="canFilterModels || canFilterAuditEvents"
          class="space-y-edge">
          <VMultiselect
            v-if="canFilterModels"
            v-model="auditableTypes"
            :close-on-select="false"
            placeholder="Select One or more models"
            :options="queryableParameters"
            object
            with-add-all
            label="Auditable types" />

          <VMultiselect
            v-if="canFilterAuditEvents"
            v-model="auditEventTypes"
            :close-on-select="false"
            placeholder="Select One or more types of audits"
            :options="allAuditEventTypes"
            object
            label="Audit Events" />

          <div
            v-if="!audits.length && !loading"
            class="mt-10">
            <h2 class="text-center">No audits found</h2>
          </div>
        </BoxContainer>
        <BoxContainer
          v-if="!audits.length && !loading"
          class="space-y-edge">
          <div class="mt-10">
            <h2 class="text-center">No audits found</h2>
          </div>
        </BoxContainer>
        <div
          v-if="audits.length"
          class="mb-edge space-y-edge">
          <AuditsSidebarItem
            v-for="audit in displayableAudits"
            :key="audit.id"
            :audit="audit"
            :allowed-fields="allowedFields"
            @click="openAudit(audit)" />
          <InfiniteLoading
            slots="complete"
            @infinite="loadAudits">
            <template #complete>
              <div class="p-edge text-center italic">All loaded</div>
            </template>
          </InfiniteLoading>
        </div>
      </div>
    </template>
  </BaseSlideout>

  <CrudModal
    v-if="modalOpen"
    :loading="loadingSelectedAudit"
    title="Audit"
    large
    :only-close-button="true"
    @create="restoreSelectedAudit"
    @closed="closeModal">
    <div
      v-if="!loadingSelectedAudit && selectedAudit"
      class="main-content">
      <div class="sub-title mb-edge px-edge">
        {{ selectedMinimalAudit.user ? selectedMinimalAudit.user.name : 'Unknown user' }}
        {{ selectedMinimalAudit.title }}
      </div>
      <div v-if="activeFields.length > 0">
        <VTable edge-to-edge>
          <template #head>
            <VTableRow head>
              <VTableCell style="max-width: 200px">Attribute</VTableCell>
              <VTableCell v-if="['updated', 'deleted'].includes(selectedAudit.event)"> Old</VTableCell>
              <VTableCell v-if="['created', 'updated'].includes(selectedAudit.event)"> New</VTableCell>
            </VTableRow>
          </template>
          <template #default>
            <VTableRow
              v-for="field in activeFields"
              :key="field.id"
              main-row>
              <VTableCell main-cell>{{ field.name }}</VTableCell>
              <VTableCell v-if="['updated', 'deleted'].includes(selectedAudit.event)">
                <template v-if="[field.type, selectedAudit.display_options].includes('field-rich-text')">
                  <div v-html="safeHtmlStringify(getFieldFromArray(selectedAudit.old_values, field))" />
                </template>
                <template v-else-if="[field.type, selectedAudit.display_options].includes('field-text')">
                  <span
                    v-for="(t, idx) in getFieldFromArray(selectedAudit.old_values, field).split('\n')"
                    :key="idx">
                    <DisplayRichText :content="t"></DisplayRichText>
                    <br />
                  </span>
                </template>
                <template v-else>
                  {{ getFieldFromArray(selectedAudit.old_values, field) }}
                </template>
              </VTableCell>
              <VTableCell v-if="['created', 'updated'].includes(selectedAudit.event)">
                <template v-if="[field.type, selectedAudit.display_options].includes('field-rich-text')">
                  <div v-html="safeHtmlStringify(getFieldFromArray(selectedAudit.new_values, field))" />
                </template>
                <template v-else-if="[field.type, selectedAudit.display_options].includes('field-text')">
                  <span
                    v-for="(t, idx) in getFieldFromArray(selectedAudit.new_values, field).split('\n')"
                    :key="idx">
                    <DisplayRichText :content="t"></DisplayRichText>
                    <br />
                  </span>
                </template>
                <template v-else>
                  {{ getFieldFromArray(selectedAudit.new_values, field) }}
                </template>
              </VTableCell>
            </VTableRow>
          </template>
        </VTable>
      </div>

      <div class="mt-edge px-edge">
        <div class="text-xs text-soft">
          <i class="fa fa-clock mr-edge-1/4" />
          <span class="mr-edge-1/4 italic">when</span>
          <i class="fa fa-minus fa-sm mr-1" />
          <span class="text-sm italic">{{ selectedAudit.created_at }}</span>
        </div>
      </div>
    </div>
  </CrudModal>
</template>
