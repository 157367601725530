<script lang="ts" setup>
import IntermediateStepRoomCalendarEventHover from '@/components/calendar/event-hover-items/IntermediateStepRoomCalendarEventHover.vue';
import VCalendar from '@/components/calendar/VCalendar.vue';
import VButton from '@/components/Inputs/VButton.vue';
import IntermediateStepRoomBookingList from '@/components/IntermediateStep/Tabs/Members/IntermediateStepRoomBookingList.vue';
import RoomBookingModal from '@/components/Rooms/RoomBookingModal.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { useRecurringModal } from '@/composables/modals/use-recurring-modal';
import { useSmallScreen } from '@/composables/use-small-screen';
import { getInviteRoomBookings } from '@/services/api-invite';
import { patchRoomBooking, postRoomBooking } from '@/services/api-room-booking';
import { useEmitStore } from '@/store/EmitStore';
import { InviteResource } from '@/types/invite';
import { RoomBookingResource } from '@/types/room-booking';
import { toHumanTimeFormat } from '@/util/date';
import { CalendarApi, CalendarOptions } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { ResourceApi } from '@fullcalendar/resource';
import FullCalendar from '@fullcalendar/vue3';
import moment from 'moment';
import { onActivated, onMounted, reactive, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import { getKey } from '@/util/globals';

type Props = {
  invite?: InviteResource;
  canEdit: boolean;
  venueId?: number | null;
  groupId: number;
  eventId?: number;
  eventStart: string;
  isPastEvent?: boolean;
};

const props = defineProps<Props>();

const { isSmallScreen } = useSmallScreen();
const toast = useToast();
const { rootEmit } = useEmitStore();
const { assertCertain } = useCertaintyModal();
const { recurringModal } = useRecurringModal();

const calendar = ref<{ api: CalendarApi; calendar: typeof FullCalendar } | null>(null);

const allRooms = ref([]);
const roomBookings = ref<RoomBookingResource[]>([]);

const views = ['1_day', '3_days'] as const;
const initialView = ref(views[1]);

const calendarData = reactive({
  initialView: isSmallScreen.value ? 'smallScreen' : initialView.value,
  resourceAreaHeaderContent: ' ',
  resourceAreaWidth: '30%',
  initialDate: moment(props.eventStart).subtract(1, 'day').format('YYYY-MM-DD'),
  allDaySlot: false,
  nextDayThreshold: '06:00',
  resourcesInitiallyExpanded: true,
  selectable: false,
  editable: false,
  firstDay: 1,
  weekNumbers: true,
  fixedWeekCount: false,
  displayEventTime: false,
  slotEventOverlap: false,
  resourceOrder: 'order, id, title',
  views: {
    '1_day': {
      buttonText: '1 day',
      type: 'resourceTimeline',
      viewClassNames: 'resource-timeline-day',
      duration: { days: 1 },
      dateIncrement: { days: 1 },
      slotDuration: '24:00:00',
      slotLabelFormat: [
        {
          month: 'long',
          day: 'numeric',
          weekday: 'long',
        },
      ],
    },
    '3_days': {
      buttonText: '3 days',
      type: 'resourceTimeline',
      viewClassNames: 'resource-timeline-day',
      duration: { days: 3 },
      slotDuration: { days: 1 },
      slotLabelFormat: [
        {
          weekday: 'short',
          day: 'numeric',
        },
      ],
    },
    smallScreen: {
      type: 'resourceTimelineDay',
      slotDuration: { days: 1 },
      slotLabelInterval: { days: 1 },
      viewClassNames: 'resource-timeline-day',
      resourceAreaWidth: '50%',
      buttonText: 'Day',
      slotLabelFormat: [
        {
          month: 'long',
          day: 'numeric',
          weekday: 'long',
        },
      ],
    },
  },
  async resources(info, success, error) {
    let url = `/api/venues/${props.venueId}/resources`;
    if (props.invite?.festival) {
      url = `/api/festivals/${props.invite.festival.id}/scheduler/venues`;
    }
    const params = {
      event_id: props.eventId,
      dashboard: false,
    };
    try {
      const { data } = await axios.get(url, { params });
      allRooms.value = data;

      if (props.invite) {
        const { data: rb } = await getInviteRoomBookings(props.invite.id);
        roomBookings.value = rb;

        rb.forEach((booking) => {
          const room = data.find((r) => r.model_id === booking.room_id);
          if (room) {
            room.booked = true;
          }
        });
      }

      success(data);
    } catch (e) {
      error(e);
    }
  },
  async events(info, success, error) {
    try {
      const start = moment(info.start.valueOf()).format('YYYY-MM-DD');
      const end = moment(info.end.valueOf()).format('YYYY-MM-DD');
      let url = `/api/venues/${props.venueId}/assignments`;
      if (props.invite?.festival) {
        url = `/api/festivals/${props.invite.festival.id}/calendars/venues`;
      }
      const params = {
        start,
        end,
        group_id: props.groupId,
        with_festivals: true,
        with_shifts: true,
      };
      const { data: events } = await axios.get(url, { params });
      events.forEach((event) => {
        event.textColor = 'black';
      });
      success(events);
    } catch (e) {
      error(e);
    }
  },
}) as CalendarOptions;

const showRoomBookingModal = ref(false);
const bookingStart = ref(props.invite?.start);
const bookingEnd = ref(props.invite?.end);
const selectedRoom = ref<{
  id: number;
  name: string;
  title: string | null;
} | null>(null);

const fetchAll = () => {
  calendar.value?.api.refetchResources();
  calendar.value?.api.refetchEvents();
};

const selectedBooking = ref<RoomBookingResource | null>(null);

const openRoomBookingModal = (booking: RoomBookingResource) => {
  selectedBooking.value = booking;
  selectedRoom.value = {
    id: booking.room_id,
    name: booking.room_name,
    title: booking.room_title,
  };
  showRoomBookingModal.value = true;
};

const addRookRoom = async (resource: ResourceApi) => {
  selectedBooking.value = null;
  selectedRoom.value = {
    id: resource.extendedProps.model_id,
    name: resource.title,
    title: resource.extendedProps.room_title,
  };
  showRoomBookingModal.value = true;
};

onActivated(() => {
  if (calendar.value?.api) {
    calendar.value.api.render();
  }
});

const bookRoom = async (booking: RoomBookingResource, close: () => void) => {
  if (!props.invite) return;

  if (props.isPastEvent) {
    const res = await useCertaintyModal().assertCertain(
      'Book room to past event',
      'This is a past event. Are you sure you want to book this room?'
    );
    if (!res) return;
  }

  await postRoomBooking(props.invite.id, booking);
  toast.success(`${booking.room_name} has been booked to ${props.invite.event?.name}`);
  calendar.value?.api.refetchEvents();
  calendar.value?.api.refetchResources();
  rootEmit('room-added-to-event');
  close();
};

const deleteBooking = async (booking: RoomBookingResource, close: () => void) => {
  const deleteIt = await assertCertain(
    `Remove ${booking.room_name} From Event`,
    `Are you sure you want to do this? All assignments on ${booking.room_name} will be lost.`
  );
  if (!deleteIt) return;

  let removeFromAll = 'justOne';
  if (selectedBooking.value.recurring_original_id !== null) {
    removeFromAll = await recurringModal(
      null,
      'Remove Room From All Recurrences',
      'Would you like to remove this booking on all recurrences of this event, or just this one?'
    );
    if (removeFromAll === 'cancel') return;
  }

  await axios.delete(`/api/room-booking/${booking.id}`, {
    params: {
      is_global: removeFromAll === 'all',
    },
  });

  toast.success('Room has been removed');
  fetchAll();
  rootEmit('room-removed-from-event');
  close();
};

const updateRoomBooking = async (
  booking: { start: string; end: string; confirmed: boolean; title: string | null },
  close: () => void
) => {
  if (!selectedBooking.value?.id) return;

  const addToAll = selectedBooking.value.recurring_original_id
    ? await recurringModal(
        '',
        'Update Recurring Room Booking',
        'Would you like to update this booking on all recurrences of this event, or just this one?'
      )
    : false;

  if (addToAll === 'cancel') return;

  await patchRoomBooking(selectedBooking.value.id, {
    start: booking.start,
    end: booking.end,
    confirmed: booking.confirmed,
    title: booking.title,
    is_global: addToAll === 'all',
  });
  toast.success('Room booking has been updated');
  fetchAll();
  rootEmit('room-update-on-event');
  close();
};

const listenForBroadcast = () => {
  Echo.join(`On.Group.${props.groupId}`)
    .listen('.venueInvite.changed', () => {
      calendar.value?.api.refetchEvents();
    })
    .listen('.roomBooking.changed', () => {
      calendar.value?.api.refetchResources();
      calendar.value?.api.refetchEvents();
    })
    .listen('.invites.changed', (e) => {
      calendar.value?.api.refetchEvents();
    });
};

onMounted(() => {
  listenForBroadcast();
  setTimeout(() => {
    calendar.value?.api.render();
  }, 300);
  setTimeout(() => {
    calendar.value?.api.render();
  }, 500);
});

const getAllEvents = () => {
  return calendar.value?.api.getEvents();
};

const getBackgroundColor = (resource: ResourceApi) => {
  const allEvent = resource.getEvents()?.filter((e) => e.extendedProps.event_id === props.eventId);
  if (resource.extendedProps.model === 'venue') {
    const children = resource.getChildren();
    children.forEach((c) => {
      const childEvents = c.getEvents()?.filter((e) => e.extendedProps.event_id === props.eventId);
      if (childEvents?.length) {
        allEvent.push(...childEvents);
      }
    });
  }
  if (allEvent?.length) {
    return 'border-l-success';
  }

  return 'border-l-transparent';
};

const getTextColor = (event: EventImpl) => {
  if (!getKey(event.extendedProps, 'confirmed', getKey(event.extendedProps, 'booking_confirmed', true))) {
    return '!text-[hsl(var(--color-text))]';
  }
  return 'text-[hsl(var(--gray-950))]';
};

const showCal = ref(true);

watch(isSmallScreen, () => {
  showCal.value = false;
  setTimeout(() => {
    showCal.value = true;
  }, 100);
});

watch(initialView, (v) => {
  if (v === '1_day') {
    calendar.value?.api.gotoDate(moment(props.eventStart).format('YYYY-MM-DD'));
  } else {
    calendar.value?.api.gotoDate(moment(props.eventStart).subtract(1, 'day').format('YYYY-MM-DD'));
  }
});
</script>

<template>
  <div class="flex h-full flex-col gap-edge overflow-auto p-edge md:border-t md:border-none">
    <IntermediateStepRoomBookingList
      v-if="invite && canEdit"
      :all-resources="allRooms"
      :can-edit="canEdit"
      :invite="invite"
      :is-past="false"
      :is-recurring="invite?.recurring_original_id !== null"
      :room-bookings="roomBookings"
      @fetch="fetchAll()"
      @selected="openRoomBookingModal" />
    <BoxContainer :content-padding="false">
      <VCalendar
        v-if="showCal"
        ref="calendar"
        :has-event-hover="!isSmallScreen"
        :has-event-menu="false"
        :options="calendarData"
        :show-title="!isSmallScreen"
        :show-view-selector="!isSmallScreen"
        :view="isSmallScreen ? 'smallScreen' : initialView"
        :views="views"
        :with-click-date="false"
        :with-click-event="false"
        class="intermediate-step-sticky [&>fc-scrollgrid-section-sticky>*]:bg-green [&>div>div:first-child]:sticky [&>div>div:first-child]:top-0 [&>div>div:first-child]:z-[5000] [&>div>div:first-child]:bg [&>div>div]:overflow-visible [&>div]:overflow-visible [&_.header-of-calendar]:rounded [&_.header-of-calendar]:px-edge"
        @update:view="initialView = $event">
        <template #resourceLabelContent="{ resource }">
          <div
            :class="getBackgroundColor(resource)"
            class="relative flex h-full flex-col justify-center border-l-[8px] pr-edge-1/4">
            <div class="flex items-center justify-between pl-edge-1/2">
              <div class="truncate">
                {{ resource.title }}
              </div>
              <VButton
                v-if="canEdit && invite?.write && resource.extendedProps.model === 'room'"
                :tool-tip-text="'Add ' + resource.title + ' room to event'"
                icon="fa-plus"
                size="sm"
                @click="addRookRoom(resource)">
              </VButton>
            </div>
          </div>
        </template>

        <template #event="{ event }">
          <div
            :class="[{ 'unconfirmed rounded': event.extendedProps.confirmed === false }, getTextColor(event)]"
            class="h-[20px] w-full">
            <div class="truncate px-edge-1/4 py-1 text-sm">
              {{ event.startStr ? toHumanTimeFormat(event.startStr) + ' - ' : '' }}
              {{ event.title }}
            </div>
          </div>
        </template>

        <template #event-hover="{ event }">
          <IntermediateStepRoomCalendarEventHover
            :all-events="getAllEvents()"
            :event="event" />
        </template>
      </VCalendar>
    </BoxContainer>
    <RoomBookingModal
      v-if="showRoomBookingModal"
      :end="bookingEnd"
      :initial-booking="selectedBooking"
      :is-recurring="invite?.recurring_original_id !== null"
      :room="selectedRoom"
      :start="bookingStart"
      @closed="showRoomBookingModal = false"
      @patch-booking="updateRoomBooking($event.booking, $event.close)"
      @remove-booking="deleteBooking($event.booking, $event.close)"
      @book-room="bookRoom($event.booking, $event.close)" />
  </div>
</template>
