<script setup lang="ts">
import VTableCell from '@/components/Tables/VTableCell.vue';
import TextCell from '@/components/Config/ColumnConfig/Cells/TextCell.vue';
import { defaultColumnWidths } from '@/variables/mysc';
import { ref } from 'vue';
import { WhisperObject } from '@/util/whisper-functions';
import VButton from '@/components/Inputs/VButton.vue';
import { getKey } from '@/util/globals';
import DateCell from '@/components/Config/ColumnConfig/Cells/DateCell.vue';
import TimeCell from '@/components/Config/ColumnConfig/Cells/TimeCell.vue';
import DocumentCell from '@/components/Config/ColumnConfig/Cells/DocumentCell.vue';
import ListCell from '@/components/Config/ColumnConfig/Cells/ListCell.vue';
import UserCell from '@/components/Config/ColumnConfig/Cells/UserCell.vue';
import ToggleCell from '@/components/Config/ColumnConfig/Cells/ToggleCell.vue';
import ImageCell from '@/components/Config/ColumnConfig/Cells/ImageCell.vue';

type Props = {
  modelValue?: string | boolean | number | null;
  columnDefinition: any;
  canEdit: boolean;
  mainCell?: boolean;
  isTemplate?: boolean | null;
  indexKey: number;
  componentId?: string | null;
  isShowMode?: boolean;
  withAuditsButton?: boolean;
  whisper?: WhisperObject | null;
  dataListOptions?: [] | null;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  mainCell: false,
  indexKey: 0,
  componentId: null,
  isShowMode: false,
  withAuditsButton: false,
  isTemplate: false,
  whisper: null,
  dataListOptions: () => [],
});

const emit = defineEmits<{
  (event: 'update:modelValue', arg: any): void;
  (event: 'keydown-enter'): void;
  (event: 'open-audits'): void;
}>();

const isEditing = ref(false);

const getWidthOfColumn = () => {
  const width = defaultColumnWidths.find((i) => props.columnDefinition.width === i.class);
  if (!width) return 100;
  return Number(width.size) * 100;
};

const getComponentName = () => {
  switch (props.columnDefinition.component) {
    case 'board-column-date': {
      return DateCell;
    }
    case 'board-column-time': {
      return TimeCell;
    }
    case 'board-column-document':
    case 'runningorder-column-document': {
      return DocumentCell;
    }
    case 'board-column-list': {
      return ListCell;
    }
    case 'board-column-toggle': {
      return ToggleCell;
    }
    case 'board-column-user': {
      return UserCell;
    }
    case 'runningorder-column-image': {
      return ImageCell;
    }
    case 'board-column-text':
    case 'runningorder-column-text':
    default: {
      return TextCell;
    }
  }
};

const formattedModelValue = () => {
  switch (props.columnDefinition.component) {
    case 'board-column-text': {
      if ([null, 'null', '', undefined, false].includes(props.modelValue)) {
        return null;
      }
      break;
    }
    case 'board-column-time': {
      if ([null, 'null', '', undefined, false, 'false'].includes(props.modelValue)) {
        return null;
      }
      break;
    }
    case 'board-column-toggle': {
      if (props.isTemplate) return null;
      return [1, '1', 'true', true].includes(props.modelValue);
    }
    case 'board-column-document':
    case 'board-column-user': {
      if (props.modelValue) return Number(props.modelValue);
      return null;
    }
    default: {
      break;
    }
  }
  return props.modelValue;
};
const getProps = () => {
  const defaultProps = {
    canEdit: props.isShowMode
      ? props.canEdit && props.columnDefinition.component === 'runningorder-column-text'
      : props.canEdit,
    'model-value': formattedModelValue(),
    'is-show-mode': props.isShowMode,
    'id': props.componentId,
  };

  switch (props.columnDefinition.component) {
    case 'board-column-text':
    case 'runningorder-column-text': {
      return {
        ...defaultProps,
        'tabindex': 0,
        'whisper': props.whisper,
        'dataListOptions': props.dataListOptions,
      };
    }
    case 'board-column-list': {
      return {
        ...defaultProps,
        'options': getKey(props.columnDefinition, 'options', []),
      };
    }
    case 'board-column-time': {
      return {
        ...defaultProps,
        'leftIcon': 'fa-clock',
      };
    }
    case 'board-column-date': {
      return {
        ...defaultProps,
        'placeholder': 'THIIIS',
      };
    }
    default: {
      return defaultProps;
    }
  }
};
const getIconOfColumn = () => {
  if (!props.isTemplate) return null;
  switch (props.columnDefinition.component) {
    case 'board-column-text':
    case 'runningorder-column-text': {
      return null;
    }
    case 'board-column-toggle': {
      return 'fa-toggle-off';
    }
    case 'board-column-document': {
      return 'fa-file';
    }
    case 'board-column-user': {
      return 'fa-user';
    }
    case 'board-column-time': {
      return 'fa-clock';
    }
    case 'board-column-list': {
      return 'fa-list';
    }
    case 'board-column-date': {
      return 'fa-calendar';
    }
    default: {
      return null;
    }
  }
};
</script>

<template>
  <VTableCell
    :main-cell="mainCell || isEditing"
    :has-input="true"
    classes="relative group/button border-r !pl-[1px] !pt-[1px] !p-0 [&>*]:!p-0">
    <div>
      <component
        :is="getComponentName()"
        class="p-0"
        v-bind="getProps()"
        :style="'min-height: 40px; width: ' + getWidthOfColumn() + 'px!important;'"
        @update:model-value="[$emit('update:modelValue', $event)]">
        <template #emptyState>
          <div class="my-edge-1/2 text-center">
            <i
              class="fa fa-fw"
              :class="getIconOfColumn()"></i>
          </div>
        </template>
      </component>
      <div
        v-if="withAuditsButton"
        class="absolute right-0 top-0">
        <VButton
          tool-tip-text="Open Audits"
          class="btn-outline-text hidden group-hover/button:block"
          icon="fa-history"
          size="xs"
          @click="emit('open-audits')" />
      </div>
    </div>
  </VTableCell>
</template>
