<script lang="ts" setup>
import { ref, onMounted, useTemplateRef } from 'vue';
import MobileMenu from '@/components/Navbar/MobileMenu.vue';
import { router } from '@inertiajs/vue3';
import EmailVerificationBanner from '@/components/Navbar/EmailVerificationBanner.vue';

const currentPlace = ref('Personal');

const currentPage = ref('group');

router.on('navigate', (e) => {
  if (e.detail.page.component === 'Event/EventShow') {
    currentPlace.value = e.detail.page.props.event.name;
    currentPage.value = 'event';
  }
  if (e.detail.page.component === 'Group/GroupShow') {
    currentPlace.value = e.detail.page.props.group.name;
    currentPage.value = 'group';
  }

  if (e.detail.page.component === 'Performance/PerformanceShow') {
    currentPlace.value = e.detail.page.props.performance.name;
    currentPage.value = 'performance';
  }

  if (e.detail.page.component === 'Festival/FestivalSectionShow') {
    currentPlace.value = e.detail.page.props.festivalSection.name;
    currentPage.value = 'festival_section';
  }

  if (e.detail.page.component === 'PublicForm/PublicFormShow') {
    currentPlace.value = e.detail.page.props.publicForm.name;
    currentPage.value = 'public_form';
  }

  if (e.detail.page.component === 'Festival/FestivalShow') {
    currentPlace.value = e.detail.page.props.festival.name;
    currentPage.value = 'festival_show';
  }
});

const header = useTemplateRef('header');

const resizeObserver = new ResizeObserver((entries) => {
  const root = document.querySelector(':root');
  if (root) {
    root.style?.setProperty('--navbar-current-height', `${entries[0].target.clientHeight}px`);
  }
});

onMounted(() => {
  resizeObserver.observe(header.value);
});
</script>

<template>
  <header ref="header">
    <EmailVerificationBanner v-if="$page.props?.auth?.user?.verified_at === null" />

    <nav class="relative flex items-center justify-between gap-edge-1/4 border-b px-edge py-edge">
      <h2 class="truncate text-center capitalize">{{ currentPlace }}</h2>
      <Suspense>
        <MobileMenu
          v-model:page="currentPage"
          v-model:place="currentPlace" />
        <template #fallback>
          <div>
            <i class="fa fa-fw fa-spinner fa-spin" />
          </div>
        </template>
      </Suspense>
    </nav>
  </header>
</template>
