<script setup lang="ts">
import { onMounted, ref } from 'vue';

type Props = {
  statusBar: { number: number; name: string; percent: number }[];
  status: number | null;
  canEdit?: boolean | null;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:status', arg: number): void;
  (event: 'updateEventType', arg: { key: string; value: number | string }): void;
  (event: 'attributeUpdated', arg: { key: string; value: number | string }): void;
}>();

const pxOfEachElement = ref(100);
const wrapper = ref<HTMLDivElement | null>(null);

onMounted(() => {
  setTimeout(() => {
    if (wrapper.value) {
      pxOfEachElement.value = (wrapper.value.offsetWidth - 160) / props.statusBar.length;
      if (pxOfEachElement.value > 120) {
        pxOfEachElement.value = 120;
      }
    }
  }, 100);
});
</script>

<template>
  <div
    ref="wrapper"
    class="flex min-w-full items-center justify-between pb-edge">
    <template
      v-for="step in statusBar"
      :key="step.number">
      <div
        class="relative flex h-[30px] min-w-[100px] flex-1 flex-col items-center"
        :title="step.name"
        @click="emit('update:status', step.number)">
        <div
          class="flex aspect-1 h-[24px] w-[24px] items-center justify-center rounded-full border-2 bg text-xs transition-all"
          :class="{
            'cursor-pointer hover:border-highlight': canEdit,
            ' ': step.number === status,
            'border-highlight bg-highlight text-inverted': step.number <= status,
          }">
          {{ step.number }}
        </div>
        <div
          :style="'max-width: ' + pxOfEachElement + 'px'"
          class="absolute mt-[25px] truncate text-center text-sm"
          :class="{ 'text-soft': step.number < status }">
          {{ step.name }}
        </div>
      </div>
      <div
        v-if="step.number < statusBar.length"
        :class="{ 'border-highlight': step.number < status }"
        class="-mx-[50px] -mt-[7px] h-1 w-full border transition-all" />
    </template>
  </div>
</template>
