import { useEventRequestTemplateStore } from '@/store/EventRequestTemplateStore';
import type { EventRequestTemplateResource } from '@/types/event-request';
import { computed, provide, readonly } from 'vue';
import { storeToRefs } from 'pinia';
import { formatModel } from '@/util/store-helpers';
import type { EventRequestTemplatesComposable } from '@/provide/keys';
import { eventRequestTemplatesKey } from '@/provide/keys';
import type { ModelType } from '@/types/global';

export const useEventRequestTemplates = (
  model: ModelType,
  modelId: number,
  shouldProvide = true,
  hasEventRequests = false
): EventRequestTemplatesComposable => {
  const { list, loading } = storeToRefs(useEventRequestTemplateStore());
  const { fetchEventRequestTemplates } = useEventRequestTemplateStore();

  const eventRequestTemplates = computed(() => list.value.get(formatModel(model, modelId)) || []);

  const fetch = async (force = false) => {
    if (!hasEventRequests) return;
    if (force || !list.value.has(formatModel(model, modelId))) {
      await fetchEventRequestTemplates(model, modelId);
    }
  };

  const addItem = (eventRequestTemplate: EventRequestTemplateResource) => {
    if (!hasEventRequests) return;
    const key = formatModel(model, modelId);
    const current = list.value.get(key) || [];
    list.value.set(key, [...current, eventRequestTemplate]);
  };

  const removeItem = (eventRequestTemplatesId: number) => {
    if (!hasEventRequests) return;
    const key = formatModel(model, modelId);
    const current = list.value.get(key) || [];
    list.value.set(
      key,
      current.filter((item) => item.id !== eventRequestTemplatesId)
    );
  };

  const updateItem = (eventRequestTemplate: EventRequestTemplateResource) => {
    if (!hasEventRequests) return;
    const key = formatModel(model, modelId);
    const current = list.value.get(key) || [];
    list.value.set(
      key,
      current.map((item) => (item.id === eventRequestTemplate.id ? eventRequestTemplate : item))
    );
  };

  if (shouldProvide) {
    provide(eventRequestTemplatesKey, {
      eventRequestTemplates: readonly(eventRequestTemplates),
      fetch,
      addItem,
      removeItem,
      updateItem,
      loading: readonly(loading),
    });
  }

  return {
    eventRequestTemplates,
    loading,
    fetch,
    addItem,
    removeItem,
    updateItem,
  };
};
