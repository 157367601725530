<script setup lang="ts">
import { BoardCellResource, BoardColumnResource, BoardModelType, BoardResource, BoardRowResource } from '@/types/board';
import { boardColumnTypes, sortBoardRows } from '@/util/board-functions';
import { computed, inject, nextTick, onMounted, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import ColumnHeader from '@/components/Config/ColumnConfig/ColumnHeader.vue';
import ColumnCRUDModal from '@/components/Config/ColumnConfig/ColumnCRUDModal.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import TextCell from '@/components/Config/ColumnConfig/Cells/TextCell.vue';
import BoardDownloadModal from '@/components/Models/Boards/BoardDownloadModal.vue';
import SaveAsTemplateModal from '@/components/Modals/SaveAsTemplateModal.vue';
import ContentContainer from '@/components/Content/ContentContainer.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { usePage } from '@inertiajs/vue3';
import {
  createBoardCell,
  createBoardColumn,
  createBoardRow,
  deleteBoardColumn,
  deleteBoardRow,
  patchBoard,
  reOrderBoardColumns,
  reOrderBoardRows,
  updateBoardCell,
  updateBoardColumn,
  updateBoardRow,
} from '@/services/api-board';
import {
  activateFieldById,
  createUuId,
  exchangeValuesOfObject,
  getIndexFromArrayBasedOnId,
  getItemFromArrayBasedOnId,
  getKey,
  getSortedListOfValues,
  isNumeric,
  removeItemFromArrayBasedOnId,
  reorderArrayByIds,
  scrollIntoViewById,
} from '@/util/globals';
import BoardAuditSidebar from '@/components/Models/Boards/BoardAuditSidebar.vue';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';
import { lastKeyStroke } from '@/util/give-me-last-key-stroke-functions';
import CrudModal from '@/components/Modals/CrudModal.vue';
import { SortEmit } from '@/components/TestTable.vue';
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import VList from '@/components/VList.vue';
import CheckBox from '@/components/Icons/CheckBox.vue';
import ColumnCell from '@/components/Config/ColumnConfig/ColumnCell.vue';
import { eventTypesKey } from '@/provide/keys';
import { useSmallScreen } from '@/composables/use-small-screen';
import SectionConnectedToEventTypeSubHeader from '@/components/Config/EventTypes/SectionConnectedToEventTypeSubHeader.vue';

type Props = {
  board: BoardResource;
  isDisplay?: boolean;
  model: BoardModelType;
  modelId: number;
  newlyCreatedBoardId?: number | null;
  canEdit?: boolean | null;
  isTemplate?: boolean | null;
};

const props = withDefaults(defineProps<Props>(), {
  canEdit: false,
  isDisplay: false,
  isTemplate: false,
  newlyCreatedBoardId: null,
});

const emit = defineEmits<{
  'update:board': [board: { id: number; title: string; row_title: string }];
  'updated': [];
  'edit': [];
  'reFetch': [];
}>();

const toast = useToast();

const { isSmallScreen } = useSmallScreen();

const boardContainer = ref<HTMLDivElement | null>(null);
const orderBy = ref<string>('');
const orderDirection = ref<'desc' | 'asc'>('desc');

const boardRows = ref(getKey(props.board, 'board_rows', []));
const boardColumns = ref(getKey(props.board, 'board_columns', []));
const boardCells = ref(getKey(props.board, 'board_cells', []));

const canDragAndDrop = computed(() => {
  return (orderBy.value === null || orderBy.value.length === 0) && selectedRowIds.value.size === 0;
});

watch(
  () => props.newlyCreatedBoardId,
  (newVal, oldValue) => {
    if (!newVal && oldValue === props.board.id) {
      boardRows.value = props.board.board_rows;
      boardColumns.value = props.board.board_columns;
      boardCells.value = props.board.board_cells;
    }
  }
);

const selectedRowIds = ref<Set<number>>(new Set());
const clearRowIds = () => {
  selectedRowIds.value = new Set();
};
const addAllRowIds = () => {
  selectedRowIds.value = new Set(boardRows.value.map((r) => r.id));
};

const sortedRows = computed(() => {
  const localRows = [...boardRows.value];
  if (!orderBy.value) return localRows;
  return sortBoardRows(localRows, boardColumns.value, boardCells.value, orderBy.value, orderDirection.value !== 'desc');
});

const getRowValue = (rowId: number, columnId: string) => {
  const cell = boardCells.value?.find((cell) => cell.board_row_id === rowId && cell.board_column_id === columnId);
  return cell?.value ?? null;
};

const canEditRow = (boardRow: BoardRowResource) => {
  return isNumeric(boardRow.id);
};

const canEditColumn = (component: string) => {
  if (props.isTemplate) {
    if (editMode.value) {
      return component === 'board-column-text';
    }
    return false;
  }
  return editMode.value;
};

const sortBy = (prop: string | null, dir: string | null) => {
  if (orderBy.value === prop && orderDirection.value === 'desc') {
    orderBy.value = null;
    orderDirection.value = 'asc';
    return;
  }
  orderDirection.value = dir === null ? (orderDirection.value === 'desc' ? 'asc' : 'desc') : dir;
  orderBy.value = prop ?? '';
};

const moveColumnLeft = async (index: number) => {
  if (index === 0) {
    toast.warning('Cannot move the column further to the left.');
    return;
  }
  const item = boardColumns.value[index];
  boardColumns.value.splice(index, 1);
  boardColumns.value.splice(index - 1, 0, item);
  await reOrderBoardColumns(
    props.board.id,
    boardColumns.value.map((r) => r.id)
  );
  toast.success('Column moved left.');
  return;
};
const moveColumnRight = async (index: number) => {
  if (index === boardColumns.value.length - 1) {
    toast.warning('Cannot move the column further to the right.');
    return;
  }

  const item = boardColumns.value[index];
  boardColumns.value.splice(index, 1);
  boardColumns.value.splice(index + 1, 0, item);

  await reOrderBoardColumns(
    props.board.id,
    boardColumns.value.map((r) => r.id)
  );
  toast.success('Column moved right.');
};

const getIdOfCell = (rowId: number | null, columnId: number | null) => {
  if (!rowId && !columnId) return;
  if (!columnId) 'row_' + rowId + '_title';
  return 'row_' + rowId + '_column_' + columnId;
};

const lastKey = lastKeyStroke(boardContainer);
const columnsWithGoToNext = ['board-column-text'];
const savingCell = ref(false);
const assignValue = async (value: string, rowId: number, columnId: number) => {
  if (savingCell.value) return;
  savingCell.value = true;
  const rowIds = selectedRowIds.value.size > 0 ? Array.from(selectedRowIds.value) : [rowId];
  for (let j = 0; j < rowIds.length; j++) {
    await actuallyAssignValue(value, rowIds[j], columnId);
  }

  toast.success('Updated.');
  savingCell.value = false;
  if (selectedRowIds.value.size <= 1 && lastKey.value === 'Enter') {
    const index = getIndexFromArrayBasedOnId(rowId, boardRows.value);
    if (index === boardRows.value.length - 1) return;
    const column = getItemFromArrayBasedOnId(columnId, boardColumns.value);
    if (!column || !columnsWithGoToNext.includes(column.component)) return;
    activateFieldById(getIdOfCell(boardRows.value[index + 1].id ?? null, columnId), 'textarea');
  }
  selectedRowIds.value = new Set();
};
const actuallyAssignValue = async (value: string, rowId: number, columnId: number) => {
  const cell = boardCells.value.find((cell) => cell.board_row_id === rowId && cell.board_column_id === columnId);
  if (cell !== undefined) {
    if (cell.value === value) {
      return;
    }
    boardCells.value = exchangeValuesOfObject({ ...cell, value }, boardCells.value);
    await updateBoardCell(props.board.id, { ...cell, value });
  } else {
    boardCells.value = [
      ...boardCells.value,
      {
        board_row_id: rowId,
        board_column_id: columnId,
        value,
      } as BoardCellResource,
    ];
    const { data: newCell } = await createBoardCell(props.board.id, {
      board_row_id: rowId,
      board_column_id: columnId,
      value,
    } as BoardCellResource);
    const index = _.findIndex(
      boardCells.value,
      (cell) => cell.board_row_id === rowId && cell.board_column_id === columnId
    );
    if (index > -1) {
      boardCells.value.splice(index, 1);
    }
    boardCells.value = exchangeValuesOfObject(newCell, boardCells.value);
  }
};

const checkToDeleteRow = async (keyboardEvent: KeyboardEvent, row: BoardRowResource) => {
  const cellsExists = boardCells.value.filter((c) => c.board_row_id === row.id).length > 0;
  if (cellsExists) return;
  if (!row.title || row.title.trim().length === 0) {
    removeItemFromArrayBasedOnId(row.id, boardRows.value);
    deleteBoardRow(props.board.id, row);
  }
};
const assignRowValue = async (value: string, row: BoardRowResource) => {
  const rowIds = selectedRowIds.value.size > 0 ? Array.from(selectedRowIds.value) : [row.id];
  for (let j = 0; j < rowIds.length; j++) {
    const item = getItemFromArrayBasedOnId(rowIds[j], boardRows.value);
    await actuallyAssignRowValue(value, item);
  }
  toast.success('Updated.');
  if (selectedRowIds.value.size <= 1 && lastKey.value === 'Enter') {
    const index = getIndexFromArrayBasedOnId(row.id, boardRows.value);
    if (index === boardRows.value.length - 1) {
      await addRow(null);
      return;
    }
    activateFieldById(getIdOfCell(boardRows.value[index + 1].id ?? null), 'textarea');
  }
  selectedRowIds.value = new Set();
};
const actuallyAssignRowValue = async (value: string, row: BoardRowResource) => {
  if (row.title && value === row.title) return;
  exchangeValuesOfObject({ ...row, title: value }, boardRows.value);
  await updateBoardRow(props.board.id, { ...row, title: value });
};

const loadingNewRow = ref(false);
const addRow = async (order: number | null) => {
  if (!order) order = (Math.max.apply(Math, boardRows.value) ?? 0) + 10000;

  loadingNewRow.value = true;

  const uuid = createUuId('new_row_');
  boardRows.value = [
    ...boardRows.value,
    {
      id: uuid,
      title: '',
      order: order,
    } as BoardRowResource,
  ];

  const newRow = await createBoardRow(props.board.id, { title: '', order: order });
  const index = getIndexFromArrayBasedOnId(uuid, boardRows.value);
  if (index > -1) {
    boardRows.value.splice(index, 1);
  }
  boardRows.value = exchangeValuesOfObject(newRow.data, boardRows.value);
  loadingNewRow.value = false;

  setTimeout(() => {
    activateFieldById(getIdOfCell(boardRows.value[index].id ?? null, undefined), 'textarea');
  }, 10);
};

const rowIdWorkingOn = ref(null);
const removeRow = async (row: BoardRowResource) => {
  const rowIds = selectedRowIds.value.size > 0 ? Array.from(selectedRowIds.value) : [row.id];
  for (let j = 0; j < rowIds.length; j++) {
    rowIdWorkingOn.value = rowIds[j];
    await deleteBoardRow(props.board.id, { id: rowIds[j] });
    removeItemFromArrayBasedOnId(rowIds[j], boardRows.value);
    boardCells.value = boardCells.value.filter((c) => c.board_row_id !== rowIds[j]);
  }
  toast.success((selectedRowIds.value.size === 0 ? 'Row' : selectedRowIds.value.size + ' rows') + ' was deleted');
  rowIdWorkingOn.value = null;
  selectedRowIds.value = new Set();
};

const createColumn = async (newColumn: BoardColumnResource) => {
  const { data: column } = await createBoardColumn(props.board.id, newColumn);
  boardColumns.value = [...boardColumns.value, column];
  toast.info('Created');
  await nextTick();
  scrollIntoViewById('board_column_' + column.id);
  await reOrderBoardColumns(
    props.board.id,
    boardColumns.value.map((r) => r.id)
  );
};

const updateColumn = async (updateColumn: BoardColumnResource) => {
  await updateBoardColumn(props.board.id, updateColumn);
  boardColumns.value = exchangeValuesOfObject(updateColumn, boardColumns.value, ['id', 'component'], 'id', false);
  toast.info('Updated');
};

const deleteColumn = async (columnId: number) => {
  await deleteBoardColumn(props.board.id, { id: columnId });
  boardColumns.value = removeItemFromArrayBasedOnId(columnId, boardColumns.value);
  toast.info('Deleted');
};

const listenForBroadcast = () => {
  Echo.join(`On.Board.${props.board.id}`)
    .listen(`.boardCell.created`, (e: BoardCellResource) => {
      boardCells.value = exchangeValuesOfObject(e, boardCells.value);
    })
    .listen(`.boardCell.updated`, (e: BoardCellResource) => {
      boardCells.value = exchangeValuesOfObject(e, boardCells.value);
    })
    .listen(`.boardColumn.created`, (e: BoardColumnResource) => {
      boardColumns.value = exchangeValuesOfObject(e, boardColumns.value);
    })
    .listen(`.boardColumn.updated`, (e: BoardColumnResource) => {
      let copy = exchangeValuesOfObject(e, boardColumns.value);
      copy.sort((a, b) => a.order - b.order);

      boardColumns.value = copy;
    })
    .listen(`.boardColumn.deleted`, (e: { id: number }) => {
      boardColumns.value = removeItemFromArrayBasedOnId(e.id, boardColumns.value);
    })
    .listen(`.boardRow.created`, (e: BoardRowResource) => {
      boardRows.value = exchangeValuesOfObject(e, boardRows.value);
    })
    .listen(`.boardRow.updated`, (e: BoardRowResource) => {
      boardRows.value = exchangeValuesOfObject(e, boardRows.value);
    })
    .listen(`.boardRow.deleted`, (e: { id: number }) => {
      boardRows.value = removeItemFromArrayBasedOnId(e.id, boardRows.value);
    });

  Echo.join(`On.${props.model}.${props.modelId}`)
    .listen(`.board.${props.board.id}.updated`, (e: { id: number; title: string; row_title: string }) => {
      emit('update:board', e);
    })
    .listen(`.board.${props.board.id}.reOrdered`, () => {
      emit('reFetch');
    });
};

onMounted(() => {
  listenForBroadcast();
});

const editMode = ref(props.canEdit);
const loading = ref(false);
const showSaveAsTemplateModal = ref(false);
const showDownloadBoardModal = ref(false);
const downloadingBoard = ref(false);

const selectedColumn = ref<BoardColumnResource | null>(null);
const editColumn = async (column: BoardColumnResource) => {
  selectedColumn.value = null;
  await nextTick();
  selectedColumn.value = column;
};

const showAuditsButton = ref(false);
const showAudits = ref(false);
const auditsRow = ref(null);
const auditsColumn = ref(null);
const auditsCell = ref(null);
const openAudits = async (boardRow: null | BoardRowResource, boardColumn: null | BoardColumnResource) => {
  showAudits.value = false;
  auditsRow.value = boardRow;
  auditsColumn.value = boardColumn;
  auditsCell.value = null;
  if (boardRow && boardColumn) {
    const cell = boardCells.value.find((c) => c.board_row_id === boardRow.id && c.board_column_id === boardColumn.id);
    if (cell) {
      auditsCell.value = cell;
    }
  }

  await nextTick();
  showAudits.value = true;
};

const actions = computed(() => {
  if (props.isTemplate) {
    return [];
  }

  if (!props.canEdit) {
    return [
      {
        title: 'Download',
        preIcon: 'fa-download',
        action: () => {
          showDownloadBoardModal.value = false;
          nextTick(() => {
            showDownloadBoardModal.value = true;
          });
        },
      },
    ];
  }

  const dropdown = [
    !props.isTemplate && usePage().props.auth.user.groups.length > 0 && props.canEdit
      ? {
          title: 'Save as Template',
          preIcon: 'fa-save',
          action: () => {
            showSaveAsTemplateModal.value = false;
            nextTick(() => {
              showSaveAsTemplateModal.value = true;
            });
          },
        }
      : null,
    {
      title: 'Download',
      preIcon: 'fa-download',
      action: () => {
        showDownloadBoardModal.value = false;
        nextTick(() => {
          showDownloadBoardModal.value = true;
        });
      },
    },
    props.canEdit && !isSmallScreen.value
      ? {
          type: 'header',
          title: 'Audits',
        }
      : null,

    props.canEdit && !isSmallScreen.value
      ? {
          title: 'Whole Board',
          preIcon: 'fa-history',
          action: () => {
            openAudits();
          },
        }
      : null,
    props.canEdit && !isSmallScreen.value
      ? {
          preIcon: 'fa-history',
          title: showAuditsButton.value ? 'Hide' : 'For Elements',
          action: () => {
            showAuditsButton.value = !showAuditsButton.value;
          },
        }
      : null,
  ].filter((i) => i !== null);

  if (props.canEdit) {
    return [
      {
        action: () => {
          emit('edit');
        },
        title: 'Edit',
        icon: 'fa-pencil fa-regular',
        loading: loading.value,
        emphasized: true,
        buttonDropdown: dropdown,
      },
    ];
  } else {
    return [
      {
        icon: 'fa-chevron-down',
        loading: loading.value,
        emphasized: true,
        dropdown: dropdown,
      },
    ];
  }
});

const boardRowTitle = ref(null);
const boardRowTitleEditModalOpen = ref(false);
const editBoardRowTitle = async () => {
  if (!props.canEdit) return;
  if (!editMode.value) return;
  boardRowTitleEditModalOpen.value = false;
  await nextTick();
  boardRowTitle.value = props.board.row_title ?? 'Description';
  boardRowTitleEditModalOpen.value = true;
};
const updateBoardRowTitle = async () => {
  if (!props.canEdit) return;
  boardRowTitleEditModalOpen.value = false;
  if (boardRowTitle.value === props.board.row_title) return;
  await patchBoard(props.board.id, { row_title: boardRowTitle.value });
  emit('update:board', { id: props.board.id, row_title: boardRowTitle.value });
};

const getAllNumberColumns = () => {
  return boardColumns.value
    .filter((c: BoardCellResource) => ['board-column-text', 'board-column-toggle'].includes(c.component))
    .map((c: BoardColumnResource) => {
      const allValues = boardCells.value
        .filter((cell: BoardCellResource) => cell.board_column_id === c.id)
        .filter((cell: BoardCellResource) => cell.value !== null);

      const isToggle = c.component === 'board-column-toggle';

      const numericValues = allValues.filter((cell: BoardCellResource) => isNumeric(cell.value));
      if (!isToggle && allValues.length !== numericValues.length) return null;
      if (!isToggle && allValues.length === 0) return null;

      return {
        id: c.id,
        title: c.title,
        values: allValues,
        sum: allValues.reduce(
          (partialSum, value) => partialSum + (isToggle ? (value.value ? 1 : 0) : Number(value.value)),
          0
        ),
        denominator: isToggle ? boardRows.value.length : null,
      };
    })
    .filter((i) => i !== null);
};
const getDataListOptionsForColumn = (column: BoardColumnResource) => {
  if (column.component !== 'board-column-text') return null;
  const allValues = boardCells.value
    .filter((cell: BoardCellResource) => cell.board_column_id === column.id)
    .filter((cell: BoardCellResource) => cell.value !== null)
    .map((cell: BoardCellResource) => cell.value);

  return getSortedListOfValues(allValues);
};

const isOpen = ref(false);

const duplicateRow = async (rowToDuplicate: BoardRowResource) => {
  rowIdWorkingOn.value = rowToDuplicate.id;
  const indexOfRow = boardRows.value.indexOf(rowToDuplicate);
  const idOfRow = createUuId('new_row_');
  const newRow = { ...rowToDuplicate, id: idOfRow };
  boardRows.value.splice(indexOfRow, 0, newRow);

  const data = await createBoardRow(props.board.id, newRow);

  const indexOfTemp = getIndexFromArrayBasedOnId(idOfRow, boardRows.value);
  if (indexOfTemp > -1) {
    boardRows.value[indexOfTemp] = data.data;
  } else {
    boardRows.value.splice(indexOfRow, 0, data.data);
  }
  rowIdWorkingOn.value = data.data.id;
  for (let j = 0; j < boardCells.value.length; j++) {
    if (boardCells.value[j].board_row_id === rowToDuplicate.id) {
      const { data: newCell } = await createBoardCell(props.board.id, {
        board_row_id: data.data.id,
        board_column_id: boardCells.value[j].board_column_id,
        value: boardCells.value[j].value,
      } as BoardCellResource);
      boardCells.value = exchangeValuesOfObject(newCell, boardCells.value);
    }
  }

  await reOrderBoardRows(
    props.board.id,
    boardRows.value.map((r) => r.id)
  );
  rowIdWorkingOn.value = null;
  useToast().info('Duplicated');
};

const insertRowRelative = async (relativeTo: BoardRowResource, before = true) => {
  const indexOfRow = boardRows.value.indexOf(relativeTo);
  const idOfRow = createUuId('new_row_');
  const newRow = {
    id: idOfRow,
    title: 'New Row',
    color: null,
    order: relativeTo.order + (before ? 0 : 1),
  };
  boardRows.value.splice(indexOfRow + (before ? 0 : 1), 0, newRow);

  const data = await createBoardRow(props.board.id, newRow);

  const indexOfTemp = getIndexFromArrayBasedOnId(idOfRow, boardRows.value);
  if (indexOfTemp > -1) {
    boardRows.value[indexOfTemp] = data.data;
  } else {
    boardRows.value.splice(indexOfRow, 0, data.data);
  }

  useToast().info('Added');
  setTimeout(() => {
    activateFieldById(getIdOfCell(data.data.id ?? null), 'textarea', true);
  }, 50);

  await reOrderBoardRows(
    props.board.id,
    boardRows.value.map((r) => r.id)
  );
};
const newOrderOfRows = async (e: SortEmit) => {
  const { selectedItem: idOfMovedRow, newOrder: arrayOfIds } = e;
  await reOrderBoardRows(props.board.id, arrayOfIds, idOfMovedRow);
  boardRows.value = reorderArrayByIds(boardRows.value, arrayOfIds);
};

const { eventTypes, fetch: fetchEventTypes } = inject(eventTypesKey, {
  eventTypes: computed(() => []),
  fetch: (force?: boolean = false) => {},
});

fetchEventTypes();

const connectetedEventTypes = computed(() => {
  if (!props.board) return [];

  return eventTypes.value.filter((ev) => ev.pivot.boards.some((cl) => cl.id === props.board.id));
});

const concatAllEvenTypeNames = computed(() => {
  if (!connectetedEventTypes.value) return '';

  return connectetedEventTypes.value.map((ev) => ev.name).join(', ');
});
</script>

<template>
  <ContentContainer
    ref="boardContainer"
    :edit-mode="isTemplate"
    :title="board.title"
    :can-edit="canEdit"
    :loading="loading"
    :actions="actions"
    pre-icon="fa-table-cells"
    actions-as-buttons
    :z-index="99"
    :with-second-border="!(!isTemplate && isOpen)"
    :align-center="!isTemplate && isOpen"
    :content-class="!isTemplate && isOpen ? '' : undefined"
    :just-content-without-header="isDisplay"
    @open="isOpen = $event"
    @edit="$emit('edit')">
    <template
      v-if="isOpen"
      #afterTitle>
      <div class="tiny-scrollbar ml-edge flex max-w-[700px] gap-edge overflow-auto">
        <div class="hidden items-center text-nowrap text-soft md:flex">
          #Rows:
          <span class="ml-edge-1/4 text">
            {{ boardRows.length }}
          </span>
        </div>
        <div
          v-for="col in getAllNumberColumns()"
          :key="col.id"
          class="flex items-center text-nowrap text-soft">
          {{ col.title }}:
          <span class="ml-edge-1/4 text">
            {{ col.sum }} <span v-if="col.denominator">/{{ col.denominator }}</span>
          </span>
        </div>
      </div>
    </template>
    <template #underHeader>
      <div v-if="isOpen && isTemplate">
        <SectionConnectedToEventTypeSubHeader
          :model="model"
          :model-id="modelId"
          pre-icon="fa-table-cells"
          :title-of-item="board.title"
          :id-of-item="board.id"
          type-of-item="Board" />
      </div>
    </template>

    <template #content>
      <div
        v-if="newlyCreatedBoardId !== board.id"
        class="flex flex-col gap-edge">
        <VTable
          v-model="sortedRows"
          :sticky-header="true"
          :handle-outside="true"
          :table-border="'all'"
          :can-drag="editMode && canEdit && canDragAndDrop && !loadingNewRow && !isSmallScreen"
          row-size="small"
          :edge-to-edge="true"
          :set-row-classes="
            () => {
              return '[&>*>div>div]:items-center [&>*>div]:px-edge-1/2 [&>*>div>div]:flex group !border-none';
            }
          "
          @sorted="newOrderOfRows">
          <template #head>
            <VTableRow head>
              <VTableCell
                style="min-width: 40px; max-width: 40px; width: 40px"
                classes="border-t border-l group/header-multiselect  text-center left-0 h-[30px] z-[11] !pt-0 sticky  !px-0">
                <div
                  class="flex w-[40px] justify-center text-center"
                  @click="canEdit && editMode ? (selectedRowIds.size === 0 ? addAllRowIds() : clearRowIds()) : null">
                  <div
                    v-if="selectedRowIds.size === 0"
                    class="block text-sm"
                    :class="{ 'group-hover/header-multiselect:hidden': editMode && canEdit }">
                    #
                  </div>
                  <div
                    v-if="(canEdit && editMode) || selectedRowIds.size > 0"
                    class="pl-[3px]"
                    :class="{
                      'hidden group-hover/header-multiselect:block': editMode && canEdit && selectedRowIds.size === 0,
                    }">
                    <CheckBox
                      :model-value="selectedRowIds.size > 0"
                      size="sm" />
                  </div>
                </div>
              </VTableCell>

              <VTableCell
                style="min-width: 10rem"
                classes="left-[40px] z-[11] sticky !p-0 border">
                <div class="flex h-[33px] w-full items-center gap-[5px] pl-[5px]">
                  <i
                    v-if="!isDisplay"
                    class="fa fa-fw cursor-pointer text-soft"
                    :class="
                      (orderBy === 'title' && orderDirection === 'desc' ? 'fa-sort-down' : '') +
                      (orderBy === 'title' && orderDirection === 'asc' ? 'fa-sort-up' : '') +
                      (orderBy !== 'title' ? 'fa-sort' : '')
                    "
                    @click="sortBy('title', orderBy === 'title' && orderDirection === 'asc' ? 'desc' : 'asc')" />
                  <div
                    title="Click to open up a menu for this column"
                    :class="{ 'cursor-pointer': editMode && canEdit }"
                    class="grid w-full grid-cols-[1fr_37px] items-center"
                    @click="editBoardRowTitle()">
                    <div class="truncate text-sm text">
                      {{ board.row_title ? board.row_title : 'Description' }}
                    </div>
                    <div
                      v-if="canEdit && editMode"
                      class="btn btn-in-table flex h-full w-[35px] min-w-[35px] items-center justify-center">
                      <i class="fa fa-fw fa-chevron-down" />
                    </div>
                  </div>
                </div>
              </VTableCell>
              <ColumnHeader
                v-for="(columnDefinition, index) in boardColumns"
                :id="'board_column_' + columnDefinition.id"
                :key="columnDefinition.id"
                :column="columnDefinition"
                :can-edit="editMode && canEdit"
                :show-audits="showAuditsButton"
                :rows="sortedRows"
                :sort-up="isDisplay ? null : orderBy === columnDefinition.id && orderDirection === 'asc'"
                :sort-down="isDisplay ? null : orderBy === columnDefinition.id && orderDirection === 'desc'"
                :can-move-left="index > 0"
                :can-move-right="index < boardColumns.length - 1"
                :board="board"
                classes="border-t"
                @open-audits="openAudits(null, columnDefinition)"
                @edit="editColumn(columnDefinition)"
                @move-left="moveColumnLeft(index)"
                @move-right="moveColumnRight(index)"
                @sort="sortBy(columnDefinition.id, $event)" />

              <!--              <VTableCell>-->
              <!--                <div>t</div>-->
              <!--              </VTableCell>-->

              <ColumnCRUDModal
                v-if="canEdit && editMode"
                :column-types="boardColumnTypes"
                :init-column="selectedColumn"
                classes="border-t border-r"
                min-width="20px"
                width="20px"
                :no-padding="true"
                @closed="selectedColumn = null"
                @created="createColumn"
                @updated="updateColumn"
                @deleted="deleteColumn" />
            </VTableRow>
          </template>

          <template #row="{ item: row, index }: { item: BoardRowResource }">
            <VTableCell
              style="min-width: 40px; max-width: 40px; width: 40px"
              class="group/multiselect sticky left-0 z-[9] border-l !p-0 text-center"
              :clickable="editMode && canEdit"
              @click="selectedRowIds.has(row.id) ? selectedRowIds.delete(row.id) : selectedRowIds.add(row.id)">
              <div
                v-if="!selectedRowIds.has(row.id)"
                class="w-[40px]">
                <div
                  class="block pl-[8px] font-headers"
                  :class="{
                    'group-hover/multiselect:hidden group-hover:hidden': editMode && canEdit && canDragAndDrop,
                  }">
                  {{ index + 1 }}
                </div>
                <div
                  v-if="editMode && canEdit"
                  class="hidden !p-0 text-center group-hover/multiselect:block">
                  <CheckBox
                    :model-value="false"
                    size="sm" />
                </div>
                <div
                  v-if="editMode && canEdit && canDragAndDrop"
                  class="hidden pl-[3.5px] group-hover:block group-hover/multiselect:hidden">
                  <i class="fa fa-fw fa-arrows-up-down"></i>
                </div>
              </div>
              <CheckBox
                v-else-if="selectedRowIds.has(row.id)"
                :model-value="true"
                size="sm" />
            </VTableCell>

            <VTableCell
              main-cell
              :class="{ '[&_div]:!p-0': editMode && canEdit }"
              classes="relative group/button left-[40px] z-[9] sticky  [&>*.left-side-border]:block [&>*.right-side-border]:block !pt-[1px] !p-0">
              <TextCell
                :id="getIdOfCell(row.id)"
                :model-value="row.title"
                :can-edit="canEdit && editMode"
                placeholder="Click to edit row"
                :tabindex="0"
                :data-list-options="
                  isSmallScreen
                    ? null
                    : getSortedListOfValues(sortedRows.map((r) => r.title).filter((i) => i !== null && i.length > 0))
                "
                :whisper="{ channel: 'On.Board.' + board.id, string: 'row_' + row.id + '_title' }"
                :v-data-board-row-index="index"
                @keydown-esc="checkToDeleteRow($event, row)"
                @update:model-value="assignRowValue($event, row)" />
              <div
                v-if="showAuditsButton"
                class="absolute right-0 top-0 hidden group-hover/button:block">
                <VButton
                  tool-tip-text="Open Audits"
                  icon="fa-history"
                  size="xs"
                  @click="openAudits(row, null)" />
              </div>
            </VTableCell>

            <ColumnCell
              v-for="columnDefinition in boardColumns"
              :key="columnDefinition.id"
              :with-audits-button="showAuditsButton"
              :model-value="getRowValue(row.id, columnDefinition.id)"
              :column-definition="columnDefinition"
              :can-edit="editMode && canEditColumn(columnDefinition.component) && canEditRow(row)"
              :column-id="columnDefinition.id"
              :width="columnDefinition.width"
              :index-key="index"
              :is-template="isTemplate"
              :component-id="getIdOfCell(row.id, columnDefinition.id)"
              :whisper="{
                channel: 'On.Board.' + board.id,
                string: 'row_' + row.id + '_column_' + columnDefinition.id,
              }"
              :data-list-options="isSmallScreen ? null : getDataListOptionsForColumn(columnDefinition)"
              @open-audits="openAudits(row, columnDefinition)"
              @update:model-value="assignValue($event, row.id, columnDefinition.id)" />

            <VTableCell
              v-if="canEdit && editMode"
              class="sticky right-0 !p-0 [&_.left-side-border]:block">
              <VDropdown close-on-click>
                <template #click-area>
                  <VButton
                    size="xs"
                    :loading="rowIdWorkingOn === row.id"
                    icon="fa-ellipsis" />
                </template>
                <template #dropdown="{ close }">
                  <VList
                    :items="[
                      {
                        disabled: !canDragAndDrop,
                        hoverTitle: canDragAndDrop ? 'Add a row above this' : 'Cannot add above while sorting',
                        title: 'Add Row Above',
                        preIcon: 'fa-arrow-up',
                        action: () => {
                          insertRowRelative(row, true);
                          close();
                        },
                      },
                      {
                        title: 'Add Row Below',
                        disabled: !canDragAndDrop,
                        hoverTitle: canDragAndDrop ? 'Add a row below this' : 'Cannot add below while sorting',
                        preIcon: 'fa-arrow-down',
                        action: () => {
                          insertRowRelative(row, false);
                          close();
                        },
                      },
                      {
                        title: 'Duplicate',
                        hoverTitle: 'Duplicate this row',
                        preIcon: 'fa-clone',
                        action: () => {
                          duplicateRow(row);
                          close();
                        },
                      },
                      {
                        title: 'Audits',
                        hoverTitle: 'Show Audits for this row',
                        preIcon: 'fa-history',
                        action: () => {
                          openAudits(row, null);
                          close();
                        },
                      },
                      {
                        type: 'divider',
                      },
                      {
                        title: 'Delete',
                        preIcon: 'fa-trash text-warning',
                        action: () => {
                          removeRow(row);
                          close();
                        },
                      },
                    ]" />
                </template>
              </VDropdown>
            </VTableCell>
          </template>

          <template
            v-if="editMode"
            #footer>
            <VTableRow no-background>
              <VTableCell class="sticky left-0 z-[9] border-l !p-0 text-center"></VTableCell>
              <VTableCell
                clickable
                classes="relative group/button left-[40px] z-[9] sticky  [&>*.left-side-border]:block [&>*.right-side-border]:block">
                <div
                  class="text-headers flex h-[40px] items-center gap-edge-1/4 p-edge text-sm hover:bg-row-hover hover:text"
                  @click="addRow(null)">
                  <i class="fa fa-fw fa-plus text-highlight"></i>
                  Add row
                </div>
              </VTableCell>
              <VTableCell v-for="columnDefinition in boardColumns" />
              <VTableCell
                v-if="canEdit && editMode"
                class="sticky right-0 !p-0 [&_.left-side-border]:block">
              </VTableCell>
            </VTableRow>
          </template>

          <!--          <template #footer>-->
          <!--            <div-->
          <!--              v-if="editMode"-->
          <!--              class="py-edge pl-[1px] sticky left-[1px] w-[1px]">-->
          <!--              <VButton-->
          <!--                title="Add row"-->
          <!--                icon="fa-plus"-->
          <!--                @click="addRow(null)" />-->
          <!--            </div>-->
          <!--          </template>-->
        </VTable>
      </div>
      <div v-if="newlyCreatedBoardId === board.id">
        <EmptyStateFullPage
          icon="fa-circle-o-notch fa-spin"
          :description="'Building ' + board.title" />
      </div>
    </template>
  </ContentContainer>

  <BoardAuditSidebar
    v-if="showAudits"
    :board="board"
    :board-row="auditsRow"
    :board-column="auditsColumn"
    :board-cell="auditsCell"
    @closed="showAudits = false" />

  <BoardDownloadModal
    v-if="showDownloadBoardModal"
    v-model:downloading="downloadingBoard"
    :board-id="board.id"
    :column-definitions="boardColumns"
    :order-by="orderBy"
    :order-direction="orderDirection"
    @closed="showDownloadBoardModal = false" />

  <SaveAsTemplateModal
    v-if="showSaveAsTemplateModal"
    :init-title="board.title"
    :modal-title="'Add ' + board.title + ' as a template'"
    url="/api/boards"
    :params="{
      model_type: 'App\\Group',
      template_id: board.id,
    }"
    @closed="showSaveAsTemplateModal = false" />

  <CrudModal
    v-if="boardRowTitleEditModalOpen"
    title="Update Column"
    :disabled="boardRowTitle.length < 2"
    update
    small
    :delete-button="false"
    @closed="boardRowTitleEditModalOpen = false"
    @update="updateBoardRowTitle">
    <div class="form-layout">
      <TextInput
        v-model="boardRowTitle"
        label="Title"
        placeholder="Title of Column"
        @keydown.enter="updateBoardRowTitle" />
    </div>
  </CrudModal>
</template>
