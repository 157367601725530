<script setup lang="ts">
import { useToast } from 'vue-toastification';
import CopyableLink from '@/components/CopyableLink.vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import DisplayWebsite from '@/components/Display/DisplayWebsite.vue';
import NumberInput from '@/components/Inputs/NumberInput.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import VButton from '@/components/Inputs/VButton.vue';
import { nextTick, ref } from 'vue';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import CopyLink from '@/components/Helpers/CopyLink.vue';
import { copyToClipboard } from '@/util/copy';

type Props = {
  nameOfModel?: string | null;
  modelType: string;
  modelId: number;
  isAdminModal?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  nameOfModel: null,
  isAdminModal: false,
});

const toast = useToast();
const { assertCertain } = useCertaintyModal();
const { assertReadyToDeleteModal } = useDeleteObjectModal();

const calendarLinks = ref([]);
const monthsBack = ref(1);
const monthsAhead = ref(2);

const modalOpen = ref(false);
const working = ref(false);
const loaded = ref(false);

const loadLinks = () => {
  if (loaded.value) return;
  axios
    .get('/api/calendar-links/', {
      params: {
        model_type: props.modelType,
        model_id: props.modelId,
      },
    })
    .then((response) => {
      calendarLinks.value = response.data;
      loaded.value = true;
    });
};

const createLink = async () => {
  if (working.value) return;
  const certain = await assertCertain('Create Link', 'Are you sure you want to create this link?');

  if (!certain) return;

  if (!props.isAdminModal && calendarLinks.value.length > 0) return;

  working.value = true;

  await axios
    .post('/api/calendar-links/', {
      model_type: props.modelType,
      model_id: props.modelId,
      months_back: monthsBack.value,
      months_ahead: monthsAhead.value,
    })
    .catch((error) => {
      console.error(error);
      working.value = false;
      toast.warning('Something went wrong, please try again later');
    });

  toast.success('Created.');
  working.value = false;
  loaded.value = false;
  loadLinks();
};

const openModal = () => {
  modalOpen.value = false;
  loadLinks();
  nextTick(() => {
    modalOpen.value = true;
  });
};

const updateLink = (link) => {
  if (!props.isAdminModal) {
    return;
  }
  axios
    .patch(`/api/calendar-links/${link.id}`, {
      months_back: link.months_back,
      months_ahead: link.months_ahead,
    })
    .then((response) => {
      toast.success('Updated');
    })
    .catch((error) => {
      console.error(error);
      toast.warning('Something went wrong, please try again later');
    });
};

const deleteLink = async (link) => {
  if (working.value) return;
  const certain = await assertReadyToDeleteModal('Delete Link', 'Are you sure you want to delete this link?');

  if (!certain) return;

  await axios.delete(`/api/calendar-links/${link.id}`).catch((error) => {
    console.error(error);
    working.value = false;
    loaded.value = false;
    loadLinks();
    toast.warning('Something went wrong, please try again later');
  });

  toast.success('Deleted');
  working.value = false;
  loaded.value = false;
  loadLinks();
};

if (!props.isAdminModal) {
  loadLinks();
}
</script>

<template>
  <div>
    <div
      v-if="!isAdminModal"
      class="text-uppercase text-xl mb-edge">
      Copy the Link and paste into your calendar provider
    </div>
    <div v-if="isAdminModal">
      <VButton
        size="sm"
        title="Show Modal"
        tool-tip-text="Show Modal For Calendar Links."
        @click="openModal" />

      <CrudModal
        v-if="modalOpen"
        :is-disabled="false"
        x-large
        :only-close-button="true"
        title="Create Ical Links"
        @closed="modalOpen = false">
        <VTable>
          <template #head>
            <VTableRow head>
              <VTableCell>Id</VTableCell>
              <VTableCell>Link</VTableCell>
              <VTableCell>Months Before</VTableCell>
              <VTableCell>Months After</VTableCell>
              <VTableCell />
              <VTableCell />
            </VTableRow>
          </template>
          <VTableRow
            v-for="link in calendarLinks"
            :key="link.id">
            <VTableCell>
              {{ link.id }}
            </VTableCell>
            <VTableCell>
              <DisplayWebsite :website="$route('public.calendarLinks.show', link.uuid)" />
            </VTableCell>
            <VTableCell>
              <NumberInput
                v-model="link.months_back"
                size="small"
                :with-controlles="true" />
            </VTableCell>
            <VTableCell>
              <NumberInput
                v-model="link.months_ahead"
                size="small"
                :with-controlles="true" />
            </VTableCell>
            <VTableCell>
              <button
                class="btn btn-create btn-sm"
                @click="updateLink(link)">
                <i class="fa fa-fw fa-save" />
              </button>
            </VTableCell>
            <VTableCell>
              <button
                class="btn btn-delete btn-sm"
                @click="deleteLink(link)">
                <i class="fa fa-fw fa-trash" />
              </button>
            </VTableCell>
          </VTableRow>
          <template #footer>
            <tfoot>
              <VTableRow>
                <VTableCell colspan="2" />
                <VTableCell>
                  <NumberInput
                    v-model="monthsBack"
                    size="small"
                    :with-controlles="true" />
                </VTableCell>
                <VTableCell>
                  <NumberInput
                    v-model="monthsAhead"
                    size="small"
                    :with-controlles="true" />
                </VTableCell>
                <VTableCell colspan="2">
                  <button
                    class="btn btn-create btn-sm float-right"
                    @click="createLink">
                    Create
                  </button>
                </VTableCell>
              </VTableRow>
            </tfoot>
          </template>
        </VTable>
      </CrudModal>
    </div>
    <div v-if="!isAdminModal && loaded">
      <div>
        <BoxContainer>
          <div class="space-y-edge">
            <div class="flex items-center justify-center gap-edge-1/4">
              <i class="fa fa-fw fa-google text-lg" />
              <i class="fa fa-fw fa-windows text-lg" />
              <i class="fa fa-fw fa-apple text-lg" />
            </div>
            <div class="text-center text-sm text-soft">Google Calendar, Outlook, Apple Calendar</div>
            <div class="flex items-center justify-center">
              <VButton
                v-if="loaded && calendarLinks.length === 0"
                size="md"
                type="primary"
                :loading="working"
                icon="fa-plus"
                title="Create Link"
                :disabled="working"
                @click="createLink" />

              <div
                v-else
                class="flex items-center justify-center">
                <div class="flex items-center gap-edge rounded bg-content-secondary p-edge-1/2">
                  <span class="text-sm text-soft">
                    {{ $route('public.calendarLinks.show', calendarLinks[0].uuid) }}
                  </span>
                  <VButton
                    size="md"
                    type="primary"
                    tool-tip-text="Copy Link"
                    :loading="working"
                    icon="fa-copy"
                    title="Copy Link"
                    :disabled="working"
                    @click="copyToClipboard($route('public.calendarLinks.show', calendarLinks[0].uuid))" />
                </div>
              </div>

              <!--              <div v-else>-->
              <!--                <div class="mt-edge">-->
              <!--                  <div-->
              <!--                    v-for="link in calendarLinks"-->
              <!--                    class="flex gap-edge">-->
              <!--                    <a-->
              <!--                      :href="$route('public.calendarLinks.show', link.uuid)"-->
              <!--                      class="hover:underline"-->
              <!--                      target="_blank">-->
              <!--                      {{ $route('public.calendarLinks.show', link.uuid) }}-->
              <!--                    </a>-->
              <!--                    <CopyableLink-->
              <!--                      :url="$route('public.calendarLinks.show', link.uuid)"-->
              <!--                      title="Your Calendar Link" />-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
        </BoxContainer>
      </div>
      <!--      <div class="mt-edge">-->
      <!--        <div class="bg-content-secondary p-edge-1/2 rounded">-->
      <!--          {{ $route('public.calendarLinks.show', calendarLinks[0].uuid) }}-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>
