import posthog from 'posthog-js';

// Define common event properties interface
interface BaseEventProperties {
  userId?: string;
  timestamp?: Date;
  source?: string;
  platform?: 'web' | 'mobile' | 'desktop';
}

// Analytics service class
export class Analytics {
  private static instance: Analytics;
  private initialized: boolean = false;

  private constructor() {}

  /**
   * Initialize PostHog with your project API key
   */
  public static init(apiKey: string, config?: posthog.Config): void {
    if (!Analytics.instance) {
      Analytics.instance = new Analytics();
    }

    if (!Analytics.instance.initialized) {
      posthog.init(apiKey, {
        api_host: 'https://eu.i.posthog.com',
        ...config,
        autocapture: false,
        capture_pageview: false,
        capture_pageleave: false,
      });
      Analytics.instance.initialized = true;
    }
  }

  /**
   * Track a custom event
   */
  public static track(eventName: EventType | string, properties?: T & BaseEventProperties) {
    if (!Analytics.instance?.initialized) {
      console.warn('PostHog Analytics not initialized. Call Analytics.init() first.');
      return;
    }

    posthog.capture(eventName, {
      timestamp: new Date(),
      ...properties,
    });
  }

  /**
   * Identify a user
   */
  public static identify(userId: string, properties?: Record<string, any>): void {
    if (!Analytics.instance?.initialized) {
      console.warn('PostHog Analytics not initialized. Call Analytics.init() first.');
      return;
    }

    posthog.identify(userId, properties);
  }

  /**
   * Reset the current user
   */
  public static reset(): void {
    if (!Analytics.instance?.initialized) {
      console.warn('PostHog Analytics not initialized. Call Analytics.init() first.');
      return;
    }

    posthog.reset();
  }

  /**
   * Set user properties
   */
  public static setUserProperties(properties: Record<string, any>): void {
    if (!Analytics.instance?.initialized) {
      console.warn('PostHog Analytics not initialized. Call Analytics.init() first.');
      return;
    }

    posthog.people.set(properties);
  }

  /**
   * Enable/disable analytics tracking
   */
  public static optOut(optOut: boolean): void {
    if (!Analytics.instance?.initialized) {
      console.warn('PostHog Analytics not initialized. Call Analytics.init() first.');
      return;
    }

    if (optOut) {
      posthog.opt_out_capturing();
    } else {
      posthog.opt_in_capturing();
    }
  }
}

// Common event types
export const EventTypes = {
  PAGE_VIEW: '$pageview',
  BUTTON_CLICK: 'button_click',
  FORM_SUBMIT: 'form_submit',
  USER_SIGNUP: 'user_signup',
  USER_LOGIN: 'user_login',
  USER_LOGOUT: 'user_logout',
  FEATURE_USAGE: 'feature_usage',
  ERROR: 'error',
  ONBOARDING_STARTED: 'onboarding_started',
  ONBOARDING_CREATE_ACCOUNT: 'onboarding_create_account',
  ONBOARDING_PLAN_SELECTOR: 'onboarding_plan_selector',
  ONBOARDING_NEEDS_SELECTOR: 'onboarding_needs_selector',
  ONBOARDING_CREATING_ACCOUNT: 'onboarding_creating_account',
  ONBOARDING_ACCOUNT_CREATED: 'onboarding_account_created',
  GROUP_CREATED: 'group_created',
  ONBOARDING_COMPLETED: 'onboarding_completed',
} as const;

export type EventType = (typeof EventTypes)[keyof typeof EventTypes];
