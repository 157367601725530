import { useEventRequestResponseStore } from '@/store/EventRequestResponseStore';
import type { EventRequestResponseResource } from '@/types/event-request';
import { computed, provide, readonly } from 'vue';
import { storeToRefs } from 'pinia';
import { formatModel } from '@/util/store-helpers';
import type { EventRequestResponsesComposable } from '@/provide/keys';
import { eventRequestResponsesKey } from '@/provide/keys';
import type { ModelType } from '@/types/global';

export const useEventRequestResponses = (
  model: ModelType,
  modelId: number,
  shouldProvide = true,
  hasEventRequests = false
): EventRequestResponsesComposable => {
  const { list, loading } = storeToRefs(useEventRequestResponseStore());
  const { fetchEventRequestResponses } = useEventRequestResponseStore();

  const eventRequestResponses = computed(() => list.value.get(formatModel(model, modelId)) || []);

  const fetch = async (force = false) => {
    if (!hasEventRequests) return;
    if (force || !list.value.has(formatModel(model, modelId))) {
      await fetchEventRequestResponses(model, modelId);
    }
  };

  const addItem = (eventRequestResponse: EventRequestResponseResource) => {
    if (!hasEventRequests) return;
    const key = formatModel(model, modelId);
    const current = list.value.get(key) || [];
    list.value.set(key, [...current, eventRequestResponse]);
  };

  const removeItem = (eventRequestResponsesId: number) => {
    if (!hasEventRequests) return;
    const key = formatModel(model, modelId);
    const current = list.value.get(key) || [];
    list.value.set(
      key,
      current.filter((item) => item.id !== eventRequestResponsesId)
    );
  };

  const updateItem = (eventRequestResponse: EventRequestResponseResource) => {
    if (!hasEventRequests) return;
    const key = formatModel(model, modelId);
    const current = list.value.get(key) || [];
    list.value.set(
      key,
      current.map((item) => (item.id === eventRequestResponse.id ? eventRequestResponse : item))
    );
  };

  if (shouldProvide) {
    provide(eventRequestResponsesKey, {
      eventRequestResponses: readonly(eventRequestResponses),
      fetch,
      addItem,
      removeItem,
      updateItem,
      loading: readonly(loading),
    });
  }

  return {
    eventRequestResponses,
    loading,
    fetch,
    addItem,
    removeItem,
    updateItem,
  };
};
