<script lang="ts" setup>
import { inject, onMounted, useTemplateRef, watch } from 'vue';
import ContentHeader from '@/components/Content/ContentHeader.vue';
import { usePageTabsStore } from '@/store/PageTabsStore';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';
import { getKey } from '@/util/globals';
import { contentListElement } from '@/provide/keys';
import { type ActionButtonProps } from '@/components/Inputs/Components/ActionButtonGroup.vue';

type Props = {
  loading?: boolean;
  firstLoad?: boolean;
  withBackButton?: boolean;
  actions?: ActionButtonProps[];
  showTabs?: boolean;
  tabs?: [];
  actionsAsButtons?: boolean | null;
  title?: string | null;
  titleInfo?: string;
  superHeader?: string | null;
  preIcon?: string;
  underTitle?: string;
  backAction?: (() => void) | null;
  emptyAction?: () => void;
  emptyMessage?: string;
  emptyIcon?: string;
  wrapperClasses?: string;
  contentSlotClasses?: string;
  emptyButtonActionText?: string;
  fullSize?: boolean;
  minimizeContainer?: boolean;
  stripedChildren?: boolean;
  heightReduction?: number | null;
  teleportHeaderId?: string;
  headerSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  heightFromElement?: string;
  withPaddingBottom?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  firstLoad: true,
  withBackButton: true,
  fullSize: true,
  minimizeContainer: false,
  stripedChildren: true,
  actionsAsButtons: true,
  actions: () => [],
  tabs: [],
  showTabs: false,
  title: null,
  titleInfo: '',
  preIcon: '',
  underTitle: '',
  backAction: null,
  superHeader: null,
  heightReduction: 0,
  emptyAction: () => {},
  emptyMessage: '',
  emptyIcon: null,
  wrapperClasses: '',
  contentSlotClasses: '',
  emptyButtonActionText: 'Create New',
  teleportHeaderId: undefined,
  headerSize: 'h2',
  heightFromElement: undefined,
  withPaddingBottom: true,
});

const pageTabsStore = usePageTabsStore();

const injectElement = inject(contentListElement, 'main');

// const widthOfContent = ref(100);
// const left = ref(0);
// const heightOfHeader = ref('var(--content-header-height)');
// const contentHeaderElem = ref<HTMLDivElement | null>(null);
// const container = ref<HTMLDivElement | null>(null);

// const heightOfContent = ref('100%');

// provide(widthOfContentKey, widthOfContent);

// const { isSmallScreen } = useSmallScreen();
//
// const getHeightOfContent = () => {
//   if (!contentHeaderElem.value) return;
//   if (props.minimizeContainer || isSmallScreen.value) {
//     if (container.value) {
//       container.value.style.removeProperty('height');
//     }
//     return;
//   }
//   let height = window.innerHeight;
//
//   const navbar = document.getElementsByTagName('header')[0] ?? 48;
//
//   height -= navbar.offsetHeight;
//   height -= contentHeaderElem.value.clientHeight;
//   if (props.heightReduction) {
//     height -= props.heightReduction;
//   }
//   // height -= 40;
//   if (!props.fullSize && height > 400) {
//     height = height * 0.5;
//   }
//   heightOfContent.value = height + 'px;';
//
//   try {
//     if (container.value.parentElement.parentElement.classList.contains('h-full')) {
//       const otherHeight =
//         container.value.parentElement.parentElement.clientHeight - contentHeaderElem.value.clientHeight;
//       heightOfContent.value = otherHeight + 'px;';
//     }
//   } catch (__) {}
// };
//
// const getWidthOfContent = () => {
//   if (!contentHeaderElem.value) return;
//   console.log(contentHeaderElem.value);
//   widthOfContent.value = contentHeaderElem.value.clientWidth;
//   heightOfHeader.value = `${contentHeaderElem.value.clientHeight}px`;
//   getHeightOfContent();
// };
//
// onMounted(() => {
//   window.addEventListener('resize', getWidthOfContent);
//   [0, 50, 100, 500].forEach((num) => {
//     setTimeout(() => {
//       getWidthOfContent();
//     }, num);
//   });
// });
//
// onBeforeUnmount(() => {
//   window.removeEventListener('resize', getWidthOfContent);
// });

const container = useTemplateRef<HTMLDivElement>('container');

const updateSize = () => {
  if (!container.value) return;

  const screenHeight = window.innerHeight;

  const main = document.querySelector('main');

  const firstHeight = screenHeight - main?.getBoundingClientRect().top;

  const wrapperElem = document.querySelector(injectElement);

  if (wrapperElem) {
    let totalHeight: number;

    if (props.fullSize) {
      if (firstHeight > wrapperElem.offsetHeight && injectElement === 'main') {
        totalHeight = firstHeight;
      } else {
        totalHeight = screenHeight > wrapperElem.offsetHeight ? wrapperElem.offsetHeight : firstHeight;
      }
      container.value.style.height = `${totalHeight}px`;
    }
  }
};

let isCreated = false;

watch(container, (el) => {
  if (el && !isCreated) {
    isCreated = true;
    updateSize();
  }
});

onMounted(async () => {
  updateSize();
  setTimeout(() => {
    updateSize();
  }, 250);
});

const addKBLinkToUnderTitle = (tab) => {
  if (!tab) return null;
  const kbLink = getKey(tab, 'kbLink');
  if (!kbLink) return tab.description;
  return `${tab.description} Learn more <a href='${kbLink}' target='_blank'>HERE</a>.`;
};
</script>

<template>
  <div
    ref="container"
    class="container-wrapper relative flex flex-col overflow-hidden"
    :class="wrapperClasses">
    <div>
      <ContentHeader
        :loading="loading"
        :title="title"
        :title-info="titleInfo"
        :super-header="superHeader"
        :teleport-id="teleportHeaderId"
        :with-padding-bottom="withPaddingBottom"
        :is-page-header="true"
        :header-size="headerSize"
        :under-title="
          underTitle
            ? underTitle
            : pageTabsStore && pageTabsStore.activeTab
              ? addKBLinkToUnderTitle(pageTabsStore.activeTab)
              : null
        "
        :with-back-button="withBackButton"
        :actions="actions"
        :actions-as-buttons="actionsAsButtons"
        @back="backAction ? backAction() : pageTabsStore.goBack()">
        <template
          v-if="$slots.afterTitle"
          #afterTitle>
          <slot name="afterTitle" />
        </template>
        <template
          v-if="$slots.underTitle"
          #underTitle>
          <slot name="underTitle" />
        </template>
      </ContentHeader>
    </div>

    <div v-if="showTabs && pageTabsStore.activeTab?.tabs?.length">
      <section class="flex gap-edge bg px-edge">
        <div
          v-for="tab in pageTabsStore.activeTab?.tabs"
          :key="tab.key"
          :dusk="`tab-${pageTabsStore.activeTab.key}-${tab.key}`"
          :class="
            pageTabsStore.activeSubTab?.key === tab.key
              ? 'min-w-fit border-b-2 border-highlight text'
              : 'truncate text-soft'
          "
          :title="tab.title"
          class="h-full cursor-pointer select-none px-1 pb-edge-1/4 text-sm capitalize hover:min-w-fit hover:text-clip hover:text lg:text-lg"
          @click="pageTabsStore.setActiveSubTab(tab)">
          {{ tab.title }}
        </div>
      </section>
    </div>

    <div
      id="container-content"
      class="flex flex-1 flex-col gap-edge overflow-auto bg-content-main"
      :class="contentSlotClasses + (stripedChildren && !emptyMessage ? ' [&_.main-header:hover]:bg-row-hover' : '')">
      <slot :sub-tab="pageTabsStore.activeSubTab" />
      <EmptyStateFullPage
        v-if="emptyMessage && !loading && firstLoad"
        :icon="emptyIcon ? emptyIcon : preIcon"
        :description="emptyMessage"
        :button-text="emptyAction ? emptyButtonActionText : null"
        :button-function="emptyAction" />
      <div
        v-if="!firstLoad && loading"
        class="mt-[64px] text-center text-5xl text-soft">
        <i class="fa fa-fw fa-circle-o-notch fa-spin" /> <br />
        <span class=""> Loading </span>
      </div>
    </div>
  </div>
</template>
