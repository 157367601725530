<script lang="ts" setup>
import { nextTick, onMounted, ref } from 'vue';
import { useInfiniteScroll, useVModel } from '@vueuse/core';
import { router, usePage } from '@inertiajs/vue3';
import BaseSlideout from '@/components/Base/BaseSlideout.vue';
import { useToast } from 'vue-toastification';
import { useSmallScreen } from '@/composables/use-small-screen';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { NotificationResource } from '@/types/notifications';
import TaskSlideout from '@/components/Tasks/TaskSlideout.vue';

type Props = {
  isOpen: boolean;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  'update:isOpen': [value: boolean];
}>();

const { isSmallScreen } = useSmallScreen();

const open = useVModel(props, 'isOpen', emit);

const showSlideoutTask = ref(null);

const showList = ref(false);
const target = ref(null);
const perPageNotifications = ref(10);
const unread = ref(0);
const currentPage = ref(1);
const notifications = ref<NotificationResource[]>([]);
const total = ref(0);
const lastPage = ref(0);
const loading = ref(false);
const echoConnected = ref(false);

if (window.Notification && Notification.permission !== 'denied' && Notification.permission !== 'granted') {
  Notification.requestPermission(() => {
    new Notification('Allow us to send notifications', {
      body: 'Can we send you notifications in the browser?',
    });
  });
}

const listenForBroadcast = () => {
  if (echoConnected.value) return;
  echoConnected.value = true;
  Echo.private(`App.User.${usePage().props.auth.user.id}`).notification((notification) => {
    notification.friendly_date = 'Just now';
    unread.value += 1;
    notifications.value.unshift(notification);
    if (notification.action_text) {
      useToast().info(notification.action_text, {
        onClick: () => {
          notificationClick(notification, null);
        },
      });
    }
  });
};

onMounted(async () => {
  listenForBroadcast();
  setTimeout(async () => {
    const { data: count } = await axios.get('/api/notifications/unread-count');
    unread.value = count.count;
    if (window.todesktop) {
      window.todesktop.app.setBadgeCount(count.count);
    }
  }, 2000);
});

const getNotifications = async () => {
  loading.value = true;
  const { data } = await axios.get('/api/notifications', {
    params: {
      page: currentPage.value,
      per_page: 25,
    },
  });
  loading.value = false;
  if (currentPage.value === 1) {
    notifications.value = data.data;
  } else {
    for (let i = 0; i < data.data.length; i++) {
      notifications.value.push(data.data[i]);
    }
  }
  total.value = data.meta.total;
  lastPage.value = data.meta.last_page;
};
const markAllRead = async () => {
  if (unread.value > 0) {
    await axios.delete('/api/notifications');
    unread.value = 0;
    if (window.todesktop) {
      window.todesktop.app.setBadgeCount(0);
    }
  }
};

const loadMore = (autoLoading = false) => {
  if (loading.value) return;
  if (currentPage.value < lastPage.value) {
    currentPage.value++;
    getNotifications();
  } else {
    loading.value = false;
    if (!autoLoading) {
      useToast().warning('Everything already loaded');
    }
  }
};

const notificationClick = async (notification: NotificationResource, event: MouseEvent | null) => {
  open.value = false;
  switch (notification.action_type) {
    case 'App\\Models\\Tasks\\Task': {
      showSlideoutTask.value = null;
      await nextTick();
      showSlideoutTask.value = {
        uuid: notification.action_id,
      };
      return;
    }
    default: {
      break;
    }
  }

  if (notification.action_url) {
    if (event && event.ctrlKey) {
      window.open(notification.action_url);
    } else {
      router.visit(notification.action_url);
    }
  }
};

const openNotifications = () => {
  open.value = false;
  currentPage.value = 1;
  getNotifications();
  nextTick(() => {
    open.value = true;
  });
  markAllRead();
};

useInfiniteScroll(
  target,
  async () => {
    // load more
    if (currentPage.value < lastPage.value) {
      currentPage.value += 1;
      await getNotifications();
    }
  },
  { distance: 10 }
);

defineOptions({
  inheritAttrs: false,
});

const clickableNotification = (notification: NotificationResource) => {
  switch (notification.action_type) {
    case 'App\\Models\\Tasks\\Task': {
      return true;
    }
    default: {
      break;
    }
  }
  return notification.action_url !== null;
};

const height = CSS.supports('height: 100dvh') ? '100dvh' : '100vh';
</script>

<template>
  <div
    v-if="!isSmallScreen"
    v-bind="$attrs">
    <VButton
      dusk="navbar-notification-button"
      icon="fa-bell fa-regular"
      size="sm"
      type="default"
      :tool-tip-text="'You have ' + (unread === 0 ? 'no' : unread) + ' unread notifications'"
      :title="unread + ' '"
      class="border-transparent bg-transparent"
      @click="openNotifications" />
  </div>

  <BaseSlideout
    v-model="open"
    tiny
    :base-z-index="201"
    header-text="Notifications">
    <template #header-text>
      <div class="flex gap-edge-1/2">
        <IconWithLoading
          :loading="loading"
          classes="text-3xl"
          icon="fa-bell" />
        <h1>Notifications</h1>
      </div>
    </template>
    <div class="h-full bg-content-main">
      <div v-if="loading">
        <div class="pt-[50px] text-center">
          <h2>
            <i class="fa fa-fw fa-circle-o-notch fa-spin" />
          </h2>
        </div>
      </div>

      <div
        v-else-if="!notifications.length"
        class="p-edge pt-[50px]">
        <div class="rounded bg p-edge text-center italic">
          <p>You don't have any notifications yet</p>
        </div>
      </div>
      <div
        v-else
        ref="target"
        :style="`height: calc(${height} - 85px)`"
        class="flex flex-col space-y-edge overflow-auto p-edge">
        <div
          v-for="(notification, index) in notifications"
          :key="`${notification.id}_${index}`"
          :ref="'notification_' + notification.id"
          class="rounded bg p-edge shadow-lg"
          @click="notificationClick(notification, $event)">
          <p :class="'notification ' + (clickableNotification(notification) ? ' cursor-pointer hover:underline' : '')">
            {{ notification.action_text }}
          </p>
          <div
            :title="notification.date"
            class="text-xs text-soft">
            {{ notification.friendly_date }}
          </div>
        </div>

        <div
          v-if="(notifications.length && currentPage >= lastPage) || true"
          class="pt-edge text-center italic"
          @click.prevent>
          All Loaded
        </div>
      </div>
    </div>
  </BaseSlideout>

  <TaskSlideout
    v-if="showSlideoutTask"
    :initial-task="showSlideoutTask"
    @closed="showSlideoutTask = null" />
</template>
