<script setup lang="ts">
import { useToast } from 'vue-toastification';
import TextInput from '@/components/Inputs/TextInput.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import ToggleWithInput from '@/components/Inputs/Components/ToggleWithInput.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import CrudSlideout from '@/components/Slideout/CrudSlideout.vue';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import { nextTick, ref } from 'vue';
import SearchSelectFloatingWindow from '@/components/Inputs/Components/SearchSelectFloatingWindow.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { getKey } from '@/util/globals';
import BoxContainer from '@/components/Elements/BoxContainer.vue';

type Props = {
  model: 'Festival' | 'Group';
  modelId: number;
  initPerformance?: { name: string | null } | null;
  canCreateMore?: boolean | null;
  partnerTypes: object[];
  performanceTypes: object[];
};

const props = withDefaults(defineProps<Props>(), {
  initPerformance: null,
  canCreateMore: true,
  partnerTypes: () => [],
});

const emits = defineEmits(['created', 'fetchList']);

const performance = ref(null);
const modalOpen = ref(null);
const usePseudonym = ref(null);
const toast = useToast();

const showCreateModal = () => {
  performance.value = {
    name: getKey(props.initPerformance, 'name', ''),
    pseudonym: null,
    performance_type_id: null,
    confirmed: true,
    partner_companies: [],
  };
  modalOpen.value = false;
  nextTick(() => {
    modalOpen.value = true;
  });
};
showCreateModal();

const createPerformance = async (emitAfterCreating = true) => {
  const { data } = await axios.post('/api/performances', {
    model_type: `App\\${props.model}`,
    model_id: props.modelId,
    name: performance.value.name,
    performance_type_id: performance.value.performance_type_id,
    confirmed: performance.value.confirmed,
    pseudonym: performance.value.pseudonym,
  });
  performance.value.id = data.id;
  performance.value.slug = data.slug;
  for (let i = 0; i < performance.value.partner_companies.length; i++) {
    await addPartnerCompany(performance.value.partner_companies[i]);
  }

  toast.success('Created');
  if (emitAfterCreating) {
    modalOpen.value = false;
    emits('created', data);
  } else {
    showCreateModal();
    emits('fetchList');
  }
};
const addPartnerCompany = async (partnerCompany) => {
  if (partnerCompany.id === null) {
    await axios.post(`/api/performances/${performance.value.id}/partners/company/new`, {
      name: partnerCompany.name,
      partner_type_id: partnerCompany.partner_type_id,
    });
  } else {
    await axios.post(`/api/performances/${performance.value.id}/partners/company`, {
      partner_company_id: partnerCompany.id,
    });
  }
};

const pageX = ref(null);
const pageY = ref(null);
const selectedPartnerTypeId = ref(null);
const openDropdown = (partnerTypeId, event) => {
  pageX.value = null;
  pageY.value = null;
  selectedPartnerTypeId.value = null;
  // open.value = false;
  nextTick(() => {
    pageX.value = event.target.getBoundingClientRect().x - 320;
    pageY.value = event.target.getBoundingClientRect().y + 35;
    selectedPartnerTypeId.value = partnerTypeId;
  });
};
</script>

<template>
  <CrudSlideout
    v-if="modalOpen"
    small
    :disabled="performance.name.length < 1 || performance.name.length > 190"
    title="Create new Performance "
    @create="createPerformance"
    @closed="modalOpen = !modalOpen">
    <div class="h-full space-y-edge bg-content-main p-edge">
      <BoxContainer>
        <div class="form-layout grid-cols-2">
          <TextInput
            v-model="performance.name"
            set-focus
            label="Name" />

          <VSelect
            v-model="performance.performance_type_id"
            label="Performance Type"
            nullable
            nullable-display-text="N/A"
            :options="performanceTypes" />

          <ToggleWithInput
            v-model="usePseudonym"
            class="col-span-2"
            :can-edit="true"
            label="Use Pseudonym"
            title="Would you like to use a pseudonym for this performance? Is so, all printed lists of travels, accommodations and such will refer to this name.">
            <template #input>
              <TextInput
                v-model="performance.pseudonym"
                :disabled="!usePseudonym"
                :can-edit="usePseudonym"
                label="Pseudonym" />
            </template>
          </ToggleWithInput>
          <SettingCheck
            v-model="performance.confirmed"
            label="Confirmed"
            title="If enabled, this performance will be created as confirmed." />
        </div>
      </BoxContainer>
      <BoxContainer
        v-for="(type, index) in partnerTypes"
        :content-padding="false"
        :padding-bottom="
          performance.partner_companies.filter(function (c) {
            return c.partner_type_id === type.id;
          }).length > 0
        "
        header-size="h3"
        :title="type.title"
        :actions="[
          {
            icon: 'fa-plus',
            title: 'Add',
            action: ($event) => {
              openDropdown(type.id, $event);
            },
          },
        ]">
        <SearchSelectFloatingWindow
          v-if="pageX && pageY && selectedPartnerTypeId === type.id"
          url="/api/partners/companies"
          :placeholder="'Search For ' + type.title"
          :debounce-time="500"
          can-create
          :params="{
            model_type: 'App\\' + model,
            model_id: modelId,
            partner_type_ids: [type.id],
            unfiltered: 'true',
          }"
          :page-y="pageY"
          :page-x="pageX"
          @closed="pageX = null"
          @create="
            performance.partner_companies.push({ id: null, name: $event, partner_type_id: type.id, editing: false })
          "
          @selected="
            performance.partner_companies.push({
              id: $event.id,
              name: $event.name,
              partner_type_id: type.id,
              editing: false,
            })
          " />

        <VTable
          v-if="performance.partner_companies.length > 0"
          edge-to-edge>
          <VTableRow
            v-for="company in performance.partner_companies.filter(function (c) {
              return c.partner_type_id === type.id;
            })"
            :key="company.id"
            clickable>
            <VTableCell
              v-if="company.id !== null"
              main-cell>
              {{ company.name }}
            </VTableCell>
            <VTableCell
              v-if="company.id === null"
              main-cell>
              <TextInput
                v-model="company.name"
                is-hidden />
            </VTableCell>
            <VTableCell style="width: 60px">
              <VButton
                size="sm"
                icon="fa-trash"
                @click="performance.partner_companies.splice(performance.partner_companies.indexOf(company), 1)" />
            </VTableCell>
          </VTableRow>
        </VTable>
      </BoxContainer>
    </div>
    <template
      v-if="canCreateMore"
      #footer>
      <div class="flex h-full items-center justify-between p-edge">
        <VButton
          title="Create & Add Another"
          type="primary"
          :disabled="performance.name.length < 1 || performance.name.length > 190"
          @click="createPerformance(false)" />
        <VButton
          type="primary"
          icon="fa-save"
          emphasized
          :disabled="performance.name.length < 1 || performance.name.length > 190"
          title="Create"
          @click="createPerformance" />
      </div>
    </template>
  </CrudSlideout>
</template>
