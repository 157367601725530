<script setup lang="ts">
import { computed, ref } from 'vue';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import {
  fetchNotificationPreferences,
  fetchNotificationTypes,
  NotificationPreference,
  NotificationType,
  patchNotificationPreference,
  postNotificationPreference,
} from '@/services/api-notifications';
import { usePage } from '@inertiajs/vue3';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import CheckBox from '@/components/Icons/CheckBox.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import { useToast } from 'vue-toastification';

const types = await fetchNotificationTypes();
const preferences = await fetchNotificationPreferences();

const loadingId = ref('');

const showProjectLeaderOptions = computed(() => usePage().props.auth.user.groups.some((group) => group.admin));

type INotification = NotificationType & {
  preference: NotificationPreference | null;
};

const getNotificationsForType = (category: string) => {
  const array = [] as INotification[];

  types.forEach((type) => {
    if (type.for_project_leaders_only && !showProjectLeaderOptions.value) return;

    if (category !== type.category) return;

    const preference = preferences.find((item) => item.notification_type_id === type.id);

    const setting = { ...type, preference: null };
    if (preference) {
      setting.preference = { ...preference };
    }

    array.push(setting);
  });

  return array.sort((a, b) => a.order - b.order);
};

const groupNotifications = ref(getNotificationsForType('group'));
const eventNotifications = ref(getNotificationsForType('event'));
const eventInvitesNotifications = ref(getNotificationsForType('Event invites'));
const contactsNotifications = ref(getNotificationsForType('Contacts'));
const userInvitesNotifications = ref(getNotificationsForType('User Invites'));
const shiftNotifications = ref(getNotificationsForType('shift'));
const taskNotifications = ref(getNotificationsForType('tasks'));

const hoursBefore = [
  { name: 'One day before', id: '24' },
  { name: 'Two days before', id: '48' },
  { name: 'Three days before', id: '72' },
  { name: 'One Week before', id: '168' },
  { name: 'Four Weeks before', id: `${168 * 4}` },
];

const addNotification = async (n: INotification) => {
  if (n.preference) return null;

  const data = await postNotificationPreference({
    notification_type_id: n.id,
    at_all: n.default_enabled,
    push: n.default_send_push,
    email: n.default_send_email,
    hours_before: n.default_hours_before,
  });

  n.preference = { ...data };
  return data;
};

const updateNotification = async ({ id, at_all, email, push, hours_before }, preference) => {
  if (id) {
    let newPushValue = false;
    let newEmailValue = false;
    let newHoursBeforeValue = null;
    if (at_all !== undefined) {
      newPushValue = !!at_all;
      newHoursBeforeValue = preference.hours_before;
    } else {
      newPushValue = push ?? preference.push;
      newEmailValue = email ?? preference.email;
      newHoursBeforeValue = hours_before === undefined ? preference.hours_before : hours_before;
    }
    try {
      const newPreferences = {
        id,
        at_all: at_all ?? preference.at_all,
        email: newEmailValue,
        push: newPushValue,
        hours_before: newHoursBeforeValue,
      };
      await patchNotificationPreference(newPreferences);
      preference.at_all = at_all ?? preference.at_all;
      preference.email = newEmailValue;
      preference.push = newPushValue;
      preference.hours_before = newHoursBeforeValue;
      const msg = () => {
        if (at_all === false) {
          return 'Notifications off';
        }
        if (at_all === true) {
          return 'Notifications on';
        }
        if (push === true) {
          return 'Push notification on';
        }
        if (push === false) {
          return 'Push notification off';
        }
        if (email === true) {
          return 'Email notification on';
        }
        if (email === false) {
          return 'Email notification off';
        }
        if (hours_before) {
          return 'Time updated';
        }
        if (hours_before === '' || hours_before === null) {
          return 'Time updated';
        }
        return 'Error';
      };
      useToast().success(msg());
    } catch (e) {
      console.error(e);
    }
  }
};

const updateSetting = (n: INotification) => {
  if (!n.preference?.at_all && n.preference?.at_all !== false && !n.preference?.id) {
    addNotification(n);
  } else if (n.preference) {
    updateNotification({ id: n.preference.id, at_all: !n.preference.at_all }, n.preference);
  }
};

const updatePush = (preference: NotificationPreference) => {
  if (!preference?.at_all) return;

  updateNotification({ id: preference.id, push: !preference.push }, preference);
};

const updateEmail = (preference: NotificationPreference) => {
  if (!preference?.at_all) return;

  updateNotification({ id: preference.id, email: !preference.email }, preference);
};

const initNotifications = async (notifications: INotification[]) => {
  if (notifications.length === 0) return;

  const promises = notifications.filter((n) => !n.preference).map((notification) => addNotification(notification));

  if (!promises.length) return;

  loadingId.value = notifications[0].category;

  console.log(loadingId.value);

  try {
    await Promise.all(promises);
    loadingId.value = '';
  } catch (e) {
    useToast().error('something went wrong');
    throw e;
  }
};

const updateHoursBefore = (preference: NotificationPreference, hours: string) => {
  if (!preference.at_all) return;

  updateNotification({ id: preference.id, hours_before: hours }, preference);
};
</script>

<template>
  <div class="-m-edge h-full space-y-edge rounded-b bg-content-main p-edge">
    <BoxContainer
      :openable="true"
      :model-value="false"
      :content-padding="false"
      header-size="h3"
      title="Group"
      @opened="initNotifications(groupNotifications)">
      <VTable
        table-border="horizontal"
        :edge-to-edge="true">
        <template #head>
          <VTableRow :head="true">
            <VTableCell></VTableCell>
            <VTableCell :component-type="'th'"> Enabled</VTableCell>
            <VTableCell :component-type="'th'"> Push</VTableCell>
            <VTableCell :component-type="'th'"> Email</VTableCell>
          </VTableRow>
        </template>
        <VTableRow
          v-for="n in groupNotifications"
          :key="n.id">
          <VTableCell :main-cell="true">{{ n.title }}</VTableCell>
          <VTableCell style="width: 69px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :can-edit="!!n.preference"
                :model-value="n.preference?.at_all"
                @update:model-value="updateSetting(n)" />
            </div>
          </VTableCell>
          <VTableCell style="width: 39px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :model-value="n.preference?.push"
                @update:model-value="updatePush(n.preference)" />
            </div>
          </VTableCell>
          <VTableCell style="width: 69px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :model-value="n.preference?.email"
                :can-edit="n.preference?.at_all"
                @update:model-value="updateEmail(n.preference)" />
            </div>
          </VTableCell>
        </VTableRow>
      </VTable>
    </BoxContainer>

    <BoxContainer
      :openable="true"
      :model-value="false"
      :content-padding="false"
      header-size="h3"
      title="Event"
      @opened="initNotifications(eventNotifications)">
      <VTable
        table-border="horizontal"
        :edge-to-edge="true">
        <template #head>
          <VTableRow :head="true">
            <VTableCell></VTableCell>
            <VTableCell></VTableCell>
            <VTableCell :component-type="'th'"> Enabled</VTableCell>
            <VTableCell :component-type="'th'"> Push</VTableCell>
            <VTableCell :component-type="'th'"> Email</VTableCell>
          </VTableRow>
        </template>
        <VTableRow
          v-for="n in eventNotifications"
          :key="n.id">
          <VTableCell :main-cell="true">{{ n.title }}</VTableCell>
          <VTableCell style="width: 250px">
            <div>
              <VSelect
                v-if="n.has_time"
                :model-value="n.preference?.hours_before ?? null"
                :options="hoursBefore"
                nullable
                :is-hidden="true"
                :can-edit="n.preference?.at_all && loadingId !== n.category"
                nullable-display-text="Always send Notifications"
                @update:model-value="updateHoursBefore(n.preference, $event)" />
            </div>
          </VTableCell>
          <VTableCell style="width: 69px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :model-value="n.preference?.at_all"
                :can-edit="!!n.preference"
                @update:model-value="updateSetting(n)" />
            </div>
          </VTableCell>
          <VTableCell style="width: 39px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :model-value="n.preference?.push"
                :can-edit="n.preference?.at_all"
                @update:model-value="updatePush(n.preference)" />
            </div>
          </VTableCell>
          <VTableCell style="width: 69px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :model-value="n.preference?.email"
                :can-edit="n.preference?.at_all"
                @update:model-value="updateEmail(n.preference)" />
            </div>
          </VTableCell>
        </VTableRow>
      </VTable>
    </BoxContainer>

    <BoxContainer
      :openable="true"
      :model-value="false"
      :content-padding="false"
      header-size="h3"
      title="Event Invites"
      @opened="initNotifications(eventInvitesNotifications)">
      <VTable
        table-border="horizontal"
        :edge-to-edge="true">
        <template #head>
          <VTableRow :head="true">
            <VTableCell></VTableCell>
            <VTableCell :component-type="'th'"> Enabled</VTableCell>
            <VTableCell :component-type="'th'"> Push</VTableCell>
            <VTableCell :component-type="'th'"> Email</VTableCell>
          </VTableRow>
        </template>
        <VTableRow
          v-for="n in eventInvitesNotifications"
          :key="n.id">
          <VTableCell :main-cell="true">{{ n.title }}</VTableCell>
          <VTableCell style="width: 69px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :model-value="n.preference?.at_all"
                :can-edit="!!n.preference"
                @update:model-value="updateSetting(n)" />
            </div>
          </VTableCell>
          <VTableCell style="width: 39px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :model-value="n.preference?.push"
                @update:model-value="updatePush(n.preference)" />
            </div>
          </VTableCell>
          <VTableCell style="width: 69px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :model-value="n.preference?.email"
                @update:model-value="updateEmail(n.preference)" />
            </div>
          </VTableCell>
        </VTableRow>
      </VTable>
    </BoxContainer>

    <BoxContainer
      :openable="true"
      :model-value="false"
      :content-padding="false"
      header-size="h3"
      title="Contacts"
      @opened="initNotifications(contactsNotifications)">
      <VTable
        table-border="horizontal"
        :edge-to-edge="true">
        <template #head>
          <VTableRow :head="true">
            <VTableCell></VTableCell>
            <VTableCell :component-type="'th'"> Enabled</VTableCell>
            <VTableCell :component-type="'th'"> Push</VTableCell>
            <VTableCell :component-type="'th'"> Email</VTableCell>
          </VTableRow>
        </template>
        <VTableRow
          v-for="n in contactsNotifications"
          :key="n.id">
          <VTableCell :main-cell="true">{{ n.title }}</VTableCell>
          <VTableCell style="width: 69px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :model-value="n.preference?.at_all"
                :can-edit="!!n.preference"
                @update:model-value="updateSetting(n)" />
            </div>
          </VTableCell>
          <VTableCell style="width: 39px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :model-value="n.preference?.push"
                @update:model-value="updatePush(n.preference)" />
            </div>
          </VTableCell>
          <VTableCell style="width: 69px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :model-value="n.preference?.email"
                @update:model-value="updateEmail(n.preference)" />
            </div>
          </VTableCell>
        </VTableRow>
      </VTable>
    </BoxContainer>

    <BoxContainer
      :openable="true"
      :model-value="false"
      :content-padding="false"
      header-size="h3"
      title="User Invites"
      @opened="initNotifications(userInvitesNotifications)">
      <VTable
        table-border="horizontal"
        :edge-to-edge="true">
        <template #head>
          <VTableRow :head="true">
            <VTableCell></VTableCell>
            <VTableCell :component-type="'th'"> Enabled</VTableCell>
            <VTableCell :component-type="'th'"> Push</VTableCell>
            <VTableCell :component-type="'th'"> Email</VTableCell>
          </VTableRow>
        </template>
        <VTableRow
          v-for="n in userInvitesNotifications"
          :key="n.id">
          <VTableCell :main-cell="true">{{ n.title }}</VTableCell>
          <VTableCell style="width: 69px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :model-value="n.preference?.at_all"
                :can-edit="!!n.preference"
                @update:model-value="updateSetting(n)" />
            </div>
          </VTableCell>
          <VTableCell style="width: 39px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :model-value="n.preference?.push"
                @update:model-value="updatePush(n.preference)" />
            </div>
          </VTableCell>
          <VTableCell style="width: 69px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :model-value="n.preference?.email"
                @update:model-value="updateEmail(n.preference)" />
            </div>
          </VTableCell>
        </VTableRow>
      </VTable>
    </BoxContainer>

    <BoxContainer
      :openable="true"
      :model-value="false"
      :content-padding="false"
      header-size="h3"
      title="Shift"
      @opened="initNotifications(shiftNotifications)">
      <VTable
        table-border="horizontal"
        :edge-to-edge="true">
        <template #head>
          <VTableRow :head="true">
            <VTableCell></VTableCell>
            <VTableCell :component-type="'th'"> Enabled</VTableCell>
            <VTableCell :component-type="'th'"> Push</VTableCell>
            <VTableCell :component-type="'th'"> Email</VTableCell>
          </VTableRow>
        </template>
        <VTableRow
          v-for="n in shiftNotifications"
          :key="n.id">
          <VTableCell :main-cell="true">{{ n.title }}</VTableCell>
          <VTableCell style="width: 69px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :model-value="n.preference?.at_all"
                :can-edit="!!n.preference"
                @update:model-value="updateSetting(n)" />
            </div>
          </VTableCell>
          <VTableCell style="width: 39px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :model-value="n.preference?.push"
                @update:model-value="updatePush(n.preference)" />
            </div>
          </VTableCell>
          <VTableCell style="width: 69px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :model-value="n.preference?.email"
                @update:model-value="updateEmail(n.preference)" />
            </div>
          </VTableCell>
        </VTableRow>
      </VTable>
    </BoxContainer>

    <BoxContainer
      :openable="true"
      :model-value="false"
      :content-padding="false"
      header-size="h3"
      title="Tasks"
      @opened="initNotifications(taskNotifications)">
      <VTable
        table-border="horizontal"
        :edge-to-edge="true">
        <template #head>
          <VTableRow :head="true">
            <VTableCell></VTableCell>
            <VTableCell :component-type="'th'"> Enabled</VTableCell>
            <VTableCell :component-type="'th'"> Push</VTableCell>
            <VTableCell :component-type="'th'"> Email</VTableCell>
          </VTableRow>
        </template>
        <VTableRow
          v-for="n in taskNotifications"
          :key="n.id">
          <VTableCell :main-cell="true">{{ n.title }}</VTableCell>
          <VTableCell style="width: 69px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :model-value="n.preference?.at_all"
                :can-edit="!!n.preference"
                @update:model-value="updateSetting(n)" />
            </div>
          </VTableCell>
          <VTableCell style="width: 39px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :model-value="n.preference?.push"
                @update:model-value="updatePush(n.preference)" />
            </div>
          </VTableCell>
          <VTableCell style="width: 69px">
            <div>
              <CheckBox
                :loading="loadingId === n.category"
                :model-value="n.preference?.email"
                @update:model-value="updateEmail(n.preference)" />
            </div>
          </VTableCell>
        </VTableRow>
      </VTable>
    </BoxContainer>
  </div>
</template>
