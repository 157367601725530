<script setup lang="ts">
import { HoldEventResource } from '@/types/hold-events';
import CrudModal from '@/components/Modals/CrudModal.vue';
import { ref } from 'vue';
import { getItemFromArrayBasedOnId, sortArrayBy } from '@/util/globals';
import { useToast } from 'vue-toastification';
import { InviteResource } from '@/types/invite';
import { formatStampAsHumanReadableDate, sortByAttribute, timeStampsAreSame } from '@/util/timeFunctions';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import DisplayBadge from '@/components/Display/DisplayBadge.vue';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';

type Props = {
  invite: InviteResource;
  initialDate?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  initialDate: null,
});

const emit = defineEmits<{
  (event: 'accepted'): void;
  (event: 'closed'): void;
}>();

const modalOpen = ref(true);
const loading = ref(false);
const selectedDate = ref(null);
const selectedDates = ref(new Set([]));
const holdEvent = ref<HoldEventResource | null>(props.invite.hold_event);

const acceptHoldEvent = async () => {
  if (!selectedDate.value || !holdEvent.value) return;

  let date = null;
  let allDates = sortByAttribute([...selectedDates.value])
    .map(function (d) {
      if (timeStampsAreSame(d, props.invite.event?.start_date, 'day')) {
        date = d;
        return null;
      }
      return d;
    })
    .filter((i) => i !== null);

  if (!date) {
    date = allDates[0];
    allDates = [...allDates].slice(1);
  }

  if (!date) {
    return;
  }

  loading.value = true;
  await axios.post(`/api/hold-events/${holdEvent.value.id}/accept`, {
    date: date,
    duplicate_to_dates: allDates,
  });

  useToast().info('Holds accepted and Events Created.');
  setTimeout(() => {
    loading.value = false;
    emit('accepted');
  }, 1000);
};

const setup = () => {
  if (!props.invite || !props.invite.hold_event || !holdEvent.value) {
    modalOpen.value = false;
    emit('closed');
    return;
  }
  if (props.initialDate) {
    const item = getItemFromArrayBasedOnId(props.initialDate, holdEvent.value.hold_event_date_pivots, null, 'date');
    if (item) {
      selectedDates.value.add(props.initialDate);
      selectedDate.value = props.initialDate;
      return;
    }
  }
  const possibleDates = sortArrayBy(
    holdEvent.value.hold_event_date_pivots.filter((p) => p.possible),
    'primary',
    false
  );
  if (possibleDates.length > 0) {
    selectedDates.value.add(possibleDates[0].date);
    selectedDate.value = possibleDates[0].date;
  } else if (holdEvent.value.hold_event_date_pivots.length > 0) {
    selectedDate.value = holdEvent.value.hold_event_date_pivots[0].date;
    selectedDates.value.add(holdEvent.value.hold_event_date_pivots[0].date);
  } else {
    useToast().info('No Potential Dates Given');
  }
};
setup();
</script>
<template>
  <CrudModal
    v-if="modalOpen && holdEvent"
    :create-button-text="'Confirm ' + selectedDates.size + ' dates'"
    :disabled="selectedDates.size === 0"
    small
    :loading="loading"
    title="Confirm Hold"
    @create="acceptHoldEvent"
    @closed="[$emit('closed'), (modalOpen = false)]">
    <div :class="{ 'opacity-50': loading }">
      <div>Please confirm one or more of these dates</div>
      <div class="mt-edge">
        <InputLabel
          super-text
          label="Available Dates" />
        <div class="flex flex-col gap-edge">
          <div
            v-for="pivot in holdEvent.hold_event_date_pivots"
            :key="pivot.date"
            :class="{ 'border-highlight': selectedDates.has(pivot.date) }"
            class="hover:border-textColor grid cursor-pointer grid-cols-[auto_70px] items-center gap-edge rounded border px-edge py-edge-1/2"
            @click="
              loading
                ? null
                : selectedDates.has(pivot.date)
                  ? selectedDates.delete(pivot.date)
                  : selectedDates.add(pivot.date)
            ">
            <div class="gap-2 flex items-center">
              <div>
                <SettingCheck
                  class="mt-[4px]"
                  :model-value="selectedDates.has(pivot.date)" />
              </div>

              {{ formatStampAsHumanReadableDate(pivot.date) }}
            </div>
            <div>
              <DisplayBadge
                v-if="pivot.primary"
                color="success"
                text="Primary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </CrudModal>
</template>
