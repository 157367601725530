<script setup lang="ts">
import VToggle from '@/components/Inputs/VToggle.vue';
import InfoIcon from '@/components/Icons/InfoIcon.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';

type Props = {
  title?: string | null;
  modelValue?: boolean;
  label?: string | null;
  labelTitle?: string | null;
  canEdit?: boolean;
  disabled?: boolean;
  vertical?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  title: null,
  modelValue: false,
  label: null,
  labelTitle: null,
  canEdit: true,
  disabled: false,
  vertical: false,
});

const emits = defineEmits<{
  'update:modelValue': [arg: boolean];
  clicked: [() => void];
}>();

const getColStyle = () => {
  let string = 'grid-template-columns: ';
  if (!props.vertical) {
    string += ' 50px ';
  }
  string += ' 1fr ';

  if (props.title) {
    string += ' 20px ';
  }
  return `${string};`;
};
</script>

<template>
  <div
    class="grid cursor-pointer grid-cols-[1fr_50px] items-center rounded p-edge-1/4 hover:bg [&_*]:mb-0"
    :class="disabled ? 'opacity-50' : 'cursor-pointer'"
    :style="getColStyle()"
    :title="title"
    @click="canEdit && !disabled ? [emits('update:modelValue', !modelValue), emits('clicked')] : null">
    <div
      :title="labelTitle"
      class="flex items-center gap-edge-1/2">
      <InputLabel
        v-if="vertical"
        class="[&_*]:font-headers [&_*]:font-semibold"
        :class="disabled ? ' ' : 'cursor-pointer'"
        :label="label" />
      <VToggle
        :model-value="modelValue"
        :label="null" />
    </div>
    <InputLabel
      v-if="!vertical"
      class="[&_*]:font-headers [&_*]:font-semibold"
      :class="disabled ? ' ' : 'cursor-pointer'"
      :label="label" />
    <InfoIcon :title="title" />
  </div>
</template>
