<script setup lang="ts">
import { ShiftResource } from '@/types/event';
import { InviteResource } from '@/types/invite';
import { ShiftTypeResource } from '@/types/shifts';
import moment from 'moment';
import { computed, nextTick, ref } from 'vue';
import { useToast } from 'vue-toastification';
import {
  cancelShift,
  canCheckIntoShift,
  getEventsForUserFromFullCalendarResource,
  getShiftsForUserFromFullCalendarResource,
  reinstateShift,
  resendShiftNotification,
  shiftStatus,
} from '@/helpers/shiftFunctions';
import { dateFormat, dateTimeFormat } from '@/variables/date-format';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import ShiftCheckModal from '@/components/Shifts/ShiftCheckModal.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import AuditsSidebar from '@/components/Audits/AuditsSidebar.vue';
import { auditableShiftFields } from '@/helpers/auditHelper';
import VButton from '@/components/Inputs/VButton.vue';
import CheckBox from '@/components/Icons/CheckBox.vue';
import { exchangeValuesOfObject, getKey, sortArrayBy } from '@/util/globals';
import { useSmallScreen } from '@/composables/use-small-screen';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import ShiftAssignAndReplacementModal from '@/components/Shifts/ShiftAssignAndReplacementModal.vue';
import type { Crew as CrewResource } from '@/views/Group/Show/GroupMembers.vue';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import { patchShift } from '@/services/api-shifts';
import DisplayBadge from '@/components/Display/DisplayBadge.vue';

type Props = {
  invite: InviteResource;
  crew: CrewResource[];
  shifts: ShiftResource[];
  shiftTypes: ShiftTypeResource[];
  canEdit: boolean;
  isPast: boolean;
  isRecurring: boolean;
  resourceId: string | null;
  groupId: number;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'fetch'): void;
  (event: 'openShiftModal', arg: typeof selectedShift.value | null): void;
}>();

const toast = useToast();
const { assertCertain } = useCertaintyModal();
const { isSmallScreen } = useSmallScreen();

const selectedShift = ref<{
  id?: number;
  title?: string | null;
  start: string | null;
  end: string | null;
  via_type: string;
  write?: boolean;
  approved?: boolean;
  in_timeline?: boolean;
  shift_type_id?: number | null;
  description?: string | null;
  via_id: number | null;
  invited_by?: number | null;
  user_id: number | null;
  initial_events: {
    id: number;
    name: string;
  }[];
} | null>(null);

const working = ref(false);
const showAcceptedShifts = ref(true);
const showDeclinedShifts = ref(true);
const showPendingShifts = ref(true);
const showCheckModal = ref(false);
const isCheckIn = ref(false);

const orderedShifts = computed(() => {
  return _.orderBy(
    _.orderBy(
      props.shifts.filter((s) => {
        if (s.accepted_at && !showAcceptedShifts.value) {
          return false;
        }
        if (s.declined_at && !showDeclinedShifts.value) {
          return false;
        }
        return !(!s.declined_at && !s.accepted_at && !showPendingShifts.value);
      }),
      'end'
    ),
    'start'
  );
});

const formattedShiftDate = (start: string, end: string) => {
  let string = '';
  if (!moment(start).isSame(props.invite.start, 'day')) {
    string = `${moment(start).format(dateFormat)} at `;
  }
  const diff = Math.abs(moment(start).diff(end, 'hours'));
  if (diff < 20) {
    return `${string + moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`;
  }
  return `${string + moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}(+${diff}H)`;
};

const openModal = () => {
  if (!props.invite.event) return;

  emit('openShiftModal', null);
};

const editShiftOnEvent = (shift: ShiftResource) => {
  if (!props.canEdit) return;

  selectedShift.value = {
    id: shift.id,
    start: shift.start,
    end: shift.end,
    write: shift.write,
    approved: shift.approved,
    title: shift.title ?? '',
    in_timeline: shift.in_timeline,
    shift_type_id: shift.shift_type_id,
    description: shift.description ?? '',
    via_type: shift.via_type ?? 'App\\Group',
    via_id: shift.via_id ?? null,
    user_id: shift.user_id ?? null,
    invited_by: shift.invited_by,
    for_sale: shift.for_sale,
    initial_events: shift.events?.map((e) => ({ id: e.id, name: e.name })) ?? [],
  };

  emit('openShiftModal', selectedShift.value);
};

const getShiftTypeTitle = (shift: ShiftResource) => {
  const index = _.findIndex(props.shiftTypes, (r) => r.id === shift.shift_type_id);
  if (index > -1) {
    return props.shiftTypes[index].title + (shift.title ? ` - ${shift.title}` : '');
  }
  return shift.title;
};

const promptCancelShift = async (shiftId: number) => {
  const wasCancelled = await cancelShift(shiftId);
  if (!wasCancelled) return;
  emit('fetch');
};

const promptReinstateShift = async (shiftId: number) => {
  const wasReinstated = await reinstateShift(shiftId);
  if (!wasReinstated) return;
  emit('fetch');
};

const createShiftOfType = async (type) => {
  await axios.post('/api/shifts/', {
    model_id: props.invite.invitable.id,
    model_type: 'App\\Group',

    shift_type_id: type.id,

    write: type.write,
    in_timeline: type.in_timeline,
    for_sale: type.for_sale,
    approved: true,

    start: type.start_minutes
      ? moment(props.invite.start).startOf('day').add(type.start_minutes, 'minutes').format(dateTimeFormat)
      : props.invite.start,
    end:
      type.start_minutes && type.duration
        ? moment(props.invite.start)
            .startOf('day')
            .add(type.start_minutes, 'minutes')
            .add(type.duration, 'minutes')
            .format(dateTimeFormat)
        : props.invite.end,

    user_id: null,
    via_id: type.via_id ? type.via_id : props.invite.invitable.id,
    via_type: type.via_type ? type.via_type : 'App\\Group',

    is_global: props.invite.recurring_original_id !== null,
    event_ids: [props.invite.event.id],
  });
  emit('fetch');
  toast.success(`Created "${type.title}"`);
};

const actions = computed(() => {
  if (!props.canEdit) return [];

  const shiftsNotForSale = props.shifts.filter((s) => !s.for_sale && s.user_id === null);

  const array = [];

  if (shiftsNotForSale.length > 0) {
    array.push({
      title: 'Mark ' + shiftsNotForSale.length + ' Up For Sale',
      icon: 'fa-cart-shopping fa-regular ',
      action: async () => {
        for (const s of shiftsNotForSale) {
          await markShiftUpForSale(s);
        }
        useToast().success(shiftsNotForSale.length + ' Shifts Up For Sale');
        emit('fetch');
      },
    });
  }

  array.push({
    title: 'Create Shift',
    icon: 'fa-plus',
    action: () => {
      openModal();
    },
  });

  if (props.shiftTypes.length > 0) {
    array.push({
      icon: 'fa-chevron-down',
      title: 'Add',
      dropdown: [
        {
          title: 'Add Shift',
          type: 'header',
        },
      ].concat(
        props.shiftTypes.map((shiftType) => {
          return {
            title: shiftType.title,
            action: (close: () => void) => {
              createShiftOfType(shiftType);
              close();
            },
          };
        })
      ),
    });
  }
  return array;
});

const auditShift = ref(null);

const showReplaceModal = ref(false);
const shiftReplace = ref(null);
const shiftsOnDay = ref([]);
const eventsOfUsersOnDay = ref([]);
const otherUsersOfGroup = ref([]);

const markShiftUpForSale = async (shift: { id: number }, emitChanges = false) => {
  working.value = true;
  await patchShift(shift.id, {
    for_sale: true,
    approved: true,
  });
  if (emitChanges) {
    emit('fetch');
  }
  working.value = false;
};
const findReplacement = async (shift: any) => {
  shiftReplace.value = {
    id: getKey(shift, 'id', null),
    isAssigned: getKey(shift, 'user_id', null) !== null,
    userId: getKey(shift, 'user_id', null),
    user_id: getKey(shift, 'user_id', null),
    shift_id: getKey(shift, 'id', null),
    start: shift.start,
    end: shift.end,
    shift_type_id: getKey(shift, 'shift_type_id', null),
    events: getKey(shift, 'events', []),
  };
  const viaGroup = props.crew.filter((c) => c.model === 'group' && c.model_id === shift.via_id);

  if (viaGroup && viaGroup.length > 0) {
    shiftReplace.value.viaGroupName = viaGroup[0].title;
  }
  const { data: allEvents } = await axios.get(`/api/groups/${props.groupId}/assignments`, {
    params: {
      start: shift.start,
      end: shift.end,
      with_resource_ids: true,
      with_shifts: true,
      with_festivals: false,
      with_colors: false,
    },
  });

  const events = allEvents.map((e) => ({
    event_id: getKey(e, 'event_id'),
    shift_id: getKey(e, 'shift_id'),
    isAssigned: getKey(e, 'isAssigned', false),
    start: e.start,
    end: e.end,
    resourceIds: e.resourceIds,
    events: e.events,
    title: e.title,
  }));

  shiftsOnDay.value = getShiftsForUserFromFullCalendarResource(events, shift, props.crew);
  eventsOfUsersOnDay.value = getEventsForUserFromFullCalendarResource(events, shift, props.crew);

  const eventFromEvents = allEvents.filter(
    (e) => e.hasOwnProperty('shift_id') && e.shift_id !== null && e.shift_id === shift.id
  );

  if (eventFromEvents.length > 0) {
    const resource = props.crew.filter((r) => r.id === eventFromEvents[0].resourceIds[0]);
    if (resource.length > 0) {
      otherUsersOfGroup.value = sortArrayBy(
        props.crew.filter(
          (r) =>
            r.model === 'user' &&
            r.parentId === (resource[0].hasOwnProperty('parentId') ? resource[0].parentId : resource[0].id)
        ),
        'title'
      );
      showReplaceModal.value = true;
      return;
    }
  }
  useToast().error('Could not find the user for this shift');
};

const replactmentDone = () => {
  emit('fetch');
};

const getAllowedAuditSidebarFields = () => {
  return [{ model: 'App\\Models\\Shifts\\Shift', fields: auditableShiftFields() }];
};
</script>

<template>
  <div>
    <BoxContainer
      title="Shifts"
      :content-padding="false"
      header-size="h3"
      :actions="actions">
      <div>
        <div
          v-if="shifts.length > 0 && !isSmallScreen"
          class="grid h-[40px] grid-cols-3 items-center gap-edge-1/2 [&>.active]:opacity-100 [&>div:hover]:opacity-100 [&>div]:cursor-pointer [&>div]:px-edge [&>div]:opacity-50">
          <div
            :class="{ 'active': showAcceptedShifts }"
            @click="showAcceptedShifts = !showAcceptedShifts">
            <CheckBox :model-value="showAcceptedShifts" />
            Accepted:
            {{ shifts.filter((s) => s.accepted_at).length }}
          </div>
          <div
            :class="{ 'active': showPendingShifts }"
            @click="showPendingShifts = !showPendingShifts">
            <CheckBox :model-value="showPendingShifts" />
            Pending:
            {{ shifts.filter((s) => !s.accepted_at && !s.declined_at).length }}
          </div>
          <div
            :class="{ 'active': showDeclinedShifts }"
            @click="showDeclinedShifts = !showDeclinedShifts">
            <CheckBox :model-value="showDeclinedShifts" />
            Declined:
            {{ shifts.filter((s) => s.declined_at).length }}
          </div>
        </div>

        <EmptyStateFullPage
          v-if="shifts.length === 0"
          size="medium"
          description="No Shifts Added" />

        <VTable
          v-if="shifts.length > 0"
          row-size="small"
          header-hover
          :bordered-table="true"
          :softer-background-header="true"
          edge-to-edge>
          <template #head>
            <VTableRow head>
              <VTableCell :style="isSmallScreen ? '' : 'min-width: 130px'"> Status</VTableCell>
              <VTableCell> Who</VTableCell>
              <VTableCell v-if="!isSmallScreen"> Via</VTableCell>
              <VTableCell v-if="!isSmallScreen"> Title</VTableCell>
              <VTableCell :style="isSmallScreen ? 'width: 60px' : 'min-width: 110px; width: 110px; max-width: 110px'">
                When
              </VTableCell>
              <VTableCell v-if="canEdit && !isSmallScreen"> Check in/out</VTableCell>
              <VTableCell
                v-if="canEdit"
                style="width: 35px" />
            </VTableRow>
          </template>
          <VTableRow
            v-for="shift in orderedShifts"
            :key="shift.id"
            :clickable="canEdit"
            classes="change-fa-icon group/item"
            @click="editShiftOnEvent(shift)">
            <VTableCell>
              <i
                v-if="working"
                class="fa fa-fw fa-circle-o-notch fa-spin" />
              <div
                v-if="!working"
                class="uppercase [&>div>*]:text-xs [&>div>div>i]:mr-edge-1/4 [&>div>div>i]:text-lg">
                <div v-if="shift.cancelled_at">
                  <div class="flex">
                    <i class="fa fw-fw fa-circle text-inverted" />
                    Cancelled
                  </div>
                </div>
                <div
                  v-else-if="shift.approved && shift.user_id && !shift.accepted_at && !shift.declined_at"
                  class="group min-w-[125px]">
                  <div class="hidden group-hover:block">
                    <VButton
                      size="xs"
                      icon="fa-envelope text-pending"
                      :title="isSmallScreen ? '' : 'Send Reminder'"
                      :stop-click="true"
                      @click="resendShiftNotification(shift)" />
                  </div>

                  <div
                    v-if="!isSmallScreen"
                    class="group-hover:hidden">
                    <i class="fa fw-fw fa-circle text-pending" />
                    Pending
                  </div>
                </div>
                <div v-else>
                  <div>
                    <i
                      v-if="!shift.approved"
                      title="Shift is not approved. It will therefore not be visible for anyone but admins."
                      class="fa fa-fw fa-exclamation-circle" />
                    <i
                      v-else-if="shift.approved && shift.declined_at"
                      class="fa fw-fw fa-circle text-warning" />
                    <i
                      v-else-if="shift.approved && shift.accepted_at"
                      class="fa fw-fw fa-circle text-success" />
                    <i
                      v-else-if="shift.approved && shift.for_sale"
                      class="fa fw-fw fa-cart-shopping fa-regular" />
                    <i
                      v-else-if="shift.approved && shift.user_id === null"
                      class="fa fw-fw fa-circle text-inverted" />

                    <template v-if="!isSmallScreen">
                      {{ shiftStatus(shift) }}
                    </template>
                  </div>
                </div>
              </div>
            </VTableCell>
            <VTableCell
              main-cell
              classes="group/cell">
              <div
                v-if="isSmallScreen && shift.via"
                class="truncate">
                <InputLabel
                  super-text
                  class="truncate"
                  :label="shift?.via?.name" />
              </div>
              <div class="w-[200px] truncate">
                <div :class="{ 'group-hover/cell:hidden': canEdit }">
                  <template v-if="shift.user">
                    {{ shift.user.name }}
                  </template>
                  <template v-else-if="shift.for_sale">
                    <DisplayBadge
                      class="max-w-[100px]"
                      :color="
                        shift.shift_interest_pivots.filter((p) => p.declined_at === null).length === 0
                          ? 'transparent'
                          : 'success'
                      "
                      :text="shift.shift_interest_pivots.filter((p) => p.declined_at === null).length + ' Requests'" />
                  </template>
                  <template v-else> Unassigned</template>
                </div>
                <div
                  v-if="canEdit"
                  class="hidden p-[1px] group-hover/cell:block">
                  <div class="flex gap-edge">
                    <VButton
                      size="xs"
                      :title="shift.user ? 'Re-assign' : 'Assign'"
                      :stop-click="true"
                      @click="findReplacement(shift)" />
                    <VButton
                      v-if="!shift.for_sale && !shift.user"
                      size="xs"
                      icon="fa-cart-shopping fa-regular"
                      title="For Sale"
                      :stop-click="true"
                      @click="markShiftUpForSale(shift, true)" />
                  </div>
                </div>
                <div
                  v-if="isSmallScreen"
                  class="font-semibold text-soft">
                  {{ getShiftTypeTitle(shift) }}
                </div>
              </div>
            </VTableCell>
            <VTableCell v-if="!isSmallScreen">
              <div
                class="w-[150px] truncate"
                :title="shift.via ? shift.via.name : ''">
                {{ shift.via ? shift.via.name : '' }}
              </div>
            </VTableCell>
            <VTableCell v-if="!isSmallScreen">
              <div
                class="w-[150px] truncate"
                :title="getShiftTypeTitle(shift)">
                {{ getShiftTypeTitle(shift) }}
              </div>
            </VTableCell>
            <VTableCell>
              <small
                v-if="
                  (shift.check_in && shift.check_in !== shift.start) ||
                  (shift.check_out && shift.check_out !== shift.end)
                "
                style="text-decoration: line-through">
                {{ formattedShiftDate(shift.start, shift.end) }} <br />
              </small>
              {{
                formattedShiftDate(
                  shift.check_in ? shift.check_in : shift.start,
                  shift.check_out ? shift.check_out : shift.end
                )
              }}
            </VTableCell>
            <VTableCell
              v-if="canEdit && !isSmallScreen"
              @click.stop>
              <div
                v-if="shift.approved && !shift.cancelled_at && shift.accepted_at"
                class="flex items-center justify-between gap-edge-1/4">
                <VButton
                  v-if="canCheckIntoShift(shift) || shift.check_in !== null"
                  size="sm"
                  :tool-tip-text="
                    shift.check_in ? 'Checked in at ' + shift.check_in : 'Not checked in yet. Click to check in.'
                  "
                  icon="fa-sign-in"
                  :stop-click="true"
                  @click="[
                    (showCheckModal = false),
                    nextTick(() => {
                      showCheckModal = true;
                    }),
                    (isCheckIn = true),
                    (selectedShift = shift),
                  ]" />
                <VButton
                  v-if="shift.check_in"
                  size="sm"
                  :tool-tip-text="
                    shift.check_out ? 'Checked out at ' + shift.check_out : 'Not check out yet. Click to check out.'
                  "
                  icon="fa-sign-out"
                  :stop-click="true"
                  @click="[
                    (showCheckModal = false),
                    nextTick(() => {
                      showCheckModal = true;
                    }),
                    (isCheckIn = false),
                    (selectedShift = shift),
                  ]" />
                <i
                  v-if="shift.notes"
                  :title="shift.notes"
                  class="fa fa-fw fa-comment mr-edge-1/4 text-sm" />
              </div>
            </VTableCell>
            <VTableCell v-if="canEdit">
              <VDropdown
                :items="
                  [
                    {
                      title: 'Edit',
                      preIcon: 'fa-pencil',
                      action: (close: () => void) => {
                        editShiftOnEvent(shift);
                        close();
                      },
                    },
                    {
                      title: shift.user ? 'Re-assign' : 'Assign',
                      preIcon: 'fa-user',
                      action: (close: () => void) => {
                        findReplacement(shift);
                        close();
                      },
                    },
                    {
                      title: (shift.cancelled_at ? 'Reinstate' : 'Cancel') + ' Shift',
                      preIcon: shift.cancelled_at ? 'fa-plus' : 'fa-times',
                      action: (close: () => void) => {
                        [shift.cancelled_at === null ? promptCancelShift(shift.id) : promptReinstateShift(shift.id)];
                        close();
                      },
                    },
                    {
                      title: 'Audits',
                      preIcon: 'fa-history',
                      action: (close: () => void) => {
                        auditShift = null;
                        nextTick(() => {
                          auditShift = shift;
                        });
                        close();
                      },
                    },

                    (shift.approved && !shift.cancelled_at && shift.accepted_at && canCheckIntoShift(shift)) ||
                    shift.check_in !== null
                      ? {
                          title: 'Check In',
                          preIcon: 'fa-sign-in',
                          action: (close: () => void) => {
                            showCheckModal = false;
                            nextTick(() => {
                              showCheckModal = true;
                            });
                            isCheckIn = true;
                            selectedShift = shift;
                            close();
                          },
                        }
                      : null,

                    shift.approved && !shift.cancelled_at && shift.accepted_at && shift.check_in
                      ? {
                          title: 'Check Out',
                          preIcon: 'fa-sign-out',
                          action: (close: () => void) => {
                            showCheckModal = false;
                            nextTick(() => {
                              showCheckModal = true;
                            });
                            isCheckIn = false;
                            selectedShift = shift;
                            close();
                          },
                        }
                      : null,
                  ].filter((i) => i !== null)
                "
                close-on-click>
                <template #click-area>
                  <div class="btn btn-in-table">
                    <i class="fa fa-fw fa-chevron-down" />
                  </div>
                </template>
                <template #pre="{ item }">
                  <CheckBox
                    v-if="item.selected !== undefined"
                    :model-value="item.selected" />
                  <i
                    :class="item.preIcon"
                    :style="'color: ' + item.hex"
                    class="fa fa-fw" />
                </template>
              </VDropdown>
            </VTableCell>
          </VTableRow>
        </VTable>
      </div>
    </BoxContainer>
    <div>
      <ShiftCheckModal
        v-if="showCheckModal"
        :allow-updating="true"
        :is-check-out-modal="!isCheckIn"
        :shift="selectedShift"
        @shift-updated="exchangeValuesOfObject($event, shifts, ['id'], 'id', false)"
        @closed="showCheckModal = false" />

      <ShiftAssignAndReplacementModal
        v-if="showReplaceModal"
        :group-id="groupId"
        :shift="shiftReplace"
        @replacement-done="replactmentDone"
        @closed="showReplaceModal = false" />

      <AuditsSidebar
        v-if="auditShift"
        :with-button="false"
        :can-filter-models="false"
        :url="'/api/audits/shifts/' + auditShift.id"
        :queryable-parameters="[{ name: 'Shift', id: 'App\\Models\\Shifts\\Shift' }]"
        :can-filter-audit-events="true"
        :allowed-fields="getAllowedAuditSidebarFields()"
        title="Audits for shift" />
    </div>
  </div>
</template>
