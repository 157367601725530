import { computed, provide, readonly } from 'vue';
import { storeToRefs } from 'pinia';
import { useGroupProjectLeadersStore } from '@/store/GroupProjectLeadersStore';
import { groupProjectLeadersKey } from '@/provide/keys';

export const useGroupProjectLeaders = (groupId: number, shouldProvide = true, usingProjectLeaders = true) => {
  const { list, loading } = storeToRefs(useGroupProjectLeadersStore());
  const { fetchProjectLeaders } = useGroupProjectLeadersStore();

  const groupProjectLeaders = computed(() => list.value.get(groupId) || []);

  const fetchGroupProjectLeaders = async (force = false) => {
    if (!usingProjectLeaders) return;
    if (force || !list.value.has(groupId)) {
      await fetchProjectLeaders(groupId);
    }
  };

  if (shouldProvide) {
    provide(groupProjectLeadersKey, {
      projectLeaders: groupProjectLeaders,
      loading: readonly(loading),
      fetchGroupProjectLeaders,
    });
  }

  return {
    projectLeaders: groupProjectLeaders,
    loading,
    fetchGroupProjectLeaders,
  };
};
