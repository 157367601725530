<script setup lang="ts">
import FieldModal from '@/components/Modals/FieldModal.vue';
import {
  destroyMetaDataField,
  getMetaData,
  getMetaDataFields,
  getMetaDataFieldShare,
  patchMetaDataField,
  postMetaDataField,
  shareMetaDataField,
  unshareMetaDataField,
} from '@/services/api-meta-data';
import { usePage } from '@inertiajs/vue3';
import { AxiosResponse } from 'axios';
import { computed, inject, nextTick, onMounted, ref, watch } from 'vue';
import { GroupResource } from '@/types/group';
import {
  MetaDataFieldResource,
  MetaDataFieldSharedWithGroupResource,
  MetaDataModelType,
  MetaDataResource,
} from '@/types/meta-data';
import { ShowTimeResource } from '@/types/show-time';
import { useToast } from 'vue-toastification';
import {
  exchangeValuesOfObject,
  getIndexFromArrayBasedOnId,
  getItemFromArrayBasedOnId,
  sortArrayBy,
} from '@/util/globals';
import FieldsList from '@/components/Fields/FieldsList.vue';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import ContentContainer from '@/components/Content/ContentContainer.vue';
import VButton from '@/components/Inputs/VButton.vue';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import { getNow } from '@/util/timeFunctions';
import { eventTypesKey } from '@/provide/keys';
import { allAvailableFieldTypes, multiSelectFieldType, tableFieldType } from '@/util/fields';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';
import SectionConnectedToEventTypeSubHeader from '@/components/Config/EventTypes/SectionConnectedToEventTypeSubHeader.vue';

type Props = {
  isTemplate: boolean;
  modelId: number;
  model: MetaDataModelType;
  canEditForm: boolean;
  canEditContent: boolean;
  isTemplateGallery?: boolean;
  isDisplay?: boolean;
  metaData: MetaDataResource;
  group?: GroupResource | null;
  documents?: [] | null;
  showTimes?: ShowTimeResource[] | null;
};

const props = withDefaults(defineProps<Props>(), {
  group: null,
  isDisplay: false,
  isTemplateGallery: false,
  documents: () => [],
  showTimes: () => [],
});

const emits = defineEmits<{
  (event: 'edit'): void;
  (event: 'updated'): void;
  (event: 'updateData'): void;
}>();

const toast = useToast();
const { assertReadyToDeleteModal } = useDeleteObjectModal();

const isOpen = ref(false);
if (props.isDisplay) {
  isOpen.value = true;
}
const editMode = ref(true);
const loading = ref(false);
const firstLoad = ref(false);

const fields = ref<MetaDataFieldResource[]>([]);
const sharedWithGroups = ref<MetaDataFieldSharedWithGroupResource[]>([]);
const selectedGroupIds = ref<Map<number, boolean>>(new Map());
const selectedField = ref<MetaDataFieldResource | null>(null);

const groupsSharedMetaDataFieldWith = (sharedWithGroupIds: number[]) => {
  if (props.group && props.group.childGroups && props.group.childGroups.length > 0) {
    return props.group.childGroups
      .filter((child) => sharedWithGroupIds.includes(child.id))
      .map((child) => child.name)
      .join(', ');
  }
  return null;
};

const fetchMetaData = async () => {
  if (props.isTemplateGallery) {
    fields.value = sortArrayBy(props.metaData.fields, 'order') as MetaDataFieldResource[];
    return;
  }
  const { data } = await getMetaData(props.metaData.id);
  fields.value = sortArrayBy(data.fields, 'order') as MetaDataFieldResource[];
  fixTitleOfFields();
};
const fixTitleOfFields = () => {
  if (props.canEditForm && props.group?.childGroups?.length) {
    fields.value.forEach((field) => {
      field.displayTitle = `${field.title} <br> <small>Shared With: </small> ${
        field.shared_with_group_ids.length === 0 ? 'N/A' : groupsSharedMetaDataFieldWith(field.shared_with_group_ids)
      }`;
    });
  }
};
if (props.isDisplay) {
  fetchMetaData();
}

watch(
  fields,
  () => {
    fixTitleOfFields();
  },
  { deep: true }
);
watch(isOpen, () => {
  if (isOpen.value) {
    if (!firstLoad.value) {
      fetchMetaData();
    }
  }
});

const getSharedWithGroupsForField = async () => {
  if (!selectedField.value?.id) return;

  const { data } = await getMetaDataFieldShare(selectedField.value.id);
  data.forEach((item) => {
    selectedGroupIds.value.set(item.group_id, item.write);
  });
  sharedWithGroups.value = data;
};

const showModal = ref(false);

const openEditFieldModal = async (field: MetaDataFieldResource | null) => {
  showModal.value = false;
  selectedGroupIds.value = new Map();
  selectedField.value = field;
  await getSharedWithGroupsForField();
  await nextTick();
  showModal.value = true;
};

const actions = computed(() => {
  const array = [];
  if (props.canEditForm) {
    if (editMode.value) {
      array.push({
        title: 'Add Field',
        type: 'primary',
        icon: 'fa-plus',
        action: () => {
          openEditFieldModal();
        },
      });
    }
  }
  return array;
});

const superHeader = computed(() => {
  if (props.metaData.show_time_id && props.showTimes.length > 0) {
    const showTime = getItemFromArrayBasedOnId(props.metaData.show_time_id, props.showTimes);
    return showTime && showTime.title ? showTime.title : 'Show #' + (props.showTimes?.indexOf(showTime) + 1);
  }
  return null;
});

const onOpen = (newOpen) => {
  isOpen.value = newOpen;
};

const fieldAdded = async (field: any) => {
  loading.value = true;

  const { data } = await postMetaDataField({
    class: field.class,
    options: field.options,
    title: field.title,
    component: field.component,
    linebreak_after: field.linebreak_after,
    meta_data_id: props.metaData.id,
  });

  fields.value.push(data);

  const promisses: Promise<AxiosResponse>[] = [];

  selectedGroupIds.value.forEach((value, key) => {
    promisses.push(shareMetaDataField(data.id, key, value));
  });

  await Promise.all(promisses);

  loading.value = false;
  toast.success('Created');
};
const removeField = async () => {
  if (!selectedField.value?.id) return;

  const index = getIndexFromArrayBasedOnId(selectedField.value.id, fields.value);

  const deleteIt = await assertReadyToDeleteModal(
    `Remove ${selectedField.value.title}`,
    'Are you sure you want to remove this field?'
  );

  if (!deleteIt) return;

  loading.value = true;
  await destroyMetaDataField(selectedField.value.id);
  loading.value = false;
  toast.success('Deleted');
  fields.value.splice(index, 1);
};
const fieldUpdated = async (field: any) => {
  if (!selectedField.value?.id) return;

  loading.value = true;

  await patchMetaDataField(selectedField.value.id, {
    class: field.class,
    options: field.options,
    title: field.title,
    linebreak_after: field.linebreak_after,
  });

  const promisses: Promise<AxiosResponse>[] = [];

  sharedWithGroups.value.forEach((sharedWith) => {
    if (!selectedGroupIds.value.has(sharedWith.group_id)) {
      promisses.push(unshareMetaDataField(selectedField.value.id, sharedWith.group_id));
    }
  });
  let newSharedTo = [];
  selectedGroupIds.value.forEach((value, key) => {
    newSharedTo.push(key);
    promisses.push(shareMetaDataField(selectedField.value.id, key, value));
  });
  field.shared_with_group_ids = newSharedTo;

  await Promise.all(promisses);

  loading.value = false;
  toast.success('Updated');
  fields.value = exchangeValuesOfObject({ ...selectedField.value, ...field }, fields.value, ['id', 'component']);
  fixTitleOfFields();
};

const fieldOrderChanged = async (field: MetaDataFieldResource, newOrder: number) => {
  await patchMetaDataField(field.id, {
    order: newOrder,
  });
  useToast().success('field order changed');
};

const assignValue = async (value: any, field: MetaDataFieldResource) => {
  if (field.value === value) return;
  await axios.patch(`/api/meta-data-fields/${field.id}/input`, {
    value,
  });
  toast.success('Saved');
  field.value = value;
  field.edited_by_id = usePage().props.auth.user.id;
  field.edited_at = getNow();
  field.updated_at = getNow();

  emits('updateData', {
    id: props.metaData.id,
    last_updated_field: { updated_by: usePage().props.auth.user.name, updated_at: getNow() },
  });
};

const fetchField = async (fieldId: number) => {
  const { data } = await getMetaDataFields(fieldId);
  fields.value = exchangeValuesOfObject(data, fields.value, ['id', 'component']);
};

const listenForBroadcast = () => {
  if (!props.modelId || !props.model) return;

  Echo.channel(`private-${props.model}.${props.modelId}`)
    .listen(`.metaData.${props.metaData.id}.updated`, (e) => {
      fetchMetaData();
    })
    .listen(`.metaData.${props.metaData.id}.fieldCreated`, (e) => {
      fetchField(e.id);
    })
    .listen(`.metaData.${props.metaData.id}.fieldUpdated`, (e) => {
      fetchField(e.id);
    })
    .listen(`.metaData.${props.metaData.id}.fieldDeleted`, (e) => {
      const index = _.findIndex(fields.value, (f) => Number(f.id) === Number(e.id));
      if (index) {
        fields.value.splice(index, 1);
      } else {
        fetchMetaData();
      }
    });
};

onMounted(() => {
  listenForBroadcast();
});

const { eventTypes, fetch: fetchEventTypes } = inject(eventTypesKey, {
  eventTypes: computed(() => []),
  fetch: (force?: boolean = false) => {},
});

fetchEventTypes();

const connectetedEventTypes = computed(() => {
  if (!props.metaData) return [];

  return eventTypes.value.filter((ev) => ev.pivot.meta_data.some((cl) => cl.id === props.metaData.id));
});

const concatAllEvenTypeNames = computed(() => {
  if (!connectetedEventTypes.value) return '';

  return connectetedEventTypes.value.map((ev) => ev.name).join(', ');
});
</script>

<template>
  <ContentContainer
    :can-edit="canEditForm || canEditContent"
    :edit-mode="isTemplate ? (canEditForm ? true : canEditContent) : canEditContent"
    :loading="loading"
    :title="metaData.title"
    :just-content-without-header="isDisplay"
    :super-header="superHeader"
    :actions="actions"
    pre-icon="fa-table fa-regular"
    :extra-height="isTemplate || isDisplay ? 0 : 70"
    @edit="emits('edit')"
    @open="onOpen">
    <template #underHeader>
      <div
        v-if="!isOpen && metaData.last_updated_field && !isTemplate"
        class="!sub-title text-soft">
        Last edited by <span class="">{{ metaData.last_updated_field.updated_by }}</span> at
        <span class="">{{ metaData.last_updated_field.updated_at }}</span>
      </div>
      <div v-else-if="isOpen && isTemplate">
        <SectionConnectedToEventTypeSubHeader
          :model="model"
          :model-id="modelId"
          pre-icon="fa-table"
          :title-of-item="metaData.title"
          :id-of-item="metaData.id"
          type-of-item="MetaData" />
      </div>
    </template>

    <template #content>
      <div :class="{ 'px-edge py-edge': editMode, 'p-edge': isTemplate }">
        <component
          :is="isDisplay ? 'div' : BoxContainer"
          v-if="fields.length > 0">
          <FieldsList
            v-model="fields"
            :edit-content="canEditContent && editMode"
            :edit-form="canEditForm && editMode"
            :model="model"
            :model-id="modelId"
            :documents="documents"
            with-icons
            :whisper-channel="model === 'Invite' ? 'Invite.' + modelId : null"
            :whisper-string="'field.'"
            :loading-order="false"
            @field-order-changed="fieldOrderChanged"
            @assign-value="assignValue"
            @open-edit-modal="openEditFieldModal" />
        </component>

        <EmptyStateFullPage
          v-if="fields.length === 0 && editMode && canEditForm"
          icon="fa-table fa-regular"
          description="No meta data fields created yet."
          button-text="Create New Field"
          :button-function="
            () => {
              openEditFieldModal();
            }
          " />

        <div
          v-if="canEditForm && editMode"
          class="h-[12px]">
          <FieldModal
            v-if="showModal"
            :init-field="selectedField"
            :with-description="false"
            :field-types="[...allAvailableFieldTypes, ...multiSelectFieldType, ...tableFieldType]"
            @created="fieldAdded"
            @deleted="removeField"
            @closed="showModal = false"
            @updated="fieldUpdated">
            <template
              v-if="group?.childGroups?.length > 0"
              #under>
              <div class="mt-edge">
                <h3
                  class="pointer"
                  title="These group will then see, and if write-access is granted, edit fields in your meta data.">
                  <i class="fa fa-group fa-fw" />
                  Share with your sub groups
                </h3>
                <VTable
                  v-if="group"
                  edge-to-edge
                  row-size="small"
                  rounded-pill-rows>
                  <VTableRow
                    v-for="child in group.childGroups"
                    :key="child.id">
                    <VTableCell :main-cell="selectedGroupIds.has(child.id)">
                      {{ child.name }}
                    </VTableCell>
                    <VTableCell>
                      <SettingCheck
                        v-if="selectedGroupIds.has(child.id)"
                        label="Can Edit"
                        :title="'If enabled, ' + child.name + ' can edit this field'"
                        :model-value="selectedGroupIds.get(child.id)"
                        @click.stop="selectedGroupIds.set(child.id, !selectedGroupIds.get(child.id))" />
                    </VTableCell>
                    <VTableCell main-cell>
                      <div class="flex justify-end">
                        <VButton
                          v-if="selectedGroupIds.has(child.id)"
                          :tool-tip-text="'Remove ' + child.name + ' from meta data field'"
                          title="Remove"
                          icon="fa-trash"
                          size="xs"
                          :stop-click="true"
                          @click="selectedGroupIds.delete(child.id)" />
                        <VButton
                          v-else
                          title="Add"
                          icon="fa-plus"
                          :tool-tip-text="'Add ' + child.name + ' to meta data field'"
                          size="xs"
                          :stop-click="true"
                          @click="selectedGroupIds.set(child.id, false)" />
                      </div>
                    </VTableCell>
                  </VTableRow>
                </VTable>
              </div>
            </template>
          </FieldModal>
        </div>
      </div>
    </template>
  </ContentContainer>
</template>
