<script lang="ts" setup>
import { nextTick, ref, watch } from 'vue';
import { safeHtmlStringify } from '@/util/safe-html-stringify';

const props = defineProps({
  content: {
    type: String,
    required: false,
    default: null,
  },
  columns: {
    type: Number,
    default: null,
    required: false,
  },
  removeLinks: {
    type: Boolean,
    default: false,
    required: false,
  },
  linksInNewTab: {
    type: Boolean,
    default: true,
    required: false,
  },
});

const display = ref(null);

const removeLinks = async () => {
  await nextTick();
  if (!props.removeLinks) return;
  if (!display.value) return;
  const links = display.value.querySelectorAll('a');
  links.forEach((link) => {
    const element2 = document.createElement('span');
    element2.innerText = link.innerText;
    link.replaceWith(element2);
  });
};
const setTargetOnAllLinkToBlank = async () => {
  await nextTick();
  if (!display.value) return;
  if (props.linksInNewTab) {
    const links = display.value.querySelectorAll('a');
    links.forEach((link) => {
      link.target = '_blank';
    });
  }
  await removeLinks();
};
setTargetOnAllLinkToBlank();
watch(
  () => props.content,
  (content) => {
    if (content) {
      setTargetOnAllLinkToBlank();
    }
  },
  { immediate: true }
);
</script>

<template>
  <div
    v-if="content"
    ref="display"
    class="font-normal [&_a:hover]:underline [&_a]:text-[--color-text-link] [&_a]:underline [&_ol]:list-decimal [&_ol]:pl-edge [&_ul]:list-disc [&_ul]:pl-edge">
    <div
      :style="columns ? 'column-count: ' + columns : ''"
      class="[&>p]:my-edge [&>p]:min-h-[1.5rem]"
      v-html="safeHtmlStringify(content)" />
  </div>
</template>
