<script setup lang="ts">
import { ZIndexPopUpModal } from '@/variables/z-indexes';
import { useSmallScreen } from '@/composables/use-small-screen';
import { useToast } from 'vue-toastification';
import { ref } from 'vue';
import VButton from '@/components/Inputs/VButton.vue';

const { image = null, canEdit = false } = defineProps<{
  image?: any;
  canEdit?: boolean;
}>();

const emit = defineEmits<{
  close: [];
  edit: [];
}>();

const toast = useToast();
const { isSmallScreen } = useSmallScreen();

const isOpen = ref(true);
const loading = ref(false);

const edit = () => {
  emit('edit');
  isOpen.value = false;
};

const closeModal = () => {
  emit('close');
  isOpen.value = false;
};

const downloadImage = async () => {
  if (isSmallScreen.value) {
    window.open(image.download_url);
    return;
  }

  loading.value = true;

  try {
    const response = await axios({
      url: image.download_url,
      method: 'GET',
      responseType: 'blob', // important
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    const splitName = image.filename.split('.');
    if (splitName[splitName.length - 1] === image.extension) {
      link.setAttribute('download', image.filename);
    } else {
      link.setAttribute('download', `${image.filename}.${image.extension}`);
    }

    document.body.appendChild(link);
    link.click();
    loading.value = false;
  } catch (e) {
    console.error(e);
    loading.value = false;
    toast.warning('Something went wrong, please try again later');
  }
};
</script>

<template>
  <teleport to="body">
    <div
      v-if="isOpen"
      :style="'z-index: ' + ZIndexPopUpModal + ';'"
      class="fixed left-0 top-0 flex h-full w-full flex-col bg">
      <div class="flex items-center gap-edge border-b">
        <div class="flex-1 px-edge py-edge-1/4">
          <h3>{{ image.filename }}</h3>
          <small
            v-if="image.user"
            class="uppercase">
            {{ image.user.name }} {{ image.created_at }}
          </small>
        </div>

        <div>
          <VButton
            :loading="loading"
            icon="fa-download"
            title="download"
            @click="downloadImage" />
        </div>

        <div v-if="canEdit">
          <VButton
            :loading="loading"
            icon="fa-pen"
            title="Edit"
            @click="edit" />
        </div>

        <div
          class="flex w-[64px] cursor-pointer items-center border-l p-edge text-center"
          @click="closeModal">
          <VButton
            :loading="loading"
            icon="fa-times" />
        </div>
      </div>
      <div class="flex flex-1 items-center overflow-auto bg-content-main p-edge">
        <img
          class="m-auto max-h-full min-h-1 min-w-1 max-w-full"
          :src="image.download_url" />
      </div>
    </div>
  </teleport>
</template>
