<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { getItemFromArrayBasedOnId } from '@/util/globals';
import { usePage } from '@inertiajs/vue3';
import { getNow } from '@/util/timeFunctions';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import VButton from '@/components/Inputs/VButton.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import DisplayEmail from '@/components/Display/DisplayEmail.vue';
import DisplayPhone from '@/components/Display/DisplayPhone.vue';
import DisplayBadge from '@/components/Display/DisplayBadge.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTable from '@/components/Tables/VTable.vue';
import EmailInput from '@/components/Inputs/EmailInput.vue';
import PhoneInput from '@/components/Inputs/PhoneInput.vue';
import { GuestListResource } from '@/types/festival';

type Props = {
  guestList: GuestListResource;
  canEdit: boolean;
  newlyCreatedGuestId: number | null;
};

const props = defineProps<Props>();
const emits = defineEmits<{
  (event: 'addOrUpdateGuest', arg: GuestListResource): void;
  (event: 'removeGuestId', arg: number): void;
  (event: 'fetch'): void;
}>();

const deleteGuest = async (id: number) => {
  await axios.delete(`/api/guests/${id}`);
  emits('removeGuestId', id);
  useToast().success('Guest deleted');
};

const updateGuestValue = async (id: any, key: string, value: any) => {
  const guest = getItemFromArrayBasedOnId(id, props.guestList.guests);
  if (!guest) return;
  guest[key] = value;
  await axios.patch('/api/guests/' + id, {
    [key]: value,
  });
  emits('addOrUpdateGuest', guest);
};

const approveGuest = async (guest) => {
  if (guest.approved_at === null) {
    axios.patch(`/api/guests/${guest.id}/approve`).then((response) => {
      if (response.status === 304) {
        useToast().warning('Already approved.');
        return;
      }
      guest.approved_by = usePage().props.auth.user.name;
      guest.approved_by_id = usePage().props.auth.user.id;
      guest.approved_at = getNow();
      useToast().success('Approved');
      emits('addOrUpdateGuest', guest);
    });
  } else {
    const certain = await useCertaintyModal().assertCertain(
      'Un Approve Guest',
      'Are you sure you want to un approve this guest?'
    );
    if (!certain) return;
    axios.patch(`/api/guests/${guest.id}/un-approve`).then((response) => {
      if (response.status === 304) {
        useToast().warning('Not approved yet.');
        return;
      }
      guest.approved_by = null;
      guest.approved_by_id = null;
      guest.approved_at = null;
      useToast().success('Un-Approved');
      emits('addOrUpdateGuest', guest);
    });
  }
};

const editGuestId = ref<number | null>(null);
watch(
  () => props.newlyCreatedGuestId,
  () => {
    if (props.newlyCreatedGuestId) {
      editGuestId.value = props.newlyCreatedGuestId;
    }
  }
);
</script>

<template>
  <VTable
    v-if="guestList && guestList.guests.length"
    snap-rows
    sticky-header
    edge-to-edge>
    <template #head>
      <VTableRow head>
        <VTableCell> Name</VTableCell>
        <VTableCell style="width: 200px"> Email</VTableCell>
        <VTableCell style="width: 150px"> Phone</VTableCell>
        <VTableCell style="width: 150px"> Status</VTableCell>
        <VTableCell
          v-if="canEdit"
          style="width: 50px" />
      </VTableRow>
    </template>
    <VTableRow
      v-for="guest in guestList.guests"
      :main-row="editGuestId === guest.id"
      :hide-edit-button-row="editGuestId !== guest.id">
      <template v-if="editGuestId !== guest.id">
        <VTableCell
          main-cell
          style="min-width: 100px">
          {{ guest.name }}
        </VTableCell>
        <VTableCell>
          <DisplayEmail :email="guest.email" />
        </VTableCell>
        <VTableCell style="min-width: 150px">
          <DisplayPhone :phone-number="guest.phone" />
        </VTableCell>
        <VTableCell>
          <DisplayBadge
            v-if="guest.approved_at"
            size="small"
            classes="w-[96px]"
            color="green"
            text="Approved" />

          <div
            v-else
            title="Not Approved. Click to approve"
            class="btn btn-outline-text btn-xs w-[100px] cursor-pointer hover:bg"
            @click.stop="approveGuest(guest)">
            <i class="fa fa-fw fa-exclamation-circle text-pending" />
            Pending
          </div>
        </VTableCell>
        <VTableCell v-if="canEdit">
          <VButton
            icon="fa-pencil"
            @click="editGuestId = guest.id"></VButton>
        </VTableCell>
      </template>
      <template v-if="editGuestId === guest.id">
        <VTableCell
          main-cell
          style="min-width: 150px">
          <TextInput
            :set-focus="newlyCreatedGuestId === guest.id"
            :model-value="guest.name"
            @blur="[(guest.name = $event), updateGuestValue(guest.id, 'name', $event)]" />
        </VTableCell>
        <VTableCell style="min-width: 150px">
          <EmailInput
            :email="guest.email"
            label=""
            @blur="[(guest.email = $event), updateGuestValue(guest.id, 'email', $event)]" />
        </VTableCell>
        <VTableCell style="min-width: 150px">
          <PhoneInput
            :phone="guest.phone"
            :label="null"
            :with-country-code="false"
            size="block"
            @phone-blur="[(guest.phone = $event), updateGuestValue(guest.id, 'phone', $event)]" />
        </VTableCell>
        <VTableCell>
          <div>
            <SettingCheck
              label="Approved"
              :model-value="guest.approved_at !== null"
              @update:model-value="approveGuest(guest)" />
          </div>
        </VTableCell>
        <VTableCell>
          <div class="flex gap-edge">
            <VButton
              size="sm"
              type="success"
              tool-tip-text="Done Editing"
              icon="fa-save"
              @click="editGuestId = null"></VButton>
            <VButton
              size="sm"
              type="warning"
              icon="fa-trash"
              @click="deleteGuest(guest.id)"></VButton>
          </div>
        </VTableCell>
      </template>
    </VTableRow>
  </VTable>
</template>
