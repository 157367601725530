import { computed, provide, readonly } from 'vue';
import { storeToRefs } from 'pinia';
import type { FestivalResourceComposable } from '@/provide/keys';
import { festivalResourceKey } from '@/provide/keys';
import { useFestivalStore } from '@/store/FestivalStore';

export const useFestival = (festivalId: number, shouldProvide = true): FestivalResourceComposable => {
  const { list, loading } = storeToRefs(useFestivalStore());
  const { fetchFestival } = useFestivalStore();

  const festival = computed(() => list.value.get(festivalId) || null);

  const fetch = async (force = false) => {
    if (force || !list.value.has(festivalId)) {
      await fetchFestival(festivalId);
    }
  };

  if (shouldProvide) {
    provide(festivalResourceKey, {
      festival,
      fetch,
      loading: readonly(loading),
    });
  }

  return {
    festival,
    loading,
    fetch,
  };
};
