<script setup lang="ts">
import { getRoute, openRoute } from '@/util/route';
import { useUserStore } from '@/store/UserStore';
import { useContextSidebarStore } from '@/store/ContextSidebarStore';
import { onMounted, ref, watch } from 'vue';
import { Link, router, usePage } from '@inertiajs/vue3';
import { getContextSidebarGroup } from '@/services/api-group';
import { groupTabs } from '@/util/tabs/group-tabs';
import { FestivalMinimalResource } from '@/types/festival';
import { getFestivals } from '@/services/api-festivals';
import { createFestivalTabs } from '@/util/tabs/festival-tabs';
import { eventShowTabs } from '@/util/tabs/views/event-show-tabs';
import { performanceTabs } from '@/util/tabs/performance-tabs';
import { festivalSectionPageTabs } from '@/util/tabs/festival-section-page-tabs';
import { publicFormTabs } from '@/util/tabs/public-form-tabs';
import autoAnimate from '@formkit/auto-animate';
import { onClickOutside } from '@vueuse/core';
import ProfileSettingModal from '@/components/Navbar/ProfileSettingModal/ProfileSettingModal.vue';

const currentPlace = defineModel<string>('place');
const currentPage = defineModel<string>('page');
const showMenu = ref(false);

const userStore = useUserStore();
const contextSidebarStore = useContextSidebarStore();

const container = ref<HTMLDivElement | undefined>();

const settingsModalOpen = ref(false);

const getPersonalTabs = () => {
  return [
    {
      key: 'profile_modal',
      title: 'Settings Modal',
      onClick: async () => {
        settingsModalOpen.value = true;
        showMenu.value = false;
      },
    },
  ];
};

const allGroups = ref([]);

const fetchAllGroups = async () => {
  const groups = contextSidebarStore.contextOptions.filter((o) => o.key.includes('group_'));

  const ids = groups.map((g) => g.key.replace('group_', ''));

  const promises = ids.map((id) => getContextSidebarGroup(Number(id)));
  const data = await Promise.all(promises);

  data.forEach((d) => {
    allGroups.value.push(d.data);
  });
};

await fetchAllGroups();

const getGroups = () => {
  return allGroups.value.map((g) => ({
    key: g.id,
    title: g.name,
    icon: 'fa-group',
    items: groupTabs(g, userStore.admin, true).map((gr) => ({
      ...gr,
      onClick: () => {
        currentPlace.value = `${g.name} | ${gr.title}`;
        openRoute(`${gr.route}#${gr.hash}`);
        showMenu.value = false;
      },
    })),
    open: false,
  }));
};

const allFestivals = ref<FestivalMinimalResource[]>([]);

const fetchAllFestivals = async () => {
  const p = allGroups.value.map((g) => getFestivals(g.id));
  const data = await Promise.all(p);
  data.forEach((d) => {
    d.data.forEach((f) => {
      const group = allGroups.value.find((g) => g.id === f.owner_id);
      allFestivals.value.push({ ...f, owner_name: group.name });
    });
  });
};

await fetchAllFestivals();

if (usePage().props.group?.id) {
  await contextSidebarStore.fill({ groupId: usePage().props.group.id });
} else if (usePage().props.festival?.id) {
  await contextSidebarStore.fill({ festivalId: usePage().props.festival.id });
} else {
  await contextSidebarStore.fill({});
}

contextSidebarStore.personalFestivals.forEach((f) => {
  if (!allFestivals.value.find((af) => af.id === f.id)) allFestivals.value.push({ ...f, owner_name: '' });
});

const adminLinks = userStore.admin
  ? [
      { title: 'Dashboard', route: getRoute('admin.dashboard') },
      {
        title: 'Administrator',
        action: () => {
          return (window.location = '/administrator');
        },
      },
      { title: 'Groups', route: getRoute('admin.groups.index') },
      { title: 'Festivals', route: getRoute('admin.festivals.index') },
      { title: 'Events', route: getRoute('admin.events.index') },
      { title: 'User Activity', route: getRoute('admin.userActivity') },
      { title: 'Data Display', route: getRoute('admin.dataDisplay') },
      { title: 'Venues', route: getRoute('admin.venues.index') },
      { title: 'Audit log', route: getRoute('admin.audits') },
      {
        title: 'Event Resource Types',
        route: getRoute('admin.eventResourceTypes'),
      },
      { title: 'Templates', route: getRoute('admin.templates.index') },
      {
        title: 'Push Notifications',
        route: getRoute('admin.pushNotifications.index'),
      },
      { title: 'Reminders', route: getRoute('admin.reminders') },
      { title: 'Caches', route: getRoute('admin.caches.groups') },
      { title: 'Components', route: getRoute('components') },
      { title: 'Design', route: getRoute('admin.design') },
      { title: 'API & Integrations', route: getRoute('profile.api') },
    ]
  : [];

const personalTabs = ref({
  key: 3,
  title: usePage().props.auth.user.name,
  icon: 'fa-user',
  items: [
    {
      key: 1,
      title: 'Dashboard',
      onClick: () => {
        openRoute(getRoute('events'));
        currentPlace.value = 'Personal';
        showMenu.value = false;
      },
    },
    ...getPersonalTabs(),
  ],
  open: false,
});

const originalTabs = [
  {
    key: 1,
    title: 'Groups',
    items: getGroups(),
    open: false,
  },
  {
    key: 2,
    title: 'Festivals',
    items: allFestivals.value.map((f) => ({
      key: f.id,
      title: `${f.name} ${f.owner_name.length ? `| ${f.owner_name}` : ''}`,
      icon: 'fa-tent',
      items: createFestivalTabs(f, userStore.admin, true).map((t) => ({
        ...t,
        onClick: () => {
          currentPlace.value = `${f.name} | ${t.title}`;
          openRoute(`${t.route}#${t.hash}`);
          showMenu.value = false;
        },
      })),
      open: false,
    })),
    open: false,
  },
];

const tabs = ref(originalTabs);

watch(
  currentPage,
  () => {
    if (currentPage.value === 'event') {
      const eventTabs = {
        key: 4,
        title: 'Event',
        items: [
          {
            key: 1,
            title: currentPlace.value,
            icon: 'fa-calendar-days',
            items: eventShowTabs(usePage().props.event).map((t) => ({
              key: t.key,
              title: t.title,
              onClick: () => {
                const url = window.location;

                router.visit(url.origin + url.pathname + url.search + '#' + t.key);

                showMenu.value = false;
              },
            })),
            open: false,
          },
        ],
        open: false,
      };

      tabs.value = [eventTabs, ...originalTabs];
    } else if (currentPage.value === 'performance') {
      const ptb = {
        key: 4,
        title: 'performance',
        items: [
          {
            key: 1,
            title: currentPlace.value,
            icon: 'fa-calendar-days',
            items: performanceTabs({
              hasAdvances: usePage().props.hasAdvances ?? false,
              hasAccreditations: usePage().props.hasAccreditations ?? false,
              hasGuestLists: usePage().props.hasGuestLists ?? false,
              hasFinances: usePage().props.hasFinances ?? false,
              permissions: usePage().props.permissions ?? [],
              admin: usePage().props.admin ?? false,
            }).map((t) => ({
              key: t.key,
              title: t.title,
              onClick: () => {
                const url = window.location;

                router.visit(url.origin + url.pathname + url.search + '#' + t.key);
                showMenu.value = false;
              },
            })),
            open: false,
          },
        ],
        open: false,
      };

      tabs.value = [ptb, ...originalTabs];
    } else if (currentPage.value === 'festival_section') {
      const ptb = {
        key: 5,
        title: 'festival_section',
        items: [
          {
            key: 1,
            title: currentPlace.value,
            icon: 'fa-calendar-days',
            items: festivalSectionPageTabs(
              usePage().props.festivalSection,
              usePage().props.festival.festival_settings
            ).map((t) => ({
              key: t.key,
              title: t.title,
              onClick: () => {
                const url = window.location;

                router.visit(url.origin + url.pathname + url.search + '#' + t.key);
                showMenu.value = false;
              },
            })),
            open: false,
          },
        ],
        open: false,
      };

      tabs.value = [ptb, ...originalTabs];
    } else if (currentPage.value === 'public_form') {
      const ptb = {
        key: 6,
        title: 'public_form',
        items: [
          {
            key: 1,
            title: currentPlace.value,
            icon: 'fa-calendar-days',
            items: publicFormTabs(
              usePage().props.admin ?? false,
              usePage().props.festival.festival_settings.has_accreditations,
              usePage().props.permissions ?? [],
              true
            ).map((t) => ({
              key: t.key,
              title: t.title,
              onClick: () => {
                const url = window.location;

                router.visit(url.origin + url.pathname + url.search + '#' + t.key);
                showMenu.value = false;
              },
            })),
            open: false,
          },
        ],
        open: false,
      };

      tabs.value = [ptb, ...originalTabs];
    } else {
      tabs.value = originalTabs;
    }
  },
  { immediate: true }
);

onMounted(() => {
  if (container.value) {
    autoAnimate(container.value);
  }
});

const menu = ref<HTMLDivElement | undefined>();

onClickOutside(menu, (e) => {
  if (e.target?.classList?.contains('fa-times')) return;
  showMenu.value = false;
});

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <button
    v-bind="$attrs"
    @click.stop="showMenu = !showMenu">
    <i
      class="fa fa-fw transform transition"
      :class="showMenu ? 'fa-times' : 'fa-bars'" />
  </button>
  <div
    v-if="showMenu"
    :style="`top:var(--navbar-current-height)`"
    class="fixed left-0 z-[8888] h-dynamic-screen w-full bg opacity-80" />

  <div
    v-if="showMenu"
    ref="menu"
    :style="`top:var(--navbar-current-height)`"
    class="absolute right-0 z-[100000] flex h-main w-[80vw] flex-col justify-between gap-edge-1/4 border-l border-r bg">
    <div
      ref="container"
      class="flex-1 divide-y overflow-auto">
      <div>
        <button
          class="flex w-full items-center justify-between gap-edge p-edge"
          @click="personalTabs.open = !personalTabs.open">
          <span
            class="flex gap-edge"
            :class="{ 'text-success': 'Personal' === currentPlace }">
            <i
              class="fa fa-fw"
              :class="personalTabs.icon" />
            <span class="font-headers text-sm">{{ personalTabs.title }}</span>
          </span>

          <i
            class="fa fa-fw fa-chevron-down ml-auto transform transition-all"
            :class="{ 'rotate-180': personalTabs.open }" />
        </button>
        <div
          v-if="personalTabs.open"
          class="my-edge space-y-edge">
          <div
            v-for="item in personalTabs.items"
            :key="item.key"
            class="flex flex-col gap-edge rounded bg-box px-edge">
            <button
              class="flex items-center justify-between py-edge-1/4 text-left"
              :class="{ 'border-b': item.open }"
              @click="item.onClick ? item.onClick() : (item.open = !item.open)">
              <span class="ml-edge-2x text-sm capitalize">{{ item.title }} </span>
            </button>
          </div>
        </div>
      </div>
      <template v-for="section in tabs">
        <div
          v-for="tab in section.items"
          :key="tab.key"
          class="">
          <div
            class="flex items-center justify-between gap-edge p-edge"
            @click="tab.open = !tab.open">
            <div
              class="flex items-center justify-between gap-edge"
              :class="{ 'text-success': tab.title === currentPlace }">
              <i
                class="fa fa-fw"
                :class="tab.icon" />
              <h3 class="text-sm">{{ tab.title }}</h3>
            </div>

            <i
              class="fa fa-fw fa-chevron-down transform transition-all"
              :class="{ 'rotate-180': tab.open }" />
          </div>
          <div
            v-if="tab.open"
            class="my-edge space-y-edge">
            <div
              v-for="item in tab.items"
              :key="item.key"
              class="flex flex-col gap-edge rounded bg-box px-edge">
              <button
                class="flex items-center justify-between py-edge-1/4 text-left"
                :class="{ 'border-b': item.open }"
                @click="item.onClick ? item.onClick() : (item.open = !item.open)">
                <span class="ml-edge-2x text-sm capitalize">{{ item.title }}</span>
              </button>
            </div>
          </div>
        </div>
      </template>

      <div v-if="userStore.admin">
        <button
          class="flex w-full items-center justify-between gap-edge p-edge"
          @click="personalTabs.open = !personalTabs.open">
          <span
            class="flex gap-edge"
            :class="{ 'text-success': 'Personal' === currentPlace }">
            <i
              class="fa fa-fw"
              :class="personalTabs.icon" />
            <span class="font-headers text-sm">Super Admin</span>
          </span>

          <i
            class="fa fa-fw fa-chevron-down ml-auto transform transition-all"
            :class="{ 'rotate-180': personalTabs.open }" />
        </button>
        <div class="my-edge space-y-edge">
          <div
            v-for="item in adminLinks"
            :key="item.title"
            class="flex flex-col gap-edge rounded bg-box px-edge">
            <button
              class="flex items-center justify-between py-edge-1/4 text-left"
              @click="item.action ? item.action() : router.visit(item.route)">
              <span class="ml-edge-2x text-sm capitalize">{{ item.title }} </span>
            </button>
          </div>
        </div>
      </div>

      <div class="text-sm">
        <Link :href="getRoute('logout')">
          <div class="flex items-center gap-edge p-edge">
            <i class="fa fa-fw fa-sign-out-alt" />
            <span> Logout </span>
          </div>
        </Link>
      </div>
    </div>
  </div>

  <ProfileSettingModal
    v-if="settingsModalOpen"
    @closed="settingsModalOpen = false" />
</template>
