<script lang="ts" setup>
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import HoverBox from '@/components/HoverBox.vue';
import { highlightStringBySearch } from '@/util/text-replace-helper';

type Props = {
  inFocus?: null | boolean;
  withUpAndDown?: null | boolean;
  maxItems?: null | number;
  modelValue?: string | null | boolean | number;
  dataListOptions?: string[] | number[];
  container?: HTMLTextAreaElement | null;
  currentDataListOption?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  inFocus: false,
  withUpAndDown: true,
  maxItems: null,
  modelValue: null,
  container: null,
  currentDataListOption: null,
  dataListOptions: () => [],
});

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void;
  (event: 'update:currentDataListOption', value: string | null): void;
}>();

emit('update:currentDataListOption', null);

const selectedDataListOptionIndex = ref<number | null>(null);
const rerenderPositionIndicator = ref(0);
const listIsInverted = ref(false);
const filteredDataListOptions = ref<string[]>([]);

const getFilteredDataListOptions = () => {
  if (!props.inFocus) return (filteredDataListOptions.value = null);
  if (!props.container) return (filteredDataListOptions.value = null);
  if (props.modelValue && props.modelValue.length > 10) return (filteredDataListOptions.value = null);

  if (props.dataListOptions?.length) {
    const newList = [...props.dataListOptions].filter((o) =>
      props.modelValue
        ? (typeof o === 'string' && typeof props.modelValue === 'string'
            ? o.toLowerCase().includes(props.modelValue.toLowerCase())
            : true) &&
          o !== props.modelValue &&
          (props.modelValue ? o !== props.modelValue : true)
        : true
    );
    filteredDataListOptions.value = listIsInverted.value
      ? [...newList].splice(0, props.maxItems ? props.maxItems : 5).reverse()
      : [...newList].splice(0, props.maxItems ? props.maxItems : 5);
  }
};

watch(
  () => props.inFocus,
  () => {
    if (!props.inFocus) {
      setTimeout(() => {
        pageX.value = null;
        pageY.value = null;
      }, 200);
      return;
    }
    nextTick(() => {
      findPageXAndY();
      getFilteredDataListOptions();
    });
  },
  { deep: true, immediate: true }
);

const setPageXandY = (delay = 0) => {
  if (!props.container) return;
  if (!props.inFocus) return;
  setTimeout(() => {
    pageX.value = props.container.getBoundingClientRect().x;
    pageY.value = props.container.getBoundingClientRect().y + 40;
  }, delay);
  setTimeout(() => {
    setPageXandY();
  }, 1000);
};

watch(
  listIsInverted,
  () => {
    getFilteredDataListOptions();
  },
  { deep: true, immediate: true }
);

watch(
  () => props.modelValue,
  () => {
    // selectedDataListOptionIndex.value = null;
    if (selectedDataListOptionIndex.value) {
      selectedDataListOptionIndex.value = null;
      emit('update:currentDataListOption', null);
    }
    getFilteredDataListOptions();
  },
  { deep: true, immediate: true }
);

const pageX = ref<number | null>(null);
const pageY = ref<number | null>(null);

const findPageXAndY = () => {
  if (!props.container) return;
  pageX.value = null;
  pageY.value = null;
  setPageXandY(100);
  setPageXandY(200);
  setPageXandY(400);
  setPageXandY(500);
};

const onKeyDownEvent = (event) => {
  rerenderPositionIndicator.value++;
  setTimeout(() => {
    rerenderPositionIndicator.value++;
  }, 200);
  if (pageX.value === null) return;
  if (event.code === 'Escape') {
    pageY.value = null;
    pageX.value = null;
    return;
  }
  if (!props.withUpAndDown) return;

  if (event.code === 'ArrowDown') {
    if (!filteredDataListOptions.value) return;
    if (selectedDataListOptionIndex.value === null) {
      selectedDataListOptionIndex.value = 0;
    } else {
      selectedDataListOptionIndex.value++;
    }
    if (selectedDataListOptionIndex.value >= filteredDataListOptions.value.length) {
      selectedDataListOptionIndex.value = null;
    }
  } else if (event.code === 'ArrowUp') {
    if (!filteredDataListOptions.value) return;
    if (selectedDataListOptionIndex.value === null) {
      selectedDataListOptionIndex.value = filteredDataListOptions.value.length - 1;
    } else {
      selectedDataListOptionIndex.value--;
    }
    if (selectedDataListOptionIndex.value < 0) {
      selectedDataListOptionIndex.value = null;
    }
  } else {
    selectedDataListOptionIndex.value = null;
  }
  let newValue = null;
  try {
    if (
      selectedDataListOptionIndex.value !== null &&
      filteredDataListOptions.value.length >= selectedDataListOptionIndex.value
    ) {
      newValue = filteredDataListOptions.value[selectedDataListOptionIndex.value];
    }
  } catch (e) {}
  emit('update:currentDataListOption', newValue);

  if (!['ArrowDown', 'ArrowUp'].includes(event.code)) {
    selectedDataListOptionIndex.value = null;
  }
};

onMounted(() => {
  window.addEventListener('keydown', onKeyDownEvent);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', onKeyDownEvent);
});
const clickOnOption = (option) => {
  emit('update:modelValue', option);
};
</script>

<template>
  <HoverBox
    v-if="pageX && pageY && filteredDataListOptions && filteredDataListOptions.length > 0"
    v-model:list-is-inverted="listIsInverted"
    :x-pos="pageX"
    :y-pos="pageY"
    :rerender-position-indicator="rerenderPositionIndicator"
    :event-bounds="container.getBoundingClientRect()"
    close-on-scroll
    @closed="[(pageX = null), (pageY = null)]">
    <ul
      :class="{
        'rounded-tl rounded-tr border-l border-r border-t': listIsInverted,
        'rounded-bl rounded-br border-b border-l border-r': !listIsInverted,
      }"
      class="min-h-[40px] divide-y bg-content">
      <li>
        <div class="overflow-auto">
          <ul class="divide-y">
            <li
              v-for="(option, index) in filteredDataListOptions"
              :key="option"
              :title="option"
              :class="{ 'bg-row-hover': selectedDataListOptionIndex === index }"
              class="grid cursor-pointer grid-cols-[auto_15px] items-center gap-1 bg-row p-edge-1/2 pl-edge pr-edge-1/2 hover:bg-row-hover"
              @click="clickOnOption(option)">
              <slot
                :option="option"
                name="option">
                <div
                  class="w-[150px] truncate"
                  v-html="highlightStringBySearch(option, modelValue)"></div>
              </slot>
              <i
                v-if="selectedDataListOptionIndex === index"
                class="fa fa-fw fa-arrow-left"></i>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </HoverBox>
</template>
