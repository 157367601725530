import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { FestivalResource } from '@/types/festival';

export const useFestivalStore = defineStore('festivals', () => {
  const list = ref<Map<number, FestivalResource[] | null>>(new Map());
  const loading = ref(false);

  const listenForBroadcast = (festivalId: number) => {
    // Echo.join(`On.${modelType}.${modelId}`)
    //   .listen('.eventType.created', async () => {
    //     await fetchEventTypes(modelType, modelId);
    //   })
    //   .listen('.eventType.updated', async () => {
    //     await fetchEventTypes(modelType, modelId);
    //   })
    //   .listen('.eventType.deleted', async () => {
    //     await fetchEventTypes(modelType, modelId);
    //   });
  };

  const fetchFestival = async (festivalId: number) => {
    loading.value = true;
    const { data } = await axios.get('/api/festivals/' + festivalId);
    if (!list.value.has(festivalId)) {
      listenForBroadcast(festivalId);
    }
    list.value.set(festivalId, data);
    loading.value = false;
  };

  return {
    list,
    loading,
    fetchFestival,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFestivalStore, import.meta.hot));
}
