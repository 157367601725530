<script setup lang="ts">
import type { EventTypeResource } from '@/types/event-request';
import { computed, nextTick, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useMultipleButtonsModal } from '@/composables/modals/use-multiple-buttons-modal';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import { eventTypesKey } from '@/provide/keys';
import { requireInjection } from '@/util/require-inject';
import DisplayBadge from '@/components/Display/DisplayBadge.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import { eventTypeInlineAdderKey, hasAccessToFeatureInTesting } from '@/variables/feature-testing-constants';
import EventTypeCheckListAttachModal from '@/components/Config/EventTypes/Sections/EventTypeCheckListAttachModal.vue';
import { addPluralityS, arrayToJoinString } from '../../../util/globals';
import VButton from '@/components/Inputs/VButton.vue';

type Props = {
  model: 'Group';
  modelId: number;
  preIcon: string;
  titleOfItem: string;
  idOfItem: number;
  typeOfItem:
    | 'MetaData'
    | 'Runningorder'
    | 'Board'
    | 'Form'
    | 'InvoiceBasis'
    | 'TimelineTemplate'
    | 'CheckList'
    | 'Models\\Tasks\\TaskTemplateList';
};

const props = defineProps<Props>();

const { fetch: fetchEventTypes, eventTypes } = requireInjection(eventTypesKey);
fetchEventTypes();
const toast = useToast();
const { threeButtonModal } = useMultipleButtonsModal();
const { assertReadyToDeleteModal } = useDeleteObjectModal();

const doTheActualAttachData = async (eventType: EventTypeResource, addToAllFutureInvites: boolean) => {
  await axios.post(`/api/event-types/${eventType.id}/pivot`, {
    model_type: `App\\${props.typeOfItem}`,
    model_id: props.idOfItem,
    add_to_all_future_invites: addToAllFutureInvites,
  });

  await fetchEventTypes(true);

  if (addToAllFutureInvites) {
    toast.success(`Added ${props.titleOfItem} to ${eventType.name}`);
  } else {
    toast.success(`${props.titleOfItem} added`);
  }
};

const attachData = async (eventType: EventTypeResource) => {
  const result = await threeButtonModal({
    title: 'Add template to Events',
    description: `Do you want to add ${props.titleOfItem} to new events created only or previously created events?`,
    button: {
      text: 'Add',
    },
    options: [
      {
        value: 'first',
        label: 'All Events',
      },
      {
        value: 'second',
        label: 'New Events Only',
      },
    ],
  });

  switch (result) {
    case 'first': {
      await doTheActualAttachData(eventType, true);
      break;
    }
    case 'second': {
      await doTheActualAttachData(eventType, false);
      break;
    }
    case 'cancel':
    default: {
      break;
    }
  }
};

const detachData = async (eventType: EventTypeResource) => {
  const deleteIt = await assertReadyToDeleteModal(
    `Remove Template`,
    `Are you sure you want to remove ${props.titleOfItem} from ${eventType.name}? It will no longer be included on the event type.`,
    'Remove'
  );
  if (!deleteIt) return;

  await axios.delete(`/api/event-types/${eventType.id}/pivot`, {
    params: {
      model_type: `App\\${props.typeOfItem}`,
      model_id: props.idOfItem,
    },
  });

  working.value = false;

  await fetchEventTypes(true);

  toast.success(`${props.titleOfItem} detached`);
};

const working = ref(false);

const eventTypesAlreadyConnected = computed(() => {
  return eventTypes.value.filter((e) => {
    switch (props.typeOfItem) {
      case 'MetaData': {
        return e.pivot.meta_data.map((m) => m.id).includes(props.idOfItem);
      }
      case 'Form': {
        return e.pivot.forms.map((m) => m.id).includes(props.idOfItem);
      }
      case 'Board': {
        return e.pivot.boards.map((m) => m.id).includes(props.idOfItem);
      }
      case 'Runningorder': {
        return e.pivot.runningorders.map((m) => m.id).includes(props.idOfItem);
      }
      case 'InvoiceBasis': {
        return e.pivot.invoice_bases.map((m) => m.id).includes(props.idOfItem);
      }
      case 'TimelineTemplate': {
        return e.pivot.timeline_templates.map((m) => m.id).includes(props.idOfItem);
      }
      case 'CheckList': {
        return e.pivot.check_lists.map((m) => m.id).includes(props.idOfItem);
      }
      case 'Models\\Tasks\\TaskTemplateList': {
        return e.pivot.task_template_lists.map((m) => m.id).includes(props.idOfItem);
      }
    }
    return false;
  });
});

const searchTerm = ref(null);
const selectedEventType = ref(null);

const detachCheckList = async (eventType: EventTypeResource, item: { title: string; start: string; id: number }) => {
  const deleteIt = await assertReadyToDeleteModal(
    `Detach ${item.title} from ${eventType.name}${item.start ? ` at ${item.start}` : ''}?`,
    'Are you sure you want to do that?'
  );

  if (!deleteIt) return;
  working.value = true;
  await axios.post(`/api/event-types/${eventType.id}/pivot/check-lists`, {
    check_list_id: item.id,
    start: item.start,
    attach: false,
  });
  await fetchEventTypes(true);

  working.value = false;

  toast.success(`${item.title} detached`);
};

const attachCheckList = async (eventType: EventTypeResource) => {
  selectedEventType.value = null;
  await nextTick();
  selectedEventType.value = eventType;
};

const dropdownItems = computed(() => {
  if (props.typeOfItem === 'CheckList') {
    return eventTypes.value
      .filter((e) => {
        return !searchTerm.value || searchTerm.value.length === 0
          ? true
          : e.name.toLowerCase().includes(searchTerm.value.toLowerCase());
      })
      .flatMap((e) => {
        const attachments = e.pivot.check_lists.filter((c) => c.id === props.idOfItem);
        return [
          {
            id: e.id,
            title: e.name + (attachments.length > 0 ? ' (' + attachments.length + ')' : ''),
            action: () => {
              attachCheckList(e);
            },
          },
        ].concat(
          attachments.map((a) => {
            return {
              title: a.start ? 'Added at ' + a.start : 'Added',
              class: 'pl-edge-2x',
              postIcon: 'fa-trash fa-regular',
              action: () => {
                detachCheckList(e, a);
              },
            };
          })
        );
      });
  }
  return eventTypes.value
    .filter((e) => {
      return !searchTerm.value || searchTerm.value.length === 0
        ? true
        : e.name.toLowerCase().includes(searchTerm.value.toLowerCase());
    })
    .map((e) => {
      return {
        id: e.id,
        title: e.name,
        postIcon: eventTypesAlreadyConnected.value.map((connected) => connected.id).includes(e.id) ? 'fa-check' : '',
        action: () => {
          if (eventTypesAlreadyConnected.value.map((connected) => connected.id).includes(e.id)) {
            detachData(e);
          } else {
            attachData(e);
          }
        },
      };
    });
});
</script>

<template>
  <div
    v-if="hasAccessToFeatureInTesting(eventTypeInlineAdderKey)"
    class="group ml-[45px] flex items-center gap-edge">
    <h5 class="min-w-[120px]">
      Added to {{ eventTypesAlreadyConnected.length }} Event Type{{ addPluralityS(eventTypesAlreadyConnected.length) }}
    </h5>
    <VDropdown
      close-on-click
      :items="dropdownItems"
      :have-max-width="false"
      :highlight-text="searchTerm"
      with-arrows-up-and-down>
      <template #click-area>
        <div class="flex items-center gap-edge">
          <div
            v-if="eventTypesAlreadyConnected.length > 0"
            class="flex gap-edge overflow-auto">
            <div v-for="eventType in eventTypesAlreadyConnected.filter((e, i) => i < 4)">
              <DisplayBadge
                :text="eventType.name"
                class="group/badge">
                <i
                  :style="'color: ' + eventType.color"
                  class="fa fa-fw fa-tag"></i>
                {{ eventType.name }}
                <i
                  v-if="typeOfItem !== 'CheckList'"
                  class="fa fa-fw fa-times invisible group-hover/badge:visible"
                  @click.stop="detachData(eventType)"></i>
              </DisplayBadge>
            </div>
            <div v-if="eventTypesAlreadyConnected.length > 4">
              <DisplayBadge
                color="gray"
                :title="arrayToJoinString(eventTypesAlreadyConnected.filter((e, i) => i >= 4).map((e) => e.name))"
                :text="'+' + (eventTypesAlreadyConnected.length - 4) + ' more'" />
            </div>
          </div>

          <VButton
            size="sm"
            :title="eventTypesAlreadyConnected.length ? '' : 'Add'"
            icon="fa-plus" />
        </div>
      </template>
      <template #aboveDropdown>
        <TextInput
          v-model="searchTerm"
          placeholder="Filter Event Types"
          text-wrapper-class="[&_*]:rounded-b-none"
          set-focus />
      </template>
    </VDropdown>

    <EventTypeCheckListAttachModal
      v-if="selectedEventType && typeOfItem === 'CheckList'"
      v-model:loading="working"
      :event-type="selectedEventType"
      :check-list="{ id: idOfItem }" />
  </div>
</template>
