<script lang="ts" setup>
import { nextTick, onBeforeUnmount, ref, watch } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import TextInput from '@/components/Inputs/TextInput.vue';
import EmailInput from '@/components/Inputs/EmailInput.vue';
import HoverBox from '@/components/HoverBox.vue';

type Props = {
  text?: string;
  isEmail?: boolean;
  placeholder?: string | null;
  required?: boolean;
  canEdit?: boolean;
  withClear?: boolean;
  url: string;
  params?: any;
  searchString?: string | null;
  label?: string | null;
  setFocus?: boolean;
  searchParam?: string;
  debounceTime?: number;
  minSearchLength?: number;
  optionKey?: string;
  optionLabel?: string;
  leftIcon?: string;
};

const props = withDefaults(defineProps<Props>(), {
  text: '',
  isEmail: false,
  placeholder: null,
  required: false,
  canEdit: false,
  withClear: false,
  params: null,
  searchString: null,
  label: null,
  setFocus: false,
  searchParam: 'q',
  debounceTime: 150,
  minSearchLength: 1,
  optionKey: 'id',
  optionLabel: 'name',
  leftIcon: 'fa-search',
});

const emit = defineEmits<{
  (e: 'selected', option: any): void;
  (e: 'update:text', text: string): void;
}>();

const searchElement = ref<HTMLElement | null>(null);
const dropDownOpen = ref(false);
const xPos = ref(0);
const yPos = ref(0);
const width = ref(100);
const loading = ref(false);
const options = ref([]);

const openDropDrown = async () => {
  if (dropDownOpen.value || !searchElement.value) return;
  width.value = searchElement.value.getBoundingClientRect().width;
  xPos.value = searchElement.value.getBoundingClientRect().x;
  yPos.value = searchElement.value.getBoundingClientRect().bottom;
  await nextTick();
  dropDownOpen.value = true;
};

const debouncedFn = useDebounceFn(async (searchT: string) => {
  loading.value = true;
  const params = { ...props.params };
  params[props.searchParam] = searchT;

  const { data, ...rest } = await axios.get(props.url, { params });
  if (rest.status === 204) {
    return;
  }
  if (data?.data) {
    options.value = data.data;
  }
  loading.value = false;
}, props.debounceTime);

const searchForOptions = async (e?: string) => {
  let st = '';
  if (e) {
    st = e;
  } else if (props.searchString) {
    st = props.searchString;
  } else {
    st = props.text;
  }
  if (st.length < props.minSearchLength) {
    options.value = [];
    dropDownOpen.value = false;
    loading.value = false;
    return;
  }
  await openDropDrown();
  loading.value = true;
  await debouncedFn(st);
};

const onOptionClick = (option) => {
  dropDownOpen.value = false;
  emit('selected', option);
};

const onChangeText = (e: string) => {
  emit('update:text', e);
};

let ticking = false;

const update = () => {
  ticking = false;

  const box = searchElement.value?.getBoundingClientRect();
  if (box) {
    width.value = box.width;
    xPos.value = box.x;
    yPos.value = box.bottom;
  }
};

const requestTick = () => {
  if (!ticking) {
    requestAnimationFrame(update);
  }
  ticking = true;
};

const onScroll = () => {
  requestTick();
};

window.addEventListener('scroll', onScroll, true);

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll);
});

const focusCurrentItemIndex = ref<number | null>(null);

const listenForKeydown = (e: KeyboardEvent) => {
  if (e.key === 'ArrowDown') {
    if (focusCurrentItemIndex.value === null) {
      focusCurrentItemIndex.value = 0;
    } else {
      focusCurrentItemIndex.value += 1;
    }
    const elem = document.querySelector(`[data-option-index="${focusCurrentItemIndex.value}"]`);
    if (elem) {
      elem.scrollIntoView({ block: 'nearest' });
    }
  } else if (e.key === 'ArrowUp') {
    if (focusCurrentItemIndex.value === null) {
      focusCurrentItemIndex.value = 0;
    } else {
      focusCurrentItemIndex.value -= 1;
    }
    const elem = document.querySelector(`[data-option-index="${focusCurrentItemIndex.value}"]`);
    if (elem) {
      elem.scrollIntoView({ block: 'nearest' });
    }
  } else if (e.key === 'Enter') {
    if (focusCurrentItemIndex.value !== null) {
      onOptionClick(options.value[focusCurrentItemIndex.value]);
    }
  }
};

watch(dropDownOpen, (open) => {
  if (open) {
    window.addEventListener('keydown', listenForKeydown);
  } else {
    window.removeEventListener('keydown', listenForKeydown);
    focusCurrentItemIndex.value = null;
  }
});

const onBlur = () => {
  setTimeout(() => {
    dropDownOpen.value = false;
  }, 200);
};
</script>

<template>
  <div ref="searchElement">
    <EmailInput
      v-if="isEmail"
      :email="text"
      :required="required"
      :can-edit="canEdit"
      :label="label"
      @blur="onBlur"
      @keyup="searchForOptions($event.target.value)"
      @update:email="onChangeText" />
    <TextInput
      v-else
      :model-value="text"
      :required="required"
      :can-edit="canEdit"
      :label="label"
      :set-focus="setFocus"
      :left-icon="leftIcon"
      :placeholder="placeholder"
      @blur="onBlur"
      @keyup="searchForOptions()"
      @update:model-value="onChangeText" />
    <HoverBox
      v-if="dropDownOpen"
      :x-pos="xPos"
      :y-pos="yPos"
      @closed="dropDownOpen = false">
      <ul
        class="divide-y rounded-bl rounded-br border-b border-l border-r bg-content"
        :style="`width:${width}px;`">
        <li>
          <div
            class="flex flex-col divide-y overflow-auto"
            :style="`max-height: ${200}px`">
            <div
              v-for="(option, index) in options"
              :key="option[optionKey]"
              :data-option-index="index"
              :class="{ 'bg': focusCurrentItemIndex == index }"
              class="flex min-h-[40px] cursor-pointer items-center p-edge-1/4 hover:bg"
              @click="onOptionClick(option)">
              <slot :option="option">
                {{ option[optionLabel] }}
              </slot>
            </div>
          </div>
        </li>

        <li
          v-if="!options.length && searchString?.length < minSearchLength"
          class="h-[40px] p-edge-1/4">
          Type at least {{ minSearchLength }} to search
        </li>
        <li
          v-if="!options.length && searchString?.length >= minSearchLength && !loading"
          class="h-[40px] p-edge-1/4">
          No Options found
        </li>
      </ul>
    </HoverBox>
  </div>
</template>
