<script setup lang="ts">
import { nextTick, onMounted, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import CrudModal from '@/components/Modals/CrudModal.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import BaseSlideout from '@/components/Base/BaseSlideout.vue';
import { useInfiniteScroll } from '@vueuse/core';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import DisplayRichText from '@/components/Display/DisplayRichText.vue';
import {
  BoardAuditResource,
  BoardCellResource,
  BoardColumnResource,
  BoardResource,
  BoardRowResource,
} from '@/types/board';
import { usePage } from '@inertiajs/vue3';
import { getKey } from '@/util/globals';
import BoxContainer from '@/components/Elements/BoxContainer.vue';

type Props = {
  board: BoardResource;
  boardRow?: BoardRowResource | null;
  boardColumn?: BoardColumnResource | null;
  boardCell?: BoardCellResource | null;
};

const props = withDefaults(defineProps<Props>(), {
  boardRow: null,
  boardColumn: null,
  boardCell: null,
});

const toast = useToast();
const target = ref(null);

const loading = ref(false);
const open = ref(false);

const loadingSelectedAudit = ref(false);

const audits = ref<BoardAuditResource[]>([]);
const activeFields = ref([]);
const allowedFields = ref(['order', 'title', 'component', 'options', 'width', 'value']);
const page = ref(1);
const totalPages = ref(1);
const totalAudits = ref(null);
const selectedAudit = ref(null);

const auditEventTypes = ref([
  { name: 'Created', id: 'created' },
  { name: 'Updated', id: 'updated' },
  { name: 'Deleted', id: 'deleted' },
]);

const allAuditEventTypes = [
  { name: 'Created', id: 'created' },
  { name: 'Updated', id: 'updated' },
  { name: 'Deleted', id: 'deleted' },
];

const timeoutFunction = ref(null);
const fetching = ref(false);

const getTitleForAudits = () => {
  if (props.boardCell) return 'Audits for ' + props.boardRow.title + ' on ' + props.boardColumn.title;
  if (props.boardColumn) return 'Audits for ' + props.boardColumn.title;
  if (props.boardRow) return 'Audits for ' + props.boardRow.title;
  return 'Audits for ' + props.board.title;
};

const getUrlForAudits = () => {
  if (props.boardCell) return '/api/boards/' + props.board.id + '/board-cells/' + props.boardCell.id + '/audits';
  if (props.boardColumn) return '/api/boards/' + props.board.id + '/board-columns/' + props.boardColumn.id + '/audits';
  if (props.boardRow) return '/api/boards/' + props.board.id + '/board-rows/' + props.boardRow.id + '/audits';
  return '/api/boards/' + props.board.id + '/audits';
};

const loadAudits = async () => {
  if (loading.value) return;
  fetching.value = true;
  loading.value = true;
  const { data } = await axios
    .get(getUrlForAudits(), {
      params: {
        page: page.value,
        audit_events: auditEventTypes.value.map((type) => type.id),
      },
    })
    .catch((error) => {
      console.error(error);
    });
  if (data.meta.hasOwnProperty('total')) {
    totalAudits.value = data.meta.total;
  }
  if (data.meta.hasOwnProperty('last_page')) {
    totalPages.value = data.meta.last_page;
  } else if (data.data.length > 0) {
    totalPages.value = page.value + 1;
  }
  if (data.data.length) {
    audits.value = audits.value.concat(data.data);
  }

  loading.value = false;
  fetching.value = false;
};

const resetAndLoad = (delay = 1000) => {
  audits.value = [];
  page.value = 1;
  clearTimeout(timeoutFunction.value);
  timeoutFunction.value = null;
  fetching.value = true;
  timeoutFunction.value = setTimeout(() => {
    clearTimeout(timeoutFunction.value);
    loadAudits();
  }, delay);
};

const showSidebar = () => {
  open.value = false;
  nextTick(() => {
    open.value = true;
  });
  resetAndLoad(0);
};

const openAudit = async (audit: BoardAuditResource) => {
  selectedAudit.value = null;
  await nextTick();
  selectedAudit.value = audit;
  const possibleFields = audit.old_values
    ? Object.keys(audit.old_values).concat(Object.keys(audit.new_values))
    : Object.keys(audit.new_values);
  activeFields.value = allowedFields.value.filter((field) => possibleFields.includes(field));
};

watch(auditEventTypes, () => {
  resetAndLoad(0);
});

onMounted(() => {
  setTimeout(() => {
    showSidebar();
  }, 100);
});
useInfiniteScroll(
  target,
  async () => {
    // load more
    if (page.value < totalPages.value) {
      page.value += 1;
      await loadAudits();
    }
  },
  { distance: 10 }
);

const height = CSS.supports('height: 100dvh') ? '100dvh' : '100vh';
</script>

<template>
  <div>
    <BaseSlideout
      v-model="open"
      small
      within-same
      :base-z-index="1000"
      @closed="$emit('closed')">
      <template #header>
        <div class="flex flex-col border-b p-edge">
          <div class="flex gap-edge-1/2">
            <IconWithLoading
              icon="fa-history"
              classes="text-3xl"
              :loading="loading"></IconWithLoading>
            <h1>{{ totalAudits }} Records</h1>
          </div>
          <div class="sub-title text-soft">
            {{ getTitleForAudits() }}
          </div>
        </div>
      </template>
      <div class="h-full bg-content-main p-edge">
        <div v-if="loading">
          <div class="pt-[50px] text-center">
            <h2>
              <i class="fa fa-fw fa-circle-o-notch fa-spin" />
            </h2>
          </div>
        </div>

        <div
          v-else-if="!audits.length"
          class="p-edge pt-[50px]">
          <div class="rounded bg p-edge text-center italic">
            <p>No Audits yet</p>
          </div>
        </div>
        <div
          v-else-if="audits.length"
          ref="target"
          :style="`height: calc(${height} - 100px)`"
          class="space-y-edge overflow-auto">
          <BoxContainer
            v-for="audit in audits"
            :key="audit.id"
            :content-padding="false"
            :padding-bottom="false">
            <div
              class="group grid cursor-pointer grid-cols-[30px_auto] items-center gap-edge-1/2 p-edge transition-all hover:bg-hover"
              @click.prevent="openAudit(audit)">
              <div class="image pull-left align-content-vertically">
                <img
                  v-if="audit.user && audit.user.avatar_url"
                  :src="audit.user.avatar_url"
                  class="img-responsive rounded-full" />
                <img
                  v-else
                  :src="usePage().props.asset_url + 'assets/images/default-avatar.png'"
                  class="img-responsive rounded-full" />
              </div>
              <div class="overflow-hidden pr-edge-1/4">
                <div class="sub-title truncate">
                  {{ audit.user ? audit.user.name : 'Unknown user' }}
                  {{ audit.title }}
                </div>
                <small
                  class="mt-edge-1/4 text-soft"
                  :title="audit.created_at">
                  {{ audit.created_at_human }}
                </small>
              </div>
            </div>
          </BoxContainer>
          <div
            v-if="audits.length && page >= totalPages"
            class="p-edge text-center italic"
            @click.prevent>
            All Loaded
          </div>
        </div>
      </div>
    </BaseSlideout>

    <CrudModal
      v-if="selectedAudit"
      title="Audit"
      only-close-button
      @closed="selectedAudit = null">
      <div
        v-if="!loadingSelectedAudit && selectedAudit"
        class="main-content">
        <div class="sub-title mb-edge">
          {{ selectedAudit.user ? selectedAudit.user.name : 'Unknown user' }}
          {{ selectedAudit.title }}
        </div>
        <div
          v-if="activeFields.length > 0"
          class="border-t hover:border-t-highlight [&_th>*]:px-edge-1/4">
          <VTable>
            <template #head>
              <VTableRow head>
                <VTableCell>Attribute</VTableCell>
                <VTableCell v-if="['updated', 'deleted'].includes(selectedAudit.event)"> Old</VTableCell>
                <VTableCell v-if="['created', 'updated'].includes(selectedAudit.event)"> New</VTableCell>
              </VTableRow>
            </template>
            <template #default>
              <VTableRow
                v-for="field in activeFields"
                :key="field.id">
                <VTableCell main-cell>
                  <span class="capitalize">{{ field }}</span>
                </VTableCell>
                <VTableCell v-if="['updated', 'deleted'].includes(selectedAudit.event)">
                  {{ getKey(selectedAudit.old_values, field) }}
                  <span
                    v-for="(t, idx) in getKey(selectedAudit.old_values, field, '')?.split('\n')"
                    :key="idx">
                    <DisplayRichText :content="t"></DisplayRichText>
                    <br />
                  </span>
                </VTableCell>
                <VTableCell v-if="['created', 'updated'].includes(selectedAudit.event)">
                  <span
                    v-for="(t, idx) in getKey(selectedAudit.new_values, field, '')?.split('\n')"
                    :key="idx">
                    <DisplayRichText :content="t"></DisplayRichText>
                    <br />
                  </span>
                </VTableCell>
              </VTableRow>
            </template>
          </VTable>
        </div>

        <div class="mt-edge">
          <div class="text-xs text-soft">
            <i class="fa fa-clock mr-edge-1/4" />
            <span class="mr-edge-1/4 italic">when</span>
            <i class="fa fa-minus fa-sm mr-1" />
            <span class="text-sm italic">{{ selectedAudit.created_at }}</span>
          </div>
        </div>
      </div>
    </CrudModal>
  </div>
</template>
