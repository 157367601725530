<script setup lang="ts">
import { ref, computed } from 'vue';
import { useToast } from 'vue-toastification';
import VMultiselect from '@/components/Inputs/VMultiselect.vue';
import ReportModal from '@/components/Modals/ReportModal.vue';
import { getItemFromArrayBasedOnId } from '@/util/globals';
import { downloadFile } from '@/helpers/downloadFileFunctions';
import { MetaDataResource } from '@/types/meta-data';
import { ShowTimeResource } from '@/types/show-time';

const { showTimes, metaDataList } = defineProps<{
  metaDataList: MetaDataResource[];
  showTimes: ShowTimeResource[];
}>();

const toast = useToast();

const working = ref(false);
const modalOpen = ref(false);
const downloadableMetaData = ref([]);

const closeDownloadMetaDataReportModal = () => {
  modalOpen.value = false;
};

const downloadReport = async () => {
  if (working.value) return;
  working.value = true;

  const { data } = await axios
    .post('/api/meta-data/event-report', {
      downloadableMetaData: downloadableMetaData.value,
    })
    .catch((error) => {
      console.error(error);
      working.value = false;
      toast.warning('Something went wrong, please try again later');
    });
  await downloadFile(data.url, data.name);
  working.value = false;
  closeDownloadMetaDataReportModal();
};

const options = computed(() => {
  return metaDataList.map((metaData) => {
    let title = metaData.title;
    if (metaData.show_time_id) {
      const appendTitle = getItemFromArrayBasedOnId(metaData.show_time_id, showTimes, { title: 'N/A' }).title;
      title += `${appendTitle ? `- ${appendTitle}` : ''}`;
    }
    return { id: metaData.id, title: title };
  });
});
</script>

<template>
  <ReportModal
    :with-button="false"
    :can-download="downloadableMetaData.length > 0"
    title="Download Meta Data Report"
    :working="working"
    @download="downloadReport">
    <VMultiselect
      v-model="downloadableMetaData"
      class="col-span-2"
      :close-on-select="false"
      option-label="title"
      label-key="id"
      :options="options"
      label="Meta Data to include in report" />
  </ReportModal>
</template>
