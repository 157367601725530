<script lang="ts" setup>
import { computed } from 'vue';
import { getKey } from '@/util/globals';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';

type Props = {
  title: string;
  values: string[];
  icon?: string;
  itemClasses?: string | null;
  iconClass?: string;
  item?: object | null;
  truncate?: boolean | null;
};

const props = withDefaults(defineProps<Props>(), {
  icon: '',
  itemClasses: '',
  iconClass: '',
  item: null,
  truncate: true,
});

const truncatedValues = computed(() => {
  if (!props.truncate) return props.values;
  const maxLines = 3;
  const allowTruncate = !props.item || getKey(props.item, 'withoutTruncate', false) === false;

  if (props.values.length > maxLines && allowTruncate) {
    return props.values.slice(0, maxLines);
  }
  return props.values;
});

const isTruncated = computed(
  () =>
    props.values.length > truncatedValues.value.length &&
    (!props.item || getKey(props.item, 'withoutTruncate', false) === false)
);
</script>

<template>
  <div
    v-if="values?.some((value) => value)"
    class="relative">
    <InputLabel
      :label="title"
      super-text />
    <div
      v-for="(itm, idx) in truncatedValues"
      :key="idx"
      :class="itemClasses + (icon ? ' grid grid-cols-[25px_auto] items-center' : '')"
      class="mb-1">
      <i
        v-if="icon"
        :class="`fa fa-fw fa-${icon} text-soft ${iconClass}`" />
      {{ itm }}
    </div>
    <span
      v-if="isTruncated"
      class="absolute bottom-0 right-0 rounded-full bg-row px-1 text-xs">
      +{{ values.length - truncatedValues.length }}
    </span>
  </div>
</template>
