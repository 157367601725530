<script setup lang="ts">
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import type { MetaDataContent } from '@/types/event-request';
import { getComponent } from '@/util/get-component';
import { arrayMove, getKey } from '@/util/globals';
import { copyObject } from '@/util/object-helpers';
import Sortable from 'sortablejs';
import { computed, nextTick, ref, watch } from 'vue';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';
import VButton from '@/components/Inputs/VButton.vue';

type Props = {
  editMode: boolean;
  isTemplate: boolean;
  isNewRequest: boolean;
  content: MetaDataContent;
  section: object;
  canDrag: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:content', ...args: any[]): void;
}>();

const hasLoaded = ref(false);
const loading = ref(false);
const firstLoad = ref(false);
const settingsModalOpen = ref(false);

const getComponentName = (name: string, persisting: false) => {
  switch (name) {
    case 'field-document':
    case 'field-public-document': {
      if (persisting) {
        return 'field-document';
      }
      return props.isNewRequest ? 'field-public-document' : 'field-document';
    }
    default: {
      return name;
    }
  }
};

const fields = ref([
  ...props.content.fields.map((f) => ({
    value: f.value,
    component: getComponentName(f.component, false),
    title: f.title,
    options: f.options,
    class: f.class,
    id: f.id,
    linebreak_after: getKey(f, 'linebreak_after', false),
    required: getKey(f, 'required', false),
  })),
]);

const fieldName = ref('');
const selectedField = ref([]);

const classList = [
  { title: 'Small', value: 'col-md-3' },
  { title: 'Medium', value: 'col-md-6' },
  { title: 'Large', value: 'col-md-12' },
] as const;
const fieldClass = ref('col-md-3');
const linebreakAfter = ref(false);
const required = ref(false);

const canSave = computed(() => {
  return !loading.value && hasLoaded.value && firstLoad.value;
});

const persistable = computed(() => {
  return {
    fields: fields.value.map((field) => ({
      id: field.id,
      title: field.title,
      value: field.value,
      component: getComponentName(field.component, true),
      options: field.options,
      class: field.class,
      linebreak_after: field.linebreak_after,
      required: getKey(field, 'required', false),
    })),
    parent_id: props.content.parent_id,
  };
});

const emitMetaData = () => {
  emit('update:content', persistable.value);
};

watch(
  fields,
  () => {
    emitMetaData();
  },
  { deep: true }
);

const openEditFieldModal = (field) => {
  selectedField.value = field;
  fieldName.value = field.title;
  fieldClass.value = field.class;
  linebreakAfter.value = getKey(field, 'linebreak_after', false);
  required.value = getKey(field, 'required', false);
  settingsModalOpen.value = false;
  nextTick(() => {
    settingsModalOpen.value = true;
  });
};

const hideEditFieldModal = () => {
  settingsModalOpen.value = false;
  selectedField.value = [];
  fieldName.value = '';
};

const updateField = (close: () => void) => {
  const index = _.findIndex(fields.value, (f) => f.id === selectedField.value.id);
  if (index > -1) {
    fields.value[index].title = fieldName.value;
    fields.value[index].class = fieldClass.value;
    fields.value[index].linebreak_after = linebreakAfter.value;
    fields.value[index].required = required.value;
  }
  close();
};

const removeField = async (close: () => void) => {
  const deleteIt = await useDeleteObjectModal().assertReadyToDeleteModal(
    `Delete ${selectedField.value.title}`,
    'Are you sure you want to delete this field? '
  );
  if (!deleteIt) return;
  const index = _.findIndex(fields.value, (f) => f.id === selectedField.value.id);
  fields.value.splice(index, 1);
  close();
};

const translateClass = (field, index) => {
  let classList = 'col-span-full';
  switch (field.class) {
    case 'col-md-3': {
      classList += ' md:col-span-1';
      break;
    }
    case 'col-md-6': {
      classList += ' md:col-span-2';
      break;
    }
    case 'col-md-12':
    default: {
      classList += ' md:col-span-4';
      break;
    }
  }
  if (index > 0 && getKey(fields.value[index - 1], 'linebreak_after', false)) {
    classList += ' !col-start-1';
  }
  return classList;
};

const sectionWrapper = ref<HTMLDivElement | null>(null);
let sortableInstance: Sortable | null = null;

watch([() => props.canDrag, sectionWrapper], ([canDrag, items]) => {
  if (canDrag && sectionWrapper.value) {
    sortableInstance = new Sortable(sectionWrapper.value, {
      animation: 150,
      sort: true,
      ghostClass: 'invisible',
      onEnd: (e) => {
        fields.value = arrayMove(copyObject(fields.value), e.oldIndex, e.newIndex);
      },
    });
  } else {
    sortableInstance?.destroy();
  }
});
</script>

<template>
  <div
    ref="sectionWrapper"
    class="grid grid-cols-4 gap-edge">
    <div
      v-for="(field, index) in fields"
      :id="field.id"
      :key="field.id"
      :class="[translateClass(field, index), { 'linebreak': field.linebreak_after && isTemplate && editMode }]"
      class="py-edge-1/2">
      <div :class="isTemplate && canDrag ? 'ring-borderColor ring-1' : ''">
        <div
          :class="{ 'grid justify-between': editMode }"
          :style="editMode ? 'grid-template-columns: 1fr 30px' : ''">
          <InputLabel
            :label="field.title"
            :mandatory-text="getKey(field, 'required', false) ? 'Mandatory' : ''"
            :title="field.title" />
          <VButton
            v-if="isTemplate && editMode"
            size="xs"
            icon="fa fa-pencil"
            @click="openEditFieldModal(field)" />
        </div>

        <component
          :is="getComponent(field.component)"
          :id="getKey(section, 'id') + '_' + getKey(field, 'id')"
          :model-value="field.value ?? null"
          :options="field.options"
          :required="getKey(field, 'required', false)"
          :is-template="isTemplate"
          :edit-form="false"
          :edit-content="!isTemplate && editMode"
          size="block"
          :can-edit="isTemplate ? false : editMode"
          @blur="field.value = $event" />
      </div>
    </div>
  </div>

  <CrudModal
    v-if="settingsModalOpen"
    :disabled="fieldName.length < 2"
    :update="true"
    small
    title="Update Field"
    @delete="removeField"
    @update="updateField"
    @closed="hideEditFieldModal">
    <div class="flex flex-col gap-edge">
      <TextInput
        v-model="fieldName"
        label="Title" />
      <VSelect
        v-model="fieldClass"
        :options="classList"
        option-value="title"
        option-key="value"
        label="Field Size" />
      <SettingToggle
        v-model="linebreakAfter"
        label="Linebreak After" />
      <SettingToggle
        v-model="required"
        title="If enabled, the event request cannot be submitted without filling out this field."
        label="Required" />
    </div>
    <div v-if="selectedField.options && selectedField.options.length">
      <br />
      <p>To edit fields here, please change the meta data itself.</p>
    </div>
  </CrudModal>
</template>
