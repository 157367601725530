import { arrayToJoinString } from '@/util/globals';

export const fieldClasses = [
  { title: 'Small', value: 'col-md-3' },
  { title: 'Medium', value: 'col-md-6' },
  { title: 'Large', value: 'col-md-12' },
] as const;

export const fieldHasOptions = (field) => {
  if (!field || !field.hasOwnProperty('component')) {
    return false;
  }
  switch (field.component) {
    case 'field-list':
    case 'field-multi-select':
    case 'board-column-list': {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const fieldHasTable = (field) => {
  if (!field || !field.hasOwnProperty('component')) {
    return false;
  }
  switch (field.component) {
    case 'field-table': {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const allAvailableFieldTypes = [
  {
    title: 'Text',
    component: 'field-text',
    icon: 'fa fa-file-text-o fa-regular',
  },
  {
    title: 'Rich Text',
    component: 'field-rich-text',
    icon: 'fa fa-paragraph fa-regular',
  },
  {
    title: 'Number',
    component: 'field-number',
    icon: 'fa fa-hashtag fa-regular',
  },
  {
    title: 'Toggle',
    component: 'field-toggle',
    icon: 'fa fa-check-square fa-regular',
  },
  {
    title: 'List',
    component: 'field-list',
    configurator: 'field-list-configurator',
    icon: 'fa fa-list fa-regular',
  },
  {
    title: 'Date',
    component: 'field-date',
    icon: 'fa fa-calendar fa-regular',
  },
  {
    title: 'Document',
    component: 'field-document',
    icon: 'fa fa-file fa-regular',
  },
  {
    title: 'Time',
    component: 'field-time',
    icon: 'fa fa-clock-o fa-regular',
  },
] as const;

export const tableFieldType = [
  {
    title: 'Table',
    component: 'field-table',
    icon: 'fa fa-table fa-regular',
  },
] as const;

export const multiSelectFieldType = [
  {
    title: 'Multi-Select',
    component: 'field-multi-select',
    icon: 'fa fa-list fa-regular',
  },
] as const;

export const multiselectValueAsConcatString = (value?: string | null | object | any) => {
  if (!value) return;
  if (!value.length) return;
  switch (typeof value) {
    case 'object': {
      return arrayToJoinString(value, ', ');
    }
    case 'string': {
      return arrayToJoinString(JSON.parse(value), ', ');
    }
  }
  return '';
};
