<script lang="ts" setup>
import EmailInput from '@/components/Inputs/EmailInput.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import PhoneInput from '@/components/Inputs/PhoneInput.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import type { EventRequestForm } from '@/types/event-request';
import { getKey } from '@/util/globals';
import { usePage } from '@inertiajs/vue3';
import { ref } from 'vue';
import BoxContainer from '@/components/Elements/BoxContainer.vue';

type Props = {
  modelValue: EventRequestForm | null;
  newRequest: boolean;
  canSubmit: boolean;
  editMode: boolean;
  isTemplate: boolean;
  sections: [];
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:contactPerson', ...args: any[]): void;
  (event: 'update:canSubmit', ...args: any[]): void;
  (event: 'update:requesterId', ...args: any[]): void;
  (event: 'update:modelValue', arg: EventRequestForm): void;
}>();

const adminGroups = ref<
  {
    id: number;
    name: string;
  }[]
>([]);

const updateRequest = (item: string, value: string | number) => {
  if (!props.editMode || !props.modelValue) return;

  const newRequest = { ...props.modelValue };

  switch (item) {
    case 'request_by_name':
    case 'request_by_email':
    case 'request_by_phone':
    case 'request_by_country_code': {
      if (typeof value !== 'string') break;
      newRequest[item] = value;
      break;
    }
    case 'requester_id': {
      if (value === null) {
        newRequest.requester_id = usePage().props.auth.user.id;
        newRequest.requester_type = 'App\\User';
        break;
      }
      if (typeof value !== 'number') break;

      newRequest.requester_id = value;
      newRequest.requester_type = 'App\\Group';
      break;
    }
    default: {
      return;
    }
  }

  emit('update:modelValue', newRequest);
};

const isLoggedIn = usePage().props.authenticated;

if (isLoggedIn && props.newRequest) {
  emit('update:canSubmit', true);

  usePage()
    .props.auth.user.groups.filter((g) => g.admin)
    .forEach((group) => {
      adminGroups.value.push({
        name: group.name,
        id: group.id,
      });
    });

  if (props.modelValue) {
    if (adminGroups.value.length) {
      if (usePage().props.auth.user.custom_home_id && usePage().props.auth.user.custom_home_type === 'App\\Group') {
        const index = _.findIndex(adminGroups.value, (g) => g.id === usePage().props.auth.user.custom_home_id);
        if (index > -1) {
          updateRequest('requester_id', adminGroups.value[index].id);
        }
      }
    } else {
      const newRequest = { ...props.modelValue };
      newRequest.requester_id = usePage().props.auth.user.id;
      newRequest.requester_type = 'App\\User';
      emit('update:modelValue', newRequest);
    }
  }
}
</script>

<template>
  <BoxContainer
    :content-padding="false"
    data-intro="event-request-requested-by">
    <div>
      <InputLabel
        v-if="newRequest"
        :label="'Section 2 of ' + (sections.length + 2)"
        super-text />
      <h3 class="mb-edge-1/2">Contact information</h3>
    </div>
    <p class="sub-title">Who is the contact for this request?</p>
    <div
      v-if="newRequest"
      data-intro="event-request-requester">
      <div
        v-if="isLoggedIn && newRequest"
        :class="adminGroups.length === 0 || !modelValue ? 'grid-cols-3' : 'grid-cols-4'"
        class="flex flex-col gap-edge sm:grid">
        <TextInput
          :can-edit="false"
          :model-value="usePage().props.auth.user.name"
          label="Name" />

        <EmailInput
          :can-edit="false"
          :email="usePage().props.auth.user.email" />

        <PhoneInput
          :can-edit="false"
          :country-code="usePage().props.auth.user.country_code"
          :phone="usePage().props.auth.user.phone"
          size="block" />

        <VSelect
          v-if="adminGroups.length > 0 && modelValue"
          :can-edit="editMode && !isTemplate"
          :model-value="modelValue?.requester_type === 'App\\Group' ? modelValue?.requester_id : null"
          :options="adminGroups"
          label="Request For"
          nullable
          nullable-display-text="Your self"
          @update:model-value="updateRequest('requester_id', $event)" />
      </div>
      <div
        v-else
        class="flex grid-cols-3 flex-col gap-edge md:grid">
        <TextInput
          id="event-request-requested-by-name"
          :can-edit="editMode && !isTemplate"
          :model-value="modelValue?.request_by_name"
          label="Name"
          required
          @blur="updateRequest('request_by_name', $event)" />

        <EmailInput
          :can-edit="editMode && !isTemplate"
          :email="modelValue?.request_by_email"
          required
          @blur="updateRequest('request_by_email', $event)" />

        <PhoneInput
          :can-edit="editMode && !isTemplate"
          :country-code="modelValue?.request_by_country_code"
          :phone="modelValue?.request_by_phone"
          required
          size="block"
          @phone-blur="updateRequest('request_by_phone', $event)"
          @update:country-code="updateRequest('request_by_country_code', $event)" />
      </div>
    </div>
    <div
      v-else
      class="grid grid-cols-3 gap-edge">
      <TextInput
        :can-edit="!isTemplate && editMode && !getKey(modelValue, 'is_user', false)"
        :model-value="modelValue?.request_by_name"
        label="Name"
        @blur="updateRequest('request_by_name', $event)" />

      <EmailInput
        :can-edit="!isTemplate && editMode && !getKey(modelValue, 'is_user', false)"
        :email="modelValue?.request_by_email"
        @blur="updateRequest('request_by_email', $event)" />

      <PhoneInput
        :can-edit="!isTemplate && editMode && !getKey(modelValue, 'is_user', false)"
        :country-code="modelValue?.request_by_country_code"
        :phone="modelValue?.request_by_phone"
        size="block"
        @phone-blur="updateRequest('request_by_phone', $event)"
        @update:country-code="updateRequest('request_by_country_code', $event)" />

      <div v-if="getKey(modelValue, 'requester')">
        <InputLabel
          label="Requesting on behalf of"
          super-text />
        {{ modelValue.requester }}
      </div>
    </div>
  </BoxContainer>
</template>
