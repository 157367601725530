<script setup lang="ts">
import { documentKey } from '@/provide/keys';
import { DocumentResource } from '@/types/document';
import { downloadDocument } from '@/helpers/downloadFileFunctions';
import { computed, inject, nextTick, ref } from 'vue';
import ImageViewModal from '@/components/Documents/ImageViewModal.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { getFileTypeIcon } from '@/util/document';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import DocumentSelectOrUploadModal from '@/components/Documents/DocumentSelectOrUploadModal.vue';

type Props = {
  modelValue: string | number | null;
  canEdit: boolean;
  isShowMode: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:modelValue', arg: any): void;
  (event: 'focus'): void;
}>();

// const maxUploadSize = inject(maxUploadSizeKey, 100);

const { fetch, folderDocuments, unconnectedDocuments, loading, loaded, allDocuments, model, modelId } = inject(
  documentKey,
  {
    fetch: async () => {},
    loading: ref(false),
    loaded: ref(false),
    folderDocuments: computed(() => []),
    unconnectedDocuments: computed(() => []),
    allDocuments: computed(() => []),
  }
);
fetch(false);

// const empty = computed(() => folderDocuments.value.length === 0 && unconnectedDocuments.value.length === 0);

const modalOpen = ref(false);

const document = computed(() => allDocuments.value.find((doc) => doc.id === Number(props.modelValue)));

const removeDocument = async () => {
  const removeIt = await useDeleteObjectModal().assertReadyToDeleteModal(
    'Remove Document',
    'Are you sure you want to remove this document?'
  );
  if (!removeIt) return;

  emit('update:modelValue', null);
};

const openFolderIds = ref<Set<number>>(new Set());
const getFolderIds = (folder) => {
  return [folder.id].concat(folder.children.map(getFolderIds).flat());
};

const openModal = async () => {
  openFolderIds.value = new Set(folderDocuments.value.flatMap((folder) => getFolderIds(folder)));
  modalOpen.value = false;
  await nextTick();
  modalOpen.value = true;
};

const isImage = computed(() => {
  if (!document.value) return false;
  return document.value.mime_type.startsWith('image');
});
const showImage = ref(false);
const documentAdded = async (doc: DocumentResource) => {
  emit('update:modelValue', doc.id);
  await fetch(true);
};
const openDocument = () => {
  if (!document.value) return;
  if (isImage.value) {
    showImage.value = false;
    nextTick(() => {
      showImage.value = true;
    });
    return;
  }
  if (document.value.mime_type.substring(0, 15) === 'application/pdf') {
    window.open(document.value.download_url);
    return;
  }
  downloadDocument(document);
};
const searchTerm = ref('');
</script>
<template>
  <div
    v-if="document"
    class="flex items-center gap-edge-1/4">
    <div
      class="ring-highlight w-full cursor-pointer truncate rounded-full px-edge-1/2 py-1 hover:ring-1"
      :title="document.filename"
      @click="openDocument">
      <i
        class="fa fa-fw fa-regular"
        :class="getFileTypeIcon(document.extension)" />
      {{ document.filename }}
    </div>
    <VButton
      v-if="canEdit"
      size="sm"
      icon="fa-times"
      @focus="[emit('focus')]"
      @click="removeDocument" />
    <ImageViewModal
      v-if="isImage && showImage"
      :image="document"
      :can-edit="false"
      @close="showImage = false" />
  </div>
  <div
    v-else-if="!isShowMode"
    class="flex h-full items-center justify-center">
    <VButton
      v-if="canEdit"
      size="sm"
      type="success"
      :loading="loading || !loaded"
      icon="fa-file fa-regular"
      tool-tip-text="Select or Upload document to this cell"
      @focus="[emit('focus')]"
      @click="openModal" />
    <slot
      v-else
      name="emptyState" />
    <DocumentSelectOrUploadModal
      v-if="modalOpen"
      :selected-document-id="modelValue"
      @document:selected="documentAdded($event)" />
  </div>
</template>
