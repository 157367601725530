<script setup lang="ts">
import { nextTick, ref } from 'vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { createUuId, getIndexFromArrayBasedOnId, getItemFromArrayBasedOnId } from '@/util/globals';
import { SortEmit } from '@/components/TestTable.vue';
import { copyObject } from '@/util/object-helpers';
import { FieldTableColumnObject } from '@/components/Fields/Fields/FieldTable.vue';
import ActionButtonGroup from '@/components/Inputs/Components/ActionButtonGroup.vue';
import { allAvailableFieldTypes } from '@/util/fields';

type Props = {
  options: FieldTableColumnObject[] | null;
  handleOutside?: boolean | null;
  canDrag?: boolean | null;
};
const props = withDefaults(defineProps<Props>(), {
  canDrag: true,
  handleOutside: false,
});

const emit = defineEmits<{
  (event: 'update:options', arg: any): void;
  (event: 'create', arg: string): void;
  (event: 'delete', arg: any): void;
  (event: 'sorted', arg: SortEmit): void;
}>();
const idOfNewItem = ref(null);
const addColumn = (component: string, title: string) => {
  let { options } = props;
  if (!options) {
    options = [];
  }
  idOfNewItem.value = createUuId('table-' + component + '-');
  options.push(<FieldTableColumnObject>{
    id: idOfNewItem.value,
    title: title,
    component: component,
  });
  nextTick(() => {
    emit('update:options', options);
  });
};
const updateColumn = (column: object, newTitle: string) => {
  let { options } = props;
  const index = getIndexFromArrayBasedOnId(column.id, options);
  if (index > -1) {
    options[index].title = newTitle;
  }
  nextTick(() => {
    emit('update:options', options);
  });
};
const removeTableColumn = (column: object) => {
  let { options } = props;
  const index = getIndexFromArrayBasedOnId(column.id, options);
  if (index > -1) {
    options.splice(index, 1);
  }
  nextTick(() => {
    emit('update:options', options);
  });
};

const newOrderOfOptions = async (e: SortEmit) => {
  const { selectedItem: idOfMovedRow, newOrder: arrayOfIds } = e;
  let localOptions = copyObject(props.options);
  const newLocalOptions = arrayOfIds.map((id) => getItemFromArrayBasedOnId(id, [...localOptions]));
  emit('update:options', newLocalOptions);
};

const addColumnsArray = () => {
  return [
    {
      title: 'Add Column',
      icon: 'fa-plus',
      dropdown: [
        {
          title: 'Text',
          component: 'field-text',
          preIcon: 'fa fa-file-text-o',
          action: (close) => {
            addColumn('field-text', 'Text');
            close();
          },
        },
        {
          title: 'Number',
          component: 'field-number',
          preIcon: 'fa fa-hashtag',
          action: (close) => {
            addColumn('field-number', 'Number');
            close();
          },
        },
        {
          title: 'Toggle',
          component: 'field-toggle',
          preIcon: 'fa fa-check-square',
          action: (close) => {
            addColumn('field-toggle', 'Toggle');
            close();
          },
        },
        {
          title: 'Date',
          component: 'field-date',
          preIcon: 'fa fa-calendar',
          action: (close) => {
            addColumn('field-date', 'Date');
            close();
          },
        },
        {
          title: 'Time',
          component: 'field-time',
          preIcon: 'fa fa-clock-o',
          action: (close) => {
            addColumn('field-time', 'Time');
            close();
          },
        },
      ],
    },
  ];
};
</script>

<template>
  <div class="border-t pt-edge">
    <div class="flex justify-between">
      <h3>Columns</h3>
      <ActionButtonGroup
        inline
        :actions="addColumnsArray()"
        class="right-0 top-0" />
    </div>
    <VTable
      v-if="options && options.length > 0"
      class="mt-5"
      can-drag
      handle-outside
      :model-value="options"
      :items="options"
      @sorted="newOrderOfOptions">
      <template #row="{ item: option, index: index }">
        <VTableCell v-if="canDrag">
          <div class="flex items-center justify-center">
            <i class="fa fa-fw fa-arrows-up-down text-textColor-soft cursor-grab" />
          </div>
        </VTableCell>
        <VTableCell main-cell>
          <slot
            name="option"
            :option="option">
            <TextInput
              :left-icon="
                getItemFromArrayBasedOnId(option.component, [...allAvailableFieldTypes], { icon: null }, 'component')
                  .icon
              "
              :set-focus="idOfNewItem === option.id"
              :model-value="option.title"
              @blur="updateColumn(option, $event)">
            </TextInput>
          </slot>
        </VTableCell>
        <VTableCell style="width: 40px">
          <VButton
            icon="fa-trash"
            @click="removeTableColumn(option, index)" />
        </VTableCell>
      </template>
    </VTable>
  </div>
</template>
